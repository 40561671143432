import { Directive, ElementRef, AfterViewInit, Input, HostListener } from '@angular/core';
import { timer } from 'rxjs';
@Directive({
  selector: '[myMatchHeight]'
})
export class MatchHeightDirective implements AfterViewInit {
	@Input() myMatchHeight: any;

	constructor(private el: ElementRef) {
	}
	ngAfterViewInit(): void {
    timer(1000).subscribe(x => {
      this.matchHeight(this.el.nativeElement, this.myMatchHeight);
    });
    // call our matchHeight function here

  }

	@HostListener('window:resize') onResize() {
		// call our matchHeight function here later
		this.matchHeight(this.el.nativeElement, this.myMatchHeight);
	}

	matchHeight(parent: HTMLElement, className: string): void {

    // match height logic here

		if (!parent) return;

		// step 1: find all the child elements with the selected class name
		const children = parent.getElementsByClassName(className);

		if (!children) return;

		// step 2a: get all the child elements heights
		const itemHeights = Array.from(children)
	        .map(x => x.getBoundingClientRect().height);
    // step 2b: find out the tallest
		const maxHeight = itemHeights.reduce((prev, curr) => {
			return curr > prev ? curr : prev;
		}, 0);

		// step 3: update all the child elements to the tallest height
		Array.from(children)
			.forEach((x: HTMLElement|any) => x.style.height = `${maxHeight}px`);
	}


}

