import { Injectable } from '@angular/core';
import { BehaviorSubject,  ReplaySubject } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { User } from '../core/user.module';

import firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable()
export class AuthenticationService {
	private currentUserSubject 		= 	new BehaviorSubject<User>({} as User);
	public currentUser 				= 	this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
	private isAuthenticatedSubject 	= 	new ReplaySubject<boolean>(1);
	public isAuthenticated 			= 	this.isAuthenticatedSubject.asObservable();
	constructor(private afAuth: AngularFireAuth){}

	// Function to get logged in user detail
	getCurrentUser = (): any => {
		const  currentUser = JSON.parse(localStorage.getItem('userData') || '{}');
		if (typeof currentUser !== 'undefined' && currentUser !== null) {
			return currentUser;
		}else{
			return currentUser;
		}
	}

	// Verify JWT in localstorage with server & load user's info.
	// This runs once on application startup.
	populate = (): void => {
		// If JWT detected, attempt to get & store user's info
		const  currentUser = JSON.parse(localStorage.getItem('userData') || '{}');
		if (typeof currentUser !== 'undefined' && currentUser != null) {
			this.setAuth(currentUser);
		} else {
			// Remove any potential remnants of previous auth states
			this.purgeAuth();
		}
	}

	// Function is used to set local storage
	setAuth = (user: User): void => {
		localStorage.setItem('userData', JSON.stringify(user));
		// Set current user data into observable
		this.currentUserSubject.next(user);
		// Set isAuthenticated to true
		this.isAuthenticatedSubject.next(true);
	}

	// Function is used to remove local storage
	purgeAuth = (): void => {
		// Remove user data from localstorage
		localStorage.removeItem('userData');
		localStorage.removeItem('userDataToken');
		// Set current user to an empty object
		this.currentUserSubject.next({} as User);
		// Set auth status to false
		this.isAuthenticatedSubject.next(false);
	}

	// Function is used to set local storage
	setToken = (token: any): void => {
		localStorage.setItem('token', JSON.stringify(token));
	}


	// Sign in with Google
	GoogleAuth() {
		const provider = new firebase.auth.GoogleAuthProvider();
		return this.afAuth.signInWithPopup(provider);
	}

	// Sign in with Facebook
	FacebookAuth() {
		const provider = new firebase.auth.FacebookAuthProvider();
		return this.afAuth.signInWithPopup(provider);
	}
}
