import { Component, OnInit, OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from '../../service/common.service';
import { CommonFunctionsService, ValidationService, NgxToasterService } from '../../../../_services/index';
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

/** Define Component **/
@Component({
    selector: 'app-newsletter',
    templateUrl: './newsletter.component.html',
	styleUrls : ['./newsletter.component.css'],
	providers: [ValidationService, CommonFunctionsService, CommonService]
})

export class NewsletterComponent implements OnInit, OnDestroy {
	
	/** Define Variable **/
	private ngUnsubscribe: Subject<void> = new Subject<void>();
	env					=	environment;
	newslatterForm      : FormGroup;
	newslatterFormError : any 		= {};
	progress			: boolean 	= false;
	result				: any 		= [];

	/** Define constructor **/
	constructor(
		private formBuilder             : FormBuilder,
		private toasterNotification     : NgxToasterService,
		private commonService			: CommonService
	) {
		this.newslatterForm = this.formBuilder.group({});
	}

    /** This function is used to  On Init **/
	ngOnInit(): void  {
		this.createNewslatterForm();
		this.getSocialUrls();
	}

	/** create the News latter Form  */
	public createNewslatterForm(){
		this.newslatterForm = this.formBuilder.group({
			email				: ['', [Validators.required,ValidationService.emailValidator]],
		});
	}
	
	/** submit the News latter Form  */
	public submitNewslatterForm(){
		this.progress = true;
		if (this.newslatterForm.valid) {
			this.commonService.newsletterSubscribe(this.newslatterForm.value).subscribe((response: any) => {
				if(response.data.status === this.env.SUCCESS_STATUS){
					this.progress = false;
					this.toasterNotification.showSuccess(response.data.message);
					this.newslatterForm.reset();
				}else{
					if (response.data.errors !== undefined && response.data.errors !== '' && response.data.errors !== null) {
						// this.newslatterFormError.email = response.data.message;
						this.toasterNotification.showError(response.data.message);
					} else {
						this.toasterNotification.showError(response.data.message);
					}
					this.progress = false;
				}
			})
		}else{
			this.progress = false;
			ValidationService.validateAllFormFields(this.newslatterForm);
		}
	}

	/**This function are used for get Social Urls**/
	public getSocialUrls() {
        this.commonService.getSocialUrl().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
            if (response.data.status == this.env.SUCCESS_STATUS) {
                this.result = response.data;
            }
        });
    }

	/**This function are used for destroy the data**/
	ngOnDestroy(): void {}
}