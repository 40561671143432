import { Directive, ElementRef, HostListener} from "@angular/core";
import { environment } from '../../environments/environment';

/** From  directives, you can change the appearance or behavior of DOM elements and Angular components.**/
@Directive({
  selector: "[ShowHidePasswordDirective]"
})


export class ShowHidePasswordDirective {

  /** Define Variable */
	private showPassword: boolean = false;


  /** Define Constructor */

  /**Add ElementRef in the directive's constructor() to inject a reference to the host DOM element,
   the element to which you apply [ShowHidePasswordDirective] selector**/
	constructor(private el: ElementRef) {}



  /*With the @HostListener() decorator, you can subscribe to events of the DOM element that hosts an attribute directive*/
	@HostListener("click") showHideClick(): void {


    /** For toggle boolean value in flag to set password type */
		this.showPassword = !this.showPassword;

    /** If password visibility hide */
		if (!this.showPassword) {
			this.el.nativeElement.parentNode.children[0].children[0].setAttribute("type", "password");
			this.el.nativeElement.children[0].setAttribute("src", environment.SITE_IMAGE_URL + 'eye.svg');

		} else {
      /** If password visibility show */
			this.el.nativeElement.parentNode.children[0].children[0].setAttribute("type", "text");
			this.el.nativeElement.children[0].setAttribute("src", environment.SITE_IMAGE_URL + 'eye-icon-hide.png');
        }


	}
}
