import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';
 
@Component({
	templateUrl: './error404.component.html',
	styleUrls: ['./error404.component.css']
})
export class Error404Component {
	env 			= environment;
	
}
