import { Component, Input} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidationService } from './_services/index';
import { TranslateService } from  '@ngx-translate/core';
/** Define Component**/
@Component({
	selector: 'control-messages',
	template: `<div class="help-block" *ngIf="errorMessage !== null">{{errorMessage}}</div>`,
	styles:	['.help-block	{	margin-bottom	:	10px;	}']
})
export class ControlMessagesComponent {
	@Input() control: any = FormControl;
	@Input()  lang  : string = 'en';
	@Input()  name  : string = '';
    /** Define constructor**/
	constructor(public translateService: TranslateService) {
    }
	get errorMessage(): any {
		for (const propertyName in this.control.errors) {
			if (this.control.errors.hasOwnProperty(propertyName) && this.control.touched) {
				// return "error in this field";
                return ValidationService.getValidatorErrorMessage(this.translateService.getDefaultLang(), this.name, propertyName, this.control.errors[propertyName]);
			}
		}
		return null;
	}
}
