import { Directive, ElementRef, HostListener, Input ,Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { CartFunctionsService } from '../_services/cartfunctions.service';
import { NgxToasterService } from '../_services/toastr.service';
import { CommonFunctionsService } from '../_services/commonFunctions.service';
import { environment } from '../../environments/environment';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appAddToCart]'
})

export class AddToCartDirective {
    env = environment;
    @Input('product') product: any;
    @Input('quantity') quantity: any = 1;
    @Input('quickBuy') quickBuy: any = 0;
   


    @HostListener('click') onClick() {
        this.addToCart();
    }


    constructor(
        private el: ElementRef,
        public router: Router,
        public userService: UserService,
        public cartService: CartFunctionsService,
        public toasterNotification:NgxToasterService,
        public commonFunctionsService:CommonFunctionsService,
        private renderer: Renderer2,
    ) {

    }

    /**
	 * CartService:addToCart()
	 * @Description Function to add product in cart.

	 * */

    addToCart(){
        let userData   =   this.userService.getCurrentUser();
        if(Object.keys(userData).length	==	0){
            this.router.navigate(['/login'], { queryParams: { returnUrl: "/product/product-detail/"+this.product.slug }});
        }else{
            this.commonFunctionsService.blockUI();
            let formData	: any = {product_id: this.product._id, quantity: this.quantity, quick_buy: this.quickBuy, item_type: this.env.CART_TYPE_PRODUCT};
            this.cartService.addProductToCart(formData).subscribe((res: any) => {
                if(res.data.status == this.env.SUCCESS_STATUS){
                    this.commonFunctionsService.unblockUI();
                    this.toasterNotification.showSuccess(res.data.message);
                    this.product.is_in_cart =   1;
                    this.cartService.setCartNotificationEvent('1');
                    //this.cartService.setAddToCartNotificationEvent('1');
                    if(this.quickBuy == 1){
                        this.router.navigate(['/checkout'],{ queryParams: {quick_buy:1}});
                    }
                    
                    //$("#add_to_cart").trigger('click');
                }else{
                    this.toasterNotification.showError(res.data.message);
                }
            });
        }
    }


    
    

}
