import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NgxToasterService {

  constructor(private toastr: ToastrService) { }

  showSuccess(message:any){
      this.toastr.success(message)
  }

  showError(message:any){
      this.toastr.error(message)
  }

  showInfo(message:any, config:any){
      this.toastr.info(message,'',config)
  }

  showWarning(message:any){
      this.toastr.warning(message)
  }

}