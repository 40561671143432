import { Component, OnInit,Input, EventEmitter, Output} from '@angular/core';
/*import these modules for getting data from routing url */
import { Router } from '@angular/router';

import { environment } from '../../../../../environments/environment';
import { TextSetting } from '../../../../textsetting';
import { UserService } from '../../../../_services/index';

/***
 * Component
*/
@Component({
  selector: 'app-product-view',
  templateUrl: './productview.component.html'
})
export class ProductViewComponent implements OnInit {
	/***
	 * Variable 
	*/
	env					=	environment
	TextSetting			=	TextSetting
	@Input() product	:	any	=	{}
	@Input() IMAGE_URL	:	any	=	''
	@Input() RESIZEABLE_URL	:	any	=	''
	/***
	 * constructor
	*/
	constructor(
		public router: Router,
		public userService: UserService
  	) {

  	}
	/***
	 * This function is used to On Init
	*/
  	ngOnInit() {}

}
