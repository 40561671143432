<ng-container *ngIf="router.url.indexOf('checkout/thank-you') == -1 && router.url.indexOf('subscription-plans/thank-you') == -1 && router.url.indexOf('appointments/video-call') == -1">
<!--Newsletter section-->
<app-newsletter></app-newsletter>

<!--Footer section-->
<footer class="footer">
	<div class="container">
		<div class="footer-row">
			<div class="footer-column columnOne">
				<a routerLink="/"  class="footer-logo">
					<img src="{{env.SITE_IMAGE_URL}}logo.png" alt="{{env.SITE_TITLE}}">
				</a>
			</div>
			<div class="footer-column columnTwo mobile-accordian" *ngIf="categoryData.length > 0">
				<h4 (click)="footerMenuToggle($event,'active')">{{ 'GLOBAL.SHOP' | translate }}</h4>
				<ul class="footer-menu">
					<li *ngFor="let topcat of categoryData">
						<a routerLink="/product" [queryParams]="{category:topcat._id}">{{topcat.name | translate}}</a>
					</li>
					<li><a routerLink="/nutritionist-landing" href="javascript:void(0)" [ngClass]="router.url == '/nutritionist-landing' ? 'active' : '' "
						>{{ 'FOOTER.NUTRITIONIST' | translate }}</a></li>
				</ul>
			</div>
			<div class="footer-column columnThree mobile-accordian">
				<h4 (click)="footerMenuToggle($event,'active')">{{ 'FOOTER.TELE_NUTRITION' | translate }}</h4>
				<ul class="footer-menu">
					<li><a routerLink="/pages/{{env.ABOUT_US_PAGE_SLUG}}">{{ 'FOOTER.ABOUT_US' | translate }}</a></li>
					<li><a routerLink="/pages/{{env.OUR_STORY_PAGE_SLUG}}">{{ 'FOOTER.OUR_STORY' | translate }}</a></li>
					<li><a href="javascript:void(0);" class="tale_button" routerLink="/nutritionist-landing">{{ 'FOOTER.MEMBERSHIP_BENEFITS' | translate }}</a></li>					
				</ul>
			</div>
			<div class="footer-column columnFour mobile-accordian">
				<h4 (click)="footerMenuToggle($event,'active')">{{ 'FOOTER.NEED_HELP' | translate }}</h4>
				<ul class="footer-menu">
					<li><a routerLink="/faq">{{ 'FAQ_PAGE_TITLE' | translate }}</a></li>
					<li><a routerLink="/pages/{{env.TERMS_AND_CONDITION_PAGE_SLUG}}">{{ 'GLOBAL.TERMS_AND_CONDITIONS' | translate | titlecase }}</a></li>
					<li><a routerLink="/pages/{{env.PRIVACY_POLICY_PAGE_SLUG}}">{{ 'GLOBAL.PRIVACY_POLICY' | translate | titlecase }}</a></li>
					<li><a routerLink="/contact-us">{{ 'CONTACT_PAGE_TITLE' | translate }}</a></li>
				</ul>
			</div>

			<div class="footer-column columnFive">
				<div class="btn_tag">
					<!-- <a class="consuccclt_btn" href="javascript:void(0);" routerLink="/nutritionist-landing">
                        <img src="{{env.SITE_IMAGE_URL}}consuccclt-icon.png" alt="img">
                        <img class="hover_show" src="{{env.SITE_IMAGE_URL}}consuccclt-icon-hover.png" alt="img">
						{{ 'FOOTER.CONSULT_NUTRITIONLIST' | translate }}
                    </a>
					<a class="become_btn" href="javascript:void(0);" *ngIf="userService.getCurrentUser().slug == null || userService.getCurrentUser().slug == 'undefined'" appLogin [tabIndex]="loginTabIndex">
                        <img src="{{env.SITE_IMAGE_URL}}become-member.png" alt="img">
                        <img class="hover_show" src="{{env.SITE_IMAGE_URL}}become-member-hover.png" alt="img">
						{{ 'FOOTER.BECOME_A_MEMBER' | translate }}
                    </a> -->
				</div>
				<div class="footer-contact" *ngIf="result.site_settings">
					<ul>
						<li *ngIf="result.site_settings.site_contact_email">
							<a href="mailto:{{result.site_settings.site_contact_email}}"><i class="icon-envelope"></i>{{result.site_settings.site_contact_email}}</a>
						</li>
						<li *ngIf="result.site_settings.site_contact_phone">
							<a href="tel:{{result.site_settings.site_contact_phone}}"><i class="icon-phone"></i>{{result.site_settings.site_contact_phone}} </a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="footer-bottom">
		<div class="container">
			<div class="footer-copyright">
                <!-- <div class="copyright-text" *ngIf="result.site_settings">
					{{result.site_settings.site_copyright_text}}
                </div>
				<div class="design-by">
					<span>Designed by </span>
					<a target="_blank" href="https://www.fullestop.com"><img src="{{env.SITE_IMAGE_URL}}fullestop-logo.png" alt="Fullestop"></a>
				</div> -->
			</div>
		</div>
	</div>
</footer>
</ng-container>
<!--Footer section-end-->
