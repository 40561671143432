import {Inject, PLATFORM_ID, Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';
import { TextSetting } from '../textsetting';
import { UserService } from '../_services/user.service';

@Injectable()
export class AuthGuard implements CanActivate {
	env		=	environment;
	TextSetting		=	TextSetting;
	constructor(private router: Router, @Inject(PLATFORM_ID) private platformId: object, private userService: UserService) { }
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {

			const currentUser 	=	this.userService.getCurrentUser();
            if (Object.keys(currentUser).length !== 0) {
				return true;
			}
			// showErrorMessageTopRight(this.TextSetting.INVALID_ACCESS_URL);
			// Not logged in so redirect to login page with the return url and return false
			this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
			return false;

	}
}
