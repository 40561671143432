import { Directive, ElementRef , HostListener, Input } from '@angular/core';
import { Router} from '@angular/router';
import { environment } from '../../environments/environment';
import { CommonFunctionsService, UserService, NgxToasterService, CartFunctionsService } from '../_services/index';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Directive({
	selector: '[appLogout]',
})
export class LogoutDirective {

	constructor(
		private el: ElementRef,
		public router: Router,
		public userService: UserService,
		private commonFunctionsService: CommonFunctionsService,
        public toasterNotification: NgxToasterService,
        public translateService: TranslateService,
        public cartService: CartFunctionsService,
		private afService: AngularFireAuth
	) { }

	@HostListener('click') onClick(): void {
		this.logout();
	}


	// This function for logout
	private logout(): void {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if (isBrowser){
				const that		=	this;
				let user_name	=	that.userService.getCurrentUser().first_name;
                user_name 					= user_name[0].toUpperCase() + user_name.slice(1);
                this.toasterNotification.showSuccess(user_name + ', ' + this.translateService.instant('SUCCESS_LOGOUT'));
				this.cartService.setCartNotificationEvent('1');
                that.userService.purgeAuth();
				//remove social account
				this.afService.signOut().then();
				that.router.navigate(['/']);
				
			}
		});
	}
}
