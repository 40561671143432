import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'truncate', pure: false })
export class TruncateHtmlPipe implements PipeTransform {
 

   transform(value: string, args: any) : string {
		
    let limit = args > 0 ? parseInt(args, 10) : 10;
    let trail = args.length > 1 ? args[1] : '...';
		
    return value.length > limit ? value.substring(0, limit) + trail : value;
  }
}