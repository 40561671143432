import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { FormGroup, FormControl } from '@angular/forms';
import { environment } from '../../environments/environment';
import { TextSetting } from '../textsetting';
declare var swal: any;
@Injectable()

export class ValidationService {
	static getValidatorErrorMessage = (lang: string, name: string, validatorName: string, validatorValue?: any): any => {
		let min_number = environment.PASWORD_MIN_LENGTH;
		let mobileMinLength = environment.MOBILE_MIN_LENGTH;
		let mobileMaxLength = environment.MOBILE_MAX_LENGTH;
		if (lang !== undefined && lang === 'en') {
			const config: any = {

				required: name + ' is required.',
				invalidEmailAddress: 'Please enter valid email id here.',
				invalidPassword: `Password must have minimum ${min_number} characters and should contain at-least 1 alphabet and 1 number and 1 special character.`,
				mobileLengthMsg: `Mobile number should be between ${mobileMinLength} to ${mobileMaxLength} digits.`,
				invalidNumber: 'Please enter valid mobile number',
				invalidText: 'Please enter only numeric value',
				matchPassword: `Passwords do not match.`,
				invalidImage: `The image must be a type of: jpeg, jpg, png.`,
				specialCharMsg: `This field should not contain any special character and numeric value.`,
			};
			return config[validatorName];
		} else {
			const config: any = {
				required: name + 'مطلوب.',
				invalidEmailAddress: 'يرجى إدخال معرف بريد إلكتروني صالح هنا.',
				invalidPassword: `يجب أن تحتوي كلمة المرور على ${min_number} حرفًا كحد أدنى ويجب أن تحتوي على الأقل على حرف واحد ورقم واحد وحرف خاص واحد.`,
				mobileLengthMsg: `يجب أن يتراوح رقم الجوال بين ${mobileMinLength} و ${mobileMaxLength} رقم.`,
				invalidNumber: 'الرجاء إدخال رقم هاتف محمول صحيح',
				invalidText: 'يرجى إدخال قيمة رقمية فقط',
				matchPassword: `كلمة المرور غير مطابقة.`,
				invalidImage: `يجب أن تكون الصورة من نوع: jpeg، jpg، png.`,
				specialCharMsg: `يجب ألا يحتوي هذا الحقل على أي حرف خاص وقيمة رقمية.`,
			};
			return config[validatorName];
		}
	}

	// Function for email validation
	static emailValidator = (control: any): any => {
		//if ((typeof(control.value) === 'string') && control.value.match(/[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
		if ((typeof (control.value) === 'string') && control.value.match(/^(([^<>()[\]\\.,;:\s@#$%^&*"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
			return null;
		} else {
			return { invalidEmailAddress: true };
		}
	}

	// Function for special character validation
	static specialCharValidator = (control: any): any => {
		if ((typeof (control.value) === 'string') && control.value.match(/^[a-zA-Z ]+$/)) {
			return null;
		} else {
			return { specialCharMsg: true };
		}
	}

	// Function for the password validation
	static passwordValidator = (control: any): any => {
		if ((typeof (control.value) === 'string') && control.value.match(/^(?=.*[0-9])(?=.*[A-Za-z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,100}$/)) {
			return null;
		} else {
			return { invalidPassword: true };
		}
	}

	// Function for the mobile number validation
	static numberValidator = (control: any): any => {
		if ((typeof (control.value) === 'string') && control.value.match(/^[0-9]{7,15}$/)) {
			return null;
		} else {
			return { invalidNumber: true };
		}
	}

	// This function are used for only numeric condition
	static numericValidator = (control: any): any => {
		if ((typeof (control.value) === 'string') && control.value.match(/^[0-9]*$/)) {
			return null;
		} else {
			return { invalidText: true };
		}
	}

	// This function are used for only numeric condition
	static editNumericValidator = (control: any): any => {
		if (isNaN(control.value) === false) {
			return null;
		} else {
			return { invalidText: true };
		}
	}

	// Function for mobile length validation
	static mobileLengthValidator = (control: any): any => {
		if (control.value.match(/^[0-9]{7,15}$/)) {
			return null;
		} else {
			return { mobileLengthMsg: true };
		}
	}

	// Function for the match password validation
	static MatchPassword = (AC: AbstractControl): any => {
		const formGroup = AC.parent;
		if (formGroup) {
			const passwordControl = formGroup.get('password'); // to get value in input tag
			const confirmPasswordControl = formGroup.get('confirm_password'); // to get value in input tag

			if (passwordControl && confirmPasswordControl) {
				const password = passwordControl.value;
				const confirmPassword = confirmPasswordControl.value;
				if (password !== confirmPassword) {
					return { matchPassword: true };
				} else {
					return null;
				}
			}
		}
		return null;
	}

	// Function is used to check file attachment on file change event
	static validateExt = (control: any): any => {
		const extension = ['png', 'jpeg', 'jpg'];
		return extension.indexOf(control.value) ? null : { invalidImage: true };
	}


	/*This function are used validate all fields.*/
	static validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
				/* for (let propertyName in control.errors) {
					 alert(propertyName +"---"+ field);
					 break;
				 }	*/
			} else if (control instanceof FormGroup) {
				ValidationService.validateAllFormFields(control);

			}
		});
	}//end validateAllFormFields()

	// This function are used validate image fields.
	static validateImageField = (files: any): any => {
		const validation: any = {};
		validation.status = false;
		validation.message = "empty_field";
		if (files.length > 0) {
			const MAX_IMAGE_SIZE_IN_MB = environment.MAX_IMAGE_SIZE_IN_MB;
			const UPLOAD_FILE_SIZE = MAX_IMAGE_SIZE_IN_MB; // file size in MB
			const ALLOWED_IMAGE_EXTENTIONS = environment.IMAGE_ALLOWED_EXTENSIONS_ARRAY;
			const size = files[0].size.toFixed(2);
			let ext = files[0]['name'].split('.').pop();
			ext     = ext.toLowerCase();
			const allowedSize = UPLOAD_FILE_SIZE * 1024 * 1024;
			if (!ALLOWED_IMAGE_EXTENTIONS.includes(ext)) {
				validation['status'] = false;
				validation['message'] = "invalid_type";
			} else if (size > allowedSize) {
				validation.status = false;
				validation.message = "invalid_size";
			} else {
				validation.status = true;
			}
		}
		return validation;
	}// end validateImageField()
	
	// This function are used validate image fields.
	static validateFileField = (files: any): any => {
		const validation: any  =   {};
		validation.status    =   false;
		validation.message   =   "empty_field";
		//console.log(files);
		if (files.length > 0) {
			const MAX_IMAGE_SIZE_IN_MB	=	environment.MAX_IMAGE_SIZE_IN_MB;
			const ALLOWED_IMAGE_EXTENTIONS = environment.FILE_ALLOWED_EXTENSIONS_ARRAY;
			const UPLOAD_FILE_SIZE = MAX_IMAGE_SIZE_IN_MB; // file size in MB
			const size = files[0].size.toFixed(2);
			let ext = files[0]['name'].split('.').pop();
			ext = ext.toLowerCase();
			//console.log(ext);
			 const allowedSize = UPLOAD_FILE_SIZE * 1024 * 1024;	
			// if (ALLOWED_IMAGE_EXTENTIONS.indexOf(ext)=== -1) {
			// 	validation['status'] = false;
			// 	validation['message'] = "invalid_type";
			// } else if (size > allowedSize){
			// 	validation.status    =   false;
			// 	validation.message   =   "invalid_size";
			// }else{
			// 	validation.status    =   true;
			// }
			// console.log(validation.status);
					
			if (!ALLOWED_IMAGE_EXTENTIONS.includes(ext)) {
				validation['status'] = false;
				validation['message'] = "invalid_type";
			} else if (size > allowedSize){
				validation.status    =   false;
				validation.message   =   "invalid_size";
			}else{
				validation.status    =   true;
			}
		}
		return  validation;
	}// end validateImageField()

	// Function is used to open confirm poppup
	public confirm = (message: any): any => {
		return swal.fire({
			title: 'Are you sure',
			text: message,
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#0183cf',
			cancelButtonColor: '#35404e',
			confirmButtonText: 'Yes'
		}).then((result: any) => {
			return result.value;
		});
	}

	// Function is used to open confirm popup
	public newConfirm = (message: any, lang: any): any => {
		return swal.fire({
			title: (lang === 'en') ? 'Are you sure' : 'هل أنت واثق',
			text: message,
			type: 'warning',
			showCancelButton: true,
			confirmButtonColor: '#0183cf',
			cancelButtonColor: '#35404e',
			confirmButtonText: (lang === 'en') ? 'Yes' : 'نعم',
			cancelButtonText: (lang === 'en') ? 'Cancel' : 'إلغاء',
		}).then((result: any) => {
			return result.value;
		});
	}
}
