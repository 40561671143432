import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { Error404Component } from './modules/error404/error404.component';
/** Routeing **/
const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
	},

	{
		path: 'faq',
		loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule)
	},
	{
		path: 'contact-us',
		loadChildren: () => import('./modules/contact/contact.module').then(m => m.ContactModule)
	},
	{
		path: 'blog',
		loadChildren: () => import('./modules/blog/blog.module').then(m => m.BlogModule)
	},
	{
		path: 'product',
		loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule)
	},

	{
		path: 'pages/:slug',
		loadChildren: () => import('./modules/cms/cms.module').then(m => m.CmsModule)
	},
	{
		path: 'signup-thankyou',
		loadChildren: () => import('./modules/signupthankyou/signupthankyou.module').then(m => m.SignupThankYouModule)
	},
	{
		path: 'forget-password',
		loadChildren: () => import('./modules/forgetPassword/forgetPassword.module').then(m => m.ForgetPasswordModule)
	},
	{
		path: 'reset-password',
		loadChildren: () => import('./modules/resetPassword/resetPassword.module').then(m => m.ResetPasswordModule)
	},
	{
		path: 'user',
		loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule)
	},
	{
		path: 'checkout',
		loadChildren: () => import('./modules/checkout/checkout.module').then(m => m.CheckoutModule)
	},
	{
		path: 'cart',
		loadChildren: () => import('./modules/cart/cart.module').then(m => m.CartModule)
	},
	{
		path: 'subscription-plans',
		loadChildren: () => import('./modules/subscriptionPlans/subscriptionPlans.module').then(m => m.SubscriptionPlansModule)
	},
	{
		path: 'membership-plans',
		loadChildren: () => import('./modules/membershipPlans/membershipPlans.module').then(m => m.MembershipPlansModule)
	},
	{
		path: 'payment',
		loadChildren: () => import('./modules/payment/payment.module').then(m => m.PaymentModule)
	},
	{
		path: 'notifications',
		loadChildren: () => import('./modules/notifications/notifications.module').then(m => m.NotificationsModule)
	},
	{
		path: 'login',
		loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
	},
	{
		path: 'my-plan',
		loadChildren: () => import('./modules/myPlan/myplan.module').then(m => m.MyPlanModule)
	},
	{
		path: 'my-billing',
		loadChildren: () => import('./modules/billing/billing.module').then(m => m.BillingModule)
	},
	{
		path: 'nutritionists',
		loadChildren: () => import('./modules/nutritionists/nutritionists.module').then(m => m.NutritionistsModule)
	},
	{
		path: 'nutritionist-landing',
		loadChildren: () => import('./modules/nutritionistLanding/nutritionistLanding.module').then(m => m.NutritionistLandingModule)
	},
	{
		path: 'appointments',
		loadChildren: () => import('./modules/appointments/appointments.module').then(m => m.AppointmentsModule)
	},
	{
		path: 'health-concerns',
		loadChildren: () => import('./modules/healthConcern/healthConcern.module').then(m => m.HealthConcernModule)
	},
	{
		path: 'shop-by-condition',
		loadChildren: () => import('./modules/shopByConditions/shopByConditions.module').then(m => m.ShopByConditionsModule)
	},
	{
		path: 'knowledge',
		loadChildren: () => import('./modules/knowledge/knowledgen.module').then(m => m.KnowledgenModule)
	},	
	{
		path: 'article',
		loadChildren: () => import('./modules/articles/articlesn.module').then(m => m.ArticlesnModule)
	},
	{
		path: 'videos',
		loadChildren: () => import('./modules/videos/videosn.module').then(m => m.VideosnModule)
	},
	{
		path: 'product-review/:id',
		loadChildren: () => import('./modules/review/reviewn.module').then(m => m.ReviewnModule)
	},
	{
		path: 'author/:id',
		loadChildren: () => import('./modules/author/authorn.module').then(m => m.AuthornModule)
	},
	{
		path: '**', component: Error404Component
	}
];

@NgModule({
	imports: [

		RouterModule.forRoot(routes, {
			initialNavigation: 'enabled',
			preloadingStrategy: PreloadAllModules
		})
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }