import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { User } from '../core/user.module';
import { SharedService } from '../modules/shared/service/shared.service';
import { CommonFunctionsService } from '../_services/commonFunctions.service';
import {  NgxToasterService } from "../_services/toastr.service";
import { Router,ActivatedRoute  } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { TextSetting } from '../textsetting';
declare var $: any;
@Injectable()
export class UserService {
	private ngUnsubscribe: Subject<void> = new Subject<void>();
	private currentUserSubject = new BehaviorSubject<User>({} as User);
	public currentUser = this.currentUserSubject.asObservable().pipe(distinctUntilChanged());
	private isAuthenticatedSubject = new ReplaySubject<boolean>(1);
	public isAuthenticated = this.isAuthenticatedSubject.asObservable();
	SITE_API_URL: string;
	data: any		=	{};
	env			= environment;
	
	constructor(
		private sharedService: SharedService,
		public commonFunctionsService: CommonFunctionsService,
		public toasterNotification      : NgxToasterService,
		private route: ActivatedRoute,
		public router: Router,
		public translateService: TranslateService,
		@Inject(PLATFORM_ID) private platformId: object
	){
		this.SITE_API_URL =	this.env.API_URL;
	}

	// Function to get logged in user detail
	getCurrentUser = (): any => {
		if (isPlatformBrowser(this.platformId)) {
			const  currentUser: any | null | undefined  = JSON.parse(localStorage.getItem('userData') || '{}');
			if (typeof currentUser !== undefined && currentUser != null) {
				return currentUser;
			}else{
				return currentUser;
			}
		}else{
			const currentUser: any = [];
			return currentUser;
		}
	}

	// Function to update loggedin user details
	updateCurrentUserData = (): void => {
		const currentUser = JSON.parse(localStorage.getItem('userData')  || '{}');
		
		if (Object.keys(currentUser).length > 0) {
			this.commonFunctionsService.updateCurrentUserData().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
				if (res.data.status === this.env.SUCCESS_STATUS){
					const loginuserData	=	res.data.result;
					if(loginuserData.is_active && loginuserData.is_active == this.env.DEACTIVE){
						this.purgeAuth();
						this.toasterNotification.showError(TextSetting['FORCEFULLY_LOGOUT_MSG']);
						this.router.navigate(['/']);
					}else{
						localStorage.setItem('userData', JSON.stringify(loginuserData));
					}
					
				}
			});
		}
	}

	// Verify JWT in localstorage with server & load user's info.
	// This runs once on application startup.
	populate = (): void => {
		// If JWT detected, attempt to get & store user's info
		if (isPlatformBrowser(this.platformId)) {
			const  currentUser = JSON.parse(localStorage.getItem('userData')  || '{}');
			if (typeof currentUser !== undefined && currentUser != null) {
				this.setAuth(currentUser);
			}else{
				// Remove any potential remnants of previous auth states
				this.purgeAuth();
			}
		}
	}

	// Function is used to set local storage
	setAuth = (user: User): void => {
		localStorage.setItem('userData', JSON.stringify(user));
		// Set current user data into observable
		this.currentUserSubject.next(user);
		// Set isAuthenticated to true
		this.isAuthenticatedSubject.next(true);
	}

	// Function is used to remove local storage
	purgeAuth = (): void => {
		// Remove user data from localstorage
		localStorage.removeItem('userData');
		localStorage.removeItem('token');
		localStorage.removeItem('refresh_token');
		localStorage.removeItem('AddressData');
		// Set current user to an empty object
		this.currentUserSubject.next({} as User);
		// Set auth status to false
		this.isAuthenticatedSubject.next(false);
	}

	// This function are used for change the password
	submitUpdateForm = (data: any): any => {
		return this.sharedService.getPost(this.SITE_API_URL, data);
	}

	// This function are used for change the password
	getUserdata = (slug: any): any => {
		this.data.slug = slug;
		this.data.method_name = 'getUserInfo';
		return this.sharedService.getPost(this.SITE_API_URL, this.data);
	}

	// Function is used to set token on local storage
	setToken = (token: any): void => {
		localStorage.setItem('token', JSON.stringify(token));
	}

	// Function is used to set refresh token on local storage
	setRefreshToken = (refreshToken: any): void => {
		localStorage.setItem('refresh_token', JSON.stringify(refreshToken));
	}



	checkUserAccess = (): void => {
		if(this.getCurrentUser() != 'undefined' && this.getCurrentUser() != null ){
			if(this.getCurrentUser().is_active == this.env.DEACTIVE){
				this.purgeAuth();
				this.toasterNotification.showError(TextSetting['FORCEFULLY_LOGOUT_MSG']);
				this.router.navigate(['/']);
			}
		}
	}


}
