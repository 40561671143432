<!-- Cart panel -->
<div class="cart-panel" [ngClass]="[showCart  ? 'show' : '']">
	<div class="cart-panel-inr">
		<div class="mycart-header">
			<h3>{{ 'CART.MY_CART' | translate }}</h3>
			<a href="javascript:void(0);" class="close-cart-panel" (click)="CloseCart();"><i class="fa fa-times"></i></a>
		</div>
		<div class="cartlist-section">
            <ul *ngIf="cartData.result && cartData.result.length > 0">
                <li *ngFor="let product of cartData.result">
                    <div class="cart-pr-box">
                        <figure>
                        	<a routerLink="/product/product-detail/{{product.slug}}" href="javascript:void(0);">
                            <img src="{{cartData.resizable_url}}{{product.product_image}}{{env.RATIO_90_X_90}}" alt="{{product.name | titlecase}}" (load)="product.loaded = true" [hidden]="!product.loaded">
                            <img src="{{env.SITE_NO_IMAGE_URL}}{{env.RATIO_90_X_90}}" alt="{{product.name | titlecase}}" [hidden]="product.loaded">
                        </a>
                        </figure>
                        <div class="content">
                            <h4><a routerLink="/product/product-detail/{{product.slug}}" href="javascript:void(0);" >{{product.name | titlecase}}</a>
                                <a href="javascript:void(0);" (click)="removeCartItems(product.product_id)" class="cart_delete_button"><i class="fa fa-trash-alt"></i></a>
                            </h4>
                            <div class="price">{{ product.display_price | displayPrice }}</div>
                            <p class="quantity-exceed-error" *ngIf="product.stock_quantity<product.quantity && product.stock_quantity > 0">{{ 'CART.ONLY_SOME_LEFT_IN_STOCK' | translate:{quantity: product.stock_quantity} }}</p>
							<p *ngIf="product.stock_quantity <= 0"class="quantity-exceed-error">{{ 'CART.OUT_OF_STOCK_TEXT' | translate }}</p>
							<div class="i-d-button">
                                <button type="button" class="value-btn" (click)="product.quantity > 1 && decreaseQuantity(product.cart_id)">-</button>
                                <input type="text" id="qountity_input1" value="{{product.quantity}}" class="qountity-input" readonly="readonly">
                                <button type="button" class="value-btn" (click)="product.quantity < product.stock_quantity && increaseQuantity(product.cart_id)">+</button>
                            </div>
                        </div>
                    </div>
                </li>

            </ul>
            <div class="left-list"  *ngIf="cartData.result && cartData.result.length == 0">
                <app-norecordfound [norecord]="'CART.NO_ROCORD' | translate"></app-norecordfound>
            </div>
        </div>
		<div class="cart-f-bottom">
			<div class="price-total" *ngIf="cartData.cart_amount">
				<span class="left">{{'GLOBAL.TOTAL' | translate }}</span>
				<span class="Right">{{cartData.cart_amount | displayPrice }}</span>
			</div>
			<div class="ch-buttons">
				<a href="javascript:void(0)" class="btn-success c-shopping" (click)="continueShopping()">{{'CART.CONTINUE_SHOPPING' | translate }}</a>
		
				<a href="javascript:void(0)" (click)="checkout()" class="btn-success c-checkout">{{'CART.GO_TO_CART' | translate }}</a>
			</div>
		</div>
	</div>
</div>
<!-- Cart panel -->
