<div class="news_letter_section">
	<!-- <div class="container">
		<div class="news_letter_wrap">
			<div class="subscribe_content">
				<figure><img src="{{env.SITE_IMAGE_URL}}mail-box.png" alt="img"></figure>
				<div class="some_offer">
					<strong>{{ 'FOOTER.NEWSLETTER_TITLE' | translate }}</strong>
					<span>{{ 'FOOTER.NEWSLETTER_TEXT' | translate }}</span>
				</div>
			</div>
			<div class="news_letter_filed">
				<form [formGroup]="newslatterForm">
					<div class="input_filed">
						<input class="form-control" formControlName="email" type="text" placeholder="{{ 'FOOTER.PLEASE_ENTER_EMAIL' | translate }}*">
						<button type="button" (click)="submitNewslatterForm()"  [ladda]="progress" class="btn btn-fill">{{ 'FOOTER.SUBSCRIBE' | translate }}</button>
					</div>
					<control-messages *ngIf="!newslatterFormError.email"  [name]="'FORM.EMAIL' | translate" [control]="newslatterForm.controls.email"></control-messages>
					<div *ngIf="newslatterFormError.email!=''" class="error-block-form">{{ newslatterFormError.email }}</div>
				</form>
			</div>
			<div class="footer-social" *ngIf="result.social_settings">
				<a *ngIf="result.social_settings.facebook_url != ''" target="_blank" [href]="result.social_settings.facebook_url"><i class="fab fa-facebook-f"></i></a>
				<a *ngIf="result.social_settings.twitter_url != ''" target="_blank" [href]="result.social_settings.twitter_url"><i class="fab fa-twitter"></i></a>
				<a *ngIf="result.social_settings.youtube_url != ''" target="_blank" [href]="result.social_settings.youtube_url"><i class="fab fa-youtube"></i></a>
				<a *ngIf="result.social_settings.link_din_url != ''" target="_blank" [href]="result.social_settings.link_din_url"><i class="fab fa-linkedin-in"></i></a>
				<a *ngIf="result.social_settings.instagram_url != ''" target="_blank" [href]="result.social_settings.instagram_url"><i class="fab fa-instagram"></i></a>
			</div>
		</div>
	</div> -->
</div>