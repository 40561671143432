import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../environments/environment';
import { DatePipe } from '@angular/common';

/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | exponentialStrength:exponent
 * Example:
 *   {{ 2 | exponentialStrength:10 }}
 *   formats to: 1024
*/

@Pipe({name: 'displayDate'})

export class DisplayDatePipe extends DatePipe implements PipeTransform {
	env = environment;
	
	transform(value: any, args?: any): any {
		return super.transform(value, this.env.DISPLAY_DATE_FORMATE);
	}
}