import { Component, TemplateRef, OnInit, Renderer2 } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { takeUntil } from 'rxjs/operators';
import { CommonFunctionsService, UserService, CartFunctionsService, WishlistService, NgxToasterService, SocketService } from '../../../../_services/index';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SignupModalComponent } from '../signup/signupmodal.component';
import { SlideInOutAnimation } from './animations';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { ActivatedRoute, Router, Event, NavigationStart } from '@angular/router';
import { CommonService } from '../../service/common.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subject } from 'rxjs';

/**
 * Component
 */
@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.css'],
	animations: [SlideInOutAnimation]

})


export class HeaderComponent implements OnInit {

	/**
	 * Define Variable
	 */
	private ngUnsubscribe: Subject<void> = new Subject<void>();
	animationState = 'in';
	env = environment;
	openNav: boolean = false;
	isCartOpened: boolean = false;
	isOpen: boolean = false;
	open: any = [];
	scrolled: boolean = false;
	selectedItem : any = "";
	isPerformance: boolean = false;
	isPetNutrition: boolean = false;
	isWellBeing: boolean = false;
	isNutritionist: boolean = false;
	searchOpen: boolean = false;
	categoryData: any = [];
	popularTags	: any = []
	currenciesList	: any = []
	cartCount: any = 0;
	wishlistCount: any = 0;
	categoryId:any='';
	queryParamsData:any=[];
	selectedCurrency	: any =this.env.CURRENCY_CODE;
	keyword = 'name';
	data: any = [];
	searchKeyword: any = ''
	notificationCount : any = 0;
	profileImageLoaded	: boolean 	= false;
	freeShippingPrice   : any = "";

	/**Initialize popular Slider Options */
    popularSliderOptions: OwlOptions = {
		loop:true,
        dots:false,
        nav:false,
        margin:13,
        autoplay:true,
        stagePadding: 10,
        autoWidth:true,
        responsive:{
            0:{
                items:4,
                stagePadding: 10,
            },
            350:{
                items:5,
            },
            650:{
                items:6,
            },
            767:{
                items:6,
            },
            991:{
                items:7,
            },
            1199:{
                items:8,
            },
            1399:{
                items:11,
            }
        }
	}


	/**
	 * Creates an instance of header component.
	 * @param route
	 * @param router
	 * @param renderer
	 * @param commonFunctionsService
	 * @param userService
	 * @param modalService
	 * @param modalRef
	 * @param translate
	 * @param commonService
	 * @param cartFunctionsService
	 */

	/**
	 * Define constructor
	 */
	constructor(
		private route: ActivatedRoute,
		public router: Router,
		private renderer: Renderer2,
		public commonFunctionsService: CommonFunctionsService,
		public userService: UserService,
		private modalService: BsModalService,
		public modalRef: BsModalRef,
		public translate: TranslateService,
		public commonService: CommonService,
		public cartFunctionsService: CartFunctionsService,
		public wishlistService: WishlistService,
		public toasterNotification: NgxToasterService,
		public socketService: SocketService
	) {


	}


	/**
	 * on init
	 */
	ngOnInit(): void {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if (isBrowser) {
				window.addEventListener('scroll', this.scrollEvent, true);
				if (this.userService.getCurrentUser().slug != '' && this.userService.getCurrentUser().slug != 'undefined' && this.userService.getCurrentUser().slug != null){
					this.socketService.Login();
					this.getVideoCallEvent();
				}
			}
		});

		this.getHeaderCategoryData();
		this.getPopularTags();
		this.getCartItemCount();
		this.getWishlistItemCount();
		this.getWishlistNotification();
        this.getCartNotification();
        this.getAppointmentNotification();
		this.getCurrencies();
		this.getUnreadNotificationCount();
		this.getAddToCartNotification();
		this.getFreeShippingPriceData();


		// check for site currency
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if (isBrowser) {
				let ifcurrency = this.commonFunctionsService.getItem('currency')
				if(ifcurrency != null){
					this.selectedCurrency	=	this.commonFunctionsService.getItem('currency');
				}else{
					this.selectedCurrency	= this.env.CURRENCY_CODE
				}
			}
		});
		// route queryParams
		this.route.queryParams.subscribe(queryparams => {
			this.queryParamsData	=	queryparams;
			if(this.queryParamsData.category){
				let categoryId = this.queryParamsData.category;
				this.categoryId = categoryId;
			}else{
				this.categoryId = '' ;
			}
		});

		this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
			this.getHeaderCategoryData();
			this.getPopularTags();
			this.getCartItemCount();
			this.getWishlistItemCount();
			this.getWishlistNotification();
			this.getCartNotification();
			this.getAppointmentNotification();
			this.getCurrencies();
			this.getUnreadNotificationCount();
			this.getFreeShippingPriceData();
		});

		this.router.events.subscribe((event : Event) => {
            if(event instanceof NavigationStart) {
				this.isCartOpened = false;
				this.renderer.removeClass(document.body, 'cart-model-open');
            }
        });
	}


	/**
	 * Changes language
	 * @param lang
	 */
	public changeLanguage(lang: any) {
		this.commonFunctionsService.setItem('lang', lang);
		this.translate.setDefaultLang(lang);
		this.translate.use(lang);
		const languageDirections: any = this.env.LANGUAGES_DIRECTIONS
		if (languageDirections[lang] === 'ltr') {
			this.renderer.setAttribute(document.body, "dir", "ltr")
			this.renderer.removeClass(document.body, 'rtl');
		} else {
			this.renderer.setAttribute(document.body, "dir", "rtl")
			this.renderer.addClass(document.body, 'rtl');
		}
	}

	/**
	 * Open navbar of header component
	 */
	public openNavbar = (): void => {
		this.openNav = true;
		this.renderer.addClass(document.body, 'open_menu');
	}

	/**
	 * Opens dropdown
	 * @param event
	 * @param item
	 */
	public openDropdown(event: any , item : any) : void {

		if(!event.target.classList.contains('active')){
			this.selectedItem =item ;
		}else{
			event.target.classList.toggle('active');
			this.selectedItem = ''
		}
	}


	/**
	 * Close navbar of header component
	 */
	public closeNavbar = (): void => {
		this.openNav = false;
		this.renderer.removeClass(document.body, 'open_menu');
	}


	/**
	 * Toggles cart component
	 */
	toggleCartComponent() {
		this.isCartOpened = !this.isCartOpened;
		if( this.isCartOpened ){
			this.renderer.addClass(document.body, 'cart-model-open');
		}
		else{
			this.renderer.removeClass(document.body, 'cart-model-open');
		}
	}


	/**
	 * Scroll event of header component
	 */
	scrollEvent = (event: any): void => {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if (isBrowser) {
				const scroll = window.pageYOffset;
				if (scroll >= 33) {
					this.scrolled = true;
				} else {
					this.scrolled = false;
				}
			}
		})
	}


	/**
	 * Opens signup popup
	 * @param selectedtabindex
	 */
	public openSignupPopup(selectedtabindex: any) {
		const initialState = {
			tabIndex: selectedtabindex

		};
		this.modalRef = this.modalService.show(SignupModalComponent, Object.assign({}, {
			class: 'login-register-modal',
			ignoreBackdropClick: true,
			backdrop: true,
			windowClass: 'white-overlay',
			centered: true,
			animated: true,
			initialState
		}));
	}


	/**
	 * Toggles search
	 */
	public toggleSearch() {
		if (this.searchOpen) {
			this.searchOpen = false
		} else {
			this.searchOpen = true;
		}
	}


	/**
	 * Gets header category data
	 */
	public getHeaderCategoryData(): void {
		this.commonService.getHeaderCategoryData().subscribe((response: any) => {
			if (response.data.status == this.env.SUCCESS_STATUS) {
				this.categoryData = response.data.result;
				//console.log(this.categoryData);				
			}

		})
	}

	/**
	 * Gets popular tags
	 */
	public getPopularTags() : void{
		this.commonService.getPopularTags().subscribe((response: any) => {
			if (response.data.status == this.env.SUCCESS_STATUS) {
				this.popularTags = response.data.result;

			}

		})
	}


    /**
	 * Gets cart notification
	 */
	public getCartNotification(){
        this.cartFunctionsService.getCartNotificationEvent().subscribe((data:any) =>{
			this.getCartItemCount();
		})
    }



    /**
	 * Gets currencies
	 */
	public getCurrencies(){
		this.commonService.getCurrencies().subscribe((response: any) => {
			if (response.data.status == this.env.SUCCESS_STATUS) {
				this.currenciesList = response.data.result;

			}

		})
    }


    /**
	 * Gets cart item count
	 */
	public getCartItemCount(){
        if (this.userService.getCurrentUser().slug != '' && this.userService.getCurrentUser().slug != 'undefined' && this.userService.getCurrentUser().slug != null){
            this.cartFunctionsService.getCartItemCount().subscribe((response:any) =>{
    			if (response.data.status == this.env.SUCCESS_STATUS) {
    				this.cartCount = response.data.result;
    			}
    		})
        }
    }


	/**
	 * Changes currency
	 * @param currency
	 */
	public changeCurrency(currency: any) {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if (isBrowser) {
				this.commonFunctionsService.setItem('currency', currency);
				window.location.reload();
			}
		});

	}

	/**
	 * Get wishlist item count
	 */
	public getWishlistItemCount(){
        if (this.userService.getCurrentUser().slug != '' && this.userService.getCurrentUser().slug != 'undefined' && this.userService.getCurrentUser().slug != null){
            this.wishlistService.getWishlistItemCount().subscribe((response:any) =>{
    			if (response.data.status == this.env.SUCCESS_STATUS) {
    				this.wishlistCount = response.data.result;
    			}
    		})
        }
	}

	/**
	 * Gets cart notification
	 */
	public getWishlistNotification(){
        this.wishlistService.getWishlistNotificationEvent().subscribe((data:any) =>{
			this.getWishlistItemCount()
		})
    }

	/**
	 * Gets appointment notification
	 */
	public getAppointmentNotification(){
		if (this.userService.getCurrentUser().slug != '' && this.userService.getCurrentUser().slug != 'undefined' && this.userService.getCurrentUser().slug != null){
			this.commonService.getAppointmentNotification().subscribe((res:any) =>{
				if(res.data.status == this.env.SUCCESS_STATUS){
					let appointmentData: any = res.data.appointment;
					let imageUrl: any = res.data.resizable_url;
					this.commonFunctionsService.callConfirm(appointmentData, imageUrl).then((response: any) => {
						if (response) {
							this.router.navigate(['/appointments/video-call/'+appointmentData.appointment_id]);

							//this.toasterNotification.showInfo(message,{positionClass:'toast-top-full-width',disableTimeOut:true})
						}else{
							this.router.navigate(['/appointments/video-call/'+appointmentData.appointment_id],{ queryParams: { disable_video:1 }});
						}
					});
				}
			})
		}
    }

	/**
	 * Gets appointment notification
	 */
	public getVideoCallEvent(){
		this.socketService.receiveVideoCallEvent().subscribe((data:any) =>{
			this.getAppointmentNotification()
		})
	}



	/* On item select redirect to detail page */
	selectEvent( item: any ) {
		if(item.id != ""){
			this.router.navigate(['/product/product-detail/'+item.id]);
		}
	}

	/* On Enter key press */
	onEnterKeydown( event: any ){
		if (event.key === "Enter") {
			this.router.navigate(['/product'],{queryParams:{keyword:this.searchKeyword}});
		}
	}

	/* On change in search input */
	onChangeSearch(search_keyword: string) {
		this.searchKeyword = search_keyword;
		const data: any = { search_data : search_keyword };
		this.commonService.globalProductSearch(data).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
			if(response.data.status == this.env.SUCCESS_STATUS){
				this.data = response.data.result;
			}
		});
	}

	/* remove filter in search input */
	customFilter = function(countries: any[], query: string): any[] {
		return countries.filter( (items) => items);
	};

	// This function is used to get unread notification count
	public getUnreadNotificationCount(): void {
		if (this.userService.getCurrentUser().slug != '' && this.userService.getCurrentUser().slug != 'undefined' && this.userService.getCurrentUser().slug != null){
			this.commonService.getUnreadNotificationCount().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response	:	any)	=>	{
				this.notificationCount	=	response.data.count;
			});
		}
	}

	/**
	 * Gets add to cart notification
	 */
	public getAddToCartNotification(){
        this.cartFunctionsService.getAddToCartNotificationEvent().subscribe((data:any) =>{
			this.isCartOpened = true;
			this.renderer.addClass(document.body, 'cart-model-open');
		});
    }

	public filterCurrency(event: any): void {
		let value = event.target.value.toLowerCase();
		$(".currency_lists a").filter(function ():any {
			$(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
		});
	}
	public changeCurrencyMobile(event: any): void {
		let value = event.target.value.toLowerCase();
		$(".currency_lists_mobile a").filter(function ():any {
			$(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
		});
	}



	/**
	 * Get free shipping price
	 */
	 public getFreeShippingPriceData(){
		this.commonService.getFreeShippingPrice().subscribe((response: any) => {
			if (response.data.status == this.env.SUCCESS_STATUS) {
				this.freeShippingPrice = response.data.result;

			}

		})
    }

	
}
