import { Directive, ElementRef , HostListener, Input } from '@angular/core';
import {UserService} from '../_services/user.service';
import { Router} from '@angular/router';
import { environment } from '../../environments/environment';
import { CommonFunctionsService } from '../_services/index';
import { SignupModalComponent } from '../modules/shared/layout/signup/signupmodal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Directive({
	selector: '[appLogin]',
})
export class LoginDirective {
	@Input() tabIndex : any = '0';
	constructor(
		private el: ElementRef,
		public router: Router,
		public userService: UserService,
		private commonFunctionsService: CommonFunctionsService,
        private modalService: BsModalService,
		public modalRef: BsModalRef
	) { }

	@HostListener('click') onClick(): void {
		this.login();
	}


	// This function for logout
	private login(): void {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser)	=>	{
			if(isBrowser)	{
                let userData	=	this.userService.getCurrentUser();

				if(Object.keys(userData).length	==	0)	{
					const initialState = {
    			        tabIndex  : this.tabIndex,
                    };
    		        this.modalRef = this.modalService.show(SignupModalComponent,  Object.assign({}, {
            			class				: 'login-register-modal',
            			ignoreBackdropClick : true,
            			backdrop            : true,
            			windowClass         : 'white-overlay',
            			centered            : true,
            			animated            : true,
            			initialState })
                    );
                }
			}
		})
	}
}
