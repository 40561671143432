import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { Router } from '@angular/router';
import { UserService } from '../../../../_services/index';
import { CommonService } from '../../service/common.service'
import { Subject } from 'rxjs';
import { takeUntil,filter } from 'rxjs/operators';

/**
 *   Component
*/
@Component({
	selector: 'app-userleft',
	templateUrl: './userleft.component.html',
	styleUrls : ['./userleft.component.css']
})

export class UserleftComponent implements OnInit, OnDestroy {
	/**
	 * Define variable  
	*/
	private ngUnsubscribe: Subject<void> = new Subject<void>();
	env 				= environment;
	openSidebar			: boolean 	= false;
	notificationCount	: any 		= 0;
	@Input() activeTab	: any;
	profileImageLoaded	: boolean 	= false;

	/**
	 * Define  constructor
	*/
	constructor(
		public router: Router,
		public userService: UserService,
		private commonService: CommonService,

	) {

	}

    // This function are used for On Init data
	ngOnInit() {
		this.getUnreadNotificationCount();	
	}

	// This function are used for destroy the data
	ngOnDestroy(){
		this.ngUnsubscribe.next();
	}

	// This function is used to open navMenu
	public toggleSidebar = (): void => {
		if (this.openSidebar){
			this.closeSidebar();
		}else{
			this.openSidebar	=	true;
		}

	}

	// This function is used to close navMenu
	public closeSidebar = (): void => {
		this.openSidebar	=	false;
	}

	// This function is used to get unread notification count
	public getUnreadNotificationCount(): void {
		if (this.userService.getCurrentUser().slug != '' && this.userService.getCurrentUser().slug != 'undefined' && this.userService.getCurrentUser().slug != null){
			this.commonService.getUnreadNotificationCount().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response	:	any)	=>	{
				this.notificationCount	=	response.data.count;
			});
		}
	}
}
