import { Directive, ElementRef, HostListener,Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { WishlistService } from '../_services/wishlist.service';
import { TextSetting } from '../textsetting'; 
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctionsService, NgxToasterService } from '../_services/index';
import { environment } from '../../environments/environment';

declare var bootbox:any;
@Directive({
    selector: '[appRemoveFromWishlist]'
})

export class RemoveFromWishlistDirective {
    env = environment;
   
    @Input('product') product: any;

    @HostListener('click') onClick() {
        this.removeFromWishlist();
    }
 
   
    constructor(
        private el: ElementRef,
        public router: Router,
        public userService: UserService,
        public wishlistService: WishlistService,
        public toasterNotification: NgxToasterService,
        public translateService: TranslateService,
        public commonFunctionsService: CommonFunctionsService
    ) {

    }

    /**
	 * removeFromWishlist()
	 * @Description Function to add product in wishlist.
	  
	 * */

    removeFromWishlist() : void{
        let userData   =   this.userService.getCurrentUser();
        if(userData==null){
            this.toasterNotification.showError(this.translateService.instant('WISHLIST_UNAUTHORIZED_MSG'));
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});
        }
        this.commonFunctionsService.confirm(this.translateService.instant('CONFIRM_WISHLIST_ITEM_REMOVE')).then((response: any) => {
            if (response) {
                let formData	: any = {product_id: this.product._id};
                this.wishlistService.removeWishlistItem(formData).subscribe((res: any) => {
                    console.log(res);
                    if(res.data.status ==this.env.SUCCESS_STATUS){
                        this.wishlistService.setWishlistNotificationEvent('0');
                        this.toasterNotification.showSuccess(res.data.message);
                        this.product.is_favourite =   0;
                    }else{
                        this.toasterNotification.showError(res.message);
                    }
                });
            }
        });
    } 
}
