<!--Pagination-section-->
<div class="pagination-section" *ngIf="count > perPage">
	<ul class="pagination">
		<li class="page-item">
			<a class="page-link prev_page" *ngIf="page === 1" href="javascript:void(0);">&laquo; {{'PAGINATION.PREV' | translate}}</a>
			<a class="page-link prev_page" (click)="onPrev()" *ngIf="page != 1" href="javascript:void(0);">&laquo; {{'PAGINATION.PREV' | translate}}</a>
		</li>
		<li class="page-item" *ngFor="let pageNum of getPages()" >
			<a class="page-link" *ngIf="pageNum == page" [ngClass]="[(pageNum === page)?'page-item active':'page-item']" href="javascript:void(0);">{{ pageNum }}</a>
			<a class="page-link" (click)="onPage(pageNum)" *ngIf="pageNum != page" href="javascript:void(0);">{{ pageNum }}</a>
		</li>
		<li class="page-item">
			<a class="page-link next_page"  *ngIf="lastPage()" href="javascript:void(0);">{{'PAGINATION.NEXT' | translate}} &raquo;</a>
			<a class="page-link next_page"  (click)="onNext(true)" *ngIf="!lastPage()" href="javascript:void(0);">{{'PAGINATION.NEXT' | translate}} &raquo;</a>
		</li>
	</ul>
</div>
<!--Pagination-section end-->
