import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from "../modules/shared/service/shared.service";
import { environment } from '../../environments/environment';
import { Observable, of, Observer, Subject, ReplaySubject } from 'rxjs';
@Injectable()
export class CartFunctionsService {
    private cartNotification = new Subject<any>();
    private appointmentNotification = new Subject<any>();
    private addToCartNotification = new Subject<any>();
    env     =   environment;
    data    :	any		=	{};

    constructor (
        private SharedService : SharedService,
        private http: HttpClient,
    ){}


    /** Function to add document to cart
	 * @param inputData: array
	 */
	addProductToCart(inputData: any) {
        this.data['method_name']    = "addToCart";
        this.data['data']           = inputData;
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }
    /** Function to add document to cart
	 * @param inputData: array
	 */
    addPlanToCart(inputData: any) {
        this.data['method_name']    = "addToCart";
        this.data['data']           = inputData;
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }


	/** Function to get item count of cart
	 * */
	getCartItemCount() {
    	this.data['method_name']    = "getCartCount";
    	this.data['data']           = {}
    	return this.SharedService.getPost(this.env.API_URL , this.data);
	}


  	/** Function to get item count of cart
	 * @param formData: any for formData
	 */
	getCartItems(inputData: any) {
        this.data['method_name']    = "cartItemList";
        this.data['data']           = inputData
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }

   	/** Function to get item count of cart
	 * @param slug: any for slug
	 */
	removeCartItems(inputData: any) {
        this.data['method_name']    = "deleteFromCart";
        this.data['data']           = inputData;
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }
   	/** Function to get item count of cart
	 * @param slug: any for slug
	 */
	updateCartQty(inputData: any) {
        this.data['method_name']    = "updateCartQty";
        this.data['data']           = inputData;
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }

   	/** Function to apply coupon code
	 * @param inputData: any form Data
	 */
	applyCoupon(inputData: any) {
        this.data['method_name']    = "applyCoupon";
        this.data['data']           = inputData;
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }


 	 /** Function to get item count of cart
	 * @param slug: any for slug
	 */
	saveOrder(formData:any) {
        this.data['method_name']    = "saveOrder";
        this.data['data']           = formData
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }

    /** Function to get item count of cart
	 * @param inputData: any data
	 */
	checkoutSuccess(inputData: any) {
        this.data['method_name'] = "checkoutSuccess";
        this.data['data'] = inputData;
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }

    /** Function to get payment cms data*/
	getPaymentCms() {
        this.data['method_name']    = "getPaymentCms";
        this.data['data']           = {}
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }
    /** Function to get discount slabs*/
	getDiscountSlabs() {
        this.data['method_name']    = "getDiscountSlabs";
        this.data['data']           = {}
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }

    // This function is used to set cart Notification event
    setCartNotificationEvent(message: any) {
		this.cartNotification.next(message);
	}

    // This function is used to get cart Notification event
	getCartNotificationEvent(): Observable<any> {
		return this.cartNotification.asObservable();
	}
    // This function is used to set cart Notification event
    setAppointmentNotificationEvent(message: any) {
		this.appointmentNotification.next(message);
	}

    // This function is used to get cart Notification event
	getAppointmentNotificationEvent(): Observable<any> {
		return this.appointmentNotification.asObservable();
    }
    
    // This function is used to set add to cart Notification event
    setAddToCartNotificationEvent(message: any) {
		this.addToCartNotification.next(message);
	}

    // This function is used to get add to cart Notification event
	getAddToCartNotificationEvent(): Observable<any> {
		return this.addToCartNotification.asObservable();
	}

}
