import { Component, OnInit } from "@angular/core";
import { Router, NavigationStart, NavigationEnd, Event } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { environment } from "../../../../../environments/environment";
import { CommonService } from "../../service/common.service";
import {
    CommonFunctionsService,
    ValidationService,
    UserService,
    SeoService
} from "../../../../_services/index";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";
/**
* Define Component
*/
@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.css"],
    providers: [ValidationService]
})
export class FooterComponent implements OnInit {
    /**Define Variable */
    private ngUnsubscribe: Subject<void> = new Subject<void>();
    env = environment;
    blockSection: any = "";
    followUsSection: any = "";
    copyRightsText: any = "";
    social_liresultnk: any = {};
    progress: boolean | number = false;
    result: any = [];
    about_result: any = [];
    submitted: boolean = false;
    newsletterForm: FormGroup;
    newsletterFormError: any = {};
    newsletterFormStatus: any = "";
    categoryData: any = [];
    loginTabIndex: any = '1';

    /**
    * Define constructor 
    */
    constructor(
        public commonFunctionsService: CommonFunctionsService,
        public userService: UserService,
        private commonService: CommonService,
        public router: Router,
        public formBuilder: FormBuilder,
        public translateService: TranslateService,
        public seoService: SeoService,
    ) {
        this.newsletterForm = this.formBuilder.group({});
      
    } 
    /** this function is used for component initialization*/
    ngOnInit(): void {
        this.getBlockData();
        this.createNewsletterForm();
        this.getCategoryData();

        this.router.events.subscribe((event : Event) => {
            if(event instanceof NavigationStart) {
                this.userService.checkUserAccess();
            }

            if(event instanceof NavigationEnd) {
                this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
                    if (isBrowser) {
                        this.userService.updateCurrentUserData();
                    }
                })
            }
        });
        /** Function Calling On Change of language Dropdown */
        this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
			this.getBlockData();
            this.createNewsletterForm();
            this.getCategoryData();
		});
    }
    /**
	 * Creates Newsletter form : This function is used to create Newsletter Form
	 */
    public createNewsletterForm() {
        this.newsletterForm = this.formBuilder.group({
            email: ["", [Validators.required, ValidationService.emailValidator]]
        });
    }

    /**
	 * get Category Data : This function is used to get Category Data
	 */
	public getCategoryData(): void {
		this.commonService.getHeaderCategoryData().subscribe((response: any) => {
			if (response.data.status == this.env.SUCCESS_STATUS) {
				this.categoryData = response.data.result;
			}
		});
	}

    /**
	 *get Block Data : This function is used to get Block Data
	 */
    public getBlockData(): void {
        const formData: any = { block_slug: "about-us" };
        this.commonService
            .getBlockData(formData)
            .pipe(takeUntil(this.ngUnsubscribe))
            .subscribe((response: any) => {
                if (response.data.status == this.env.SUCCESS_STATUS) {
                    this.about_result = response.data;
                }
            });
    }
    /**
	 * subscribe : This function is used to subscribe
	 */
    public subscribe() {
        if (this.newsletterForm.valid) {
            this.submitted = true;
            let data: any = {};
            data = this.newsletterForm.value;
        } else {
            this.progress = false;
            ValidationService.validateAllFormFields(this.newsletterForm);
        }
    }
    /**
	 * footer Menu Toggle : This function is used to footer Menu Toggle
	 */
    public footerMenuToggle(event: any, className: string) {
        const hasClass          = event.target.classList.contains(className);
      
        if(hasClass) {
            event.target.classList.remove(className);
            event.target.parentNode.classList.remove(className);
        } else {
            event.target.classList.add(className);
            event.target.parentNode.classList.add(className);
        }
    }
}
