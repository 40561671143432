export const TextSetting = {
	/********************************PAGE_TITLE TEXTS ***************************************/
	HOME_PAGE_TITLE								:	'Home',
	MY_ACCOUNT_PAGE_TITLE						:	'My-Account',
	EDIT_PROFILE_PAGE_TITLE						:	'Edit-Profile',
	CHANGE_PASSWORD_PAGE_TITLE					:	'Change-Password',
	THANK_YOU_PAGE_TITLE						:	'Thank-You',
	COMMING_SOON_PAGE_TITLE						:	'Comming-Soon',
	LOGIN_PAGE_TITLE							:	'Login',
	SIGNUP_PAGE_TITLE							:	'Signup',
	FAQ_PAGE_TITLE								:	'Faq',

	/********************************ERROR MESSAGE TEXTS ***************************************/
	ERROR_404									:	'ERROR 404',
	ERROR_DESCRIPTION							:	'Oopss... this is embarassing, either you tried to access a non existing page, or our server has gone crazy.',
	ERROR_CLICK_HERE							:	'<a href="#">Click Here</a> to go back home',
	PASSWORD_NOT_MATCH_MSG						:	'Passwords do not match.',
	NOT_ALLOWED									:	"You are not allowed to access this page.",
	VALID_SIZE_IMAGE_ERROR_MSG					:	'Please upload valid size image. Maximum uploaded file size',
	VALID_SIZE_FILE_ERROR_MSG					:	'Please upload valid size file. Maximum uploaded file size',
	UPLOAD_IMAGE_ERROR_MSG						:	'Please upload image.',
	UPLOAD_FILE_ERROR_MSG						:	'Please upload file.',
	ALLOWED_IMAGE_TYPE_MESSAGE					:   'The image must be a type of: jpeg, jpg, png.',	
	ALLOWED_FILE_TYPE_MESSAGE          			:   'The image must be a type of: jpeg, jpg, png, pdf, doc, docx.',
	INVALID_ACCESS_URL							:	'You are not allowed to perform this action.Please login first.',
	WELCOME_LOGIN                               :   "Welcome",
	SUCCESS_LOGIN                               :   "You have successfully logged in.",
	FORCEFULLY_LOGOUT_MSG						:	'Your account is either deactivated. Please contact site administrator.',



	/**************************************** PROPERTY FIELD NAME FOR VALIDATION**************************************************** */
	FIRST_NAME							:	'First name',
	LAST_NAME							:	'Last name',
	EMAIL								:	"Email",
	PHONE_NUMBER						:	"Phone number",
	GENDER								:	"Gender",
	PASSWORD							:	"Password",		
	C_PASSWORD							:	"Confirm password",
	EMAIL_PHONE							:	"Email/Phone",
	THIS								:	"This field",
	MOBILE_NUMBER						:	"Mobile number",
	CONFIRM_MOBILE_NUMBER				:	"Confirm mobile number",

	/******************************************* BREADCRUMB ***************************************************************************/
	BREADCRUMB_HOME						:	"Home",
	BREADCRUMB_FAQ						:	"Faq",
	BREADCRUMB_CONTACT_US				:	"Contact Us",
	BREADCRUMB_BLOG						:	"Blog",
	BREADCRUMB_ARCHIVES					:	"Archives",
	BREADCRUMB_DATE						:	"March 2021",
	BREADCRUMB_PAGE_TITLE				:	"5 Things You Should Never Do Post-Workout",
	BREADCRUMB_CATEGORY					:	"Fashion",
	BREADCRUMB_PRODUCT_LIST				:	"Performance",
	BREADCRUMB_PRODUCT_CATEGORY			:	"Protein",
	/***************************************************************************************************************************** */

	NO_ACTIVE_PLAN						:		"You have no active plan available now."
};
