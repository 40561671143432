<!-- Header Start -->
<header class="header" [ngClass]="{'fixed' : scrolled,'after_login':(userService.getCurrentUser().slug != '' && userService.getCurrentUser().slug != 'undefined' && userService.getCurrentUser().slug != null)}" *ngIf="router.url.indexOf('checkout/thank-you') == -1 && router.url.indexOf('subscription-plans/thank-you') == -1  && router.url.indexOf('appointments/video-call') == -1">
    <div class="header_top">
        <div class="container">
            <div class="header_top_section">
                <div class="header_top_left">

                </div>
                <div class="header_top_right">
                    <div class="select_contry">
                        <div class="dropdown">
                            <button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<ng-container *ngIf="translate.currentLang == 'ar'">عربى</ng-container>
								<ng-container *ngIf="translate.currentLang == 'en'">English</ng-container>
							</button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <a *ngFor="let lang of env.LANGUAGES_ARRAY" class="dropdown-item" (click)="changeLanguage(lang)" href="javascript:void(0)">{{ (lang == 'ar') ? 'عربى' : 'English' }}</a>
                            </div>
                        </div>
                    </div>
                    <div class="select_contry">
                        <div class="dropdown">
                            <button class="dropdown-toggle" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							{{selectedCurrency}}
							</button>
                            <div class="dropdown-menu currency_dropdown" aria-labelledby="dropdownMenuButton2">
                                <div class="currency_search"><input type="text" (keyup)="filterCurrency($event)" class="search_currency" placeholder="{{ 'GLOBAL.SEARCH' | translate }}" /></div>
                                <div class="currency_lists">
                                    <a (click)="changeCurrency(currency.key)" *ngFor="let currency of currenciesList" class="dropdown-item" href="javascript:void(0)">{{currency.value}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="login_signup" *ngIf="userService.getCurrentUser().slug == null || userService.getCurrentUser().slug == 'undefined'">
                        <a href="javascript:void(0);" (click)="openSignupPopup('0');" class="login-btn" data-toggle="modal" data-target="#exampleModal">{{ 'GLOBAL.LOGIN' | translate }}</a>
                        <a href="javascript:void(0);" (click)="openSignupPopup('1');" data-toggle="modal" data-target="#exampleModal"> {{ 'GLOBAL.SIGNUP' | translate }}</a>
                    </div>
                    <div class="login_signup" *ngIf="userService.getCurrentUser().slug != '' && userService.getCurrentUser().slug != 'undefined' && userService.getCurrentUser().slug != null">
                        <div class="dropdown">
                            <button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
								<figure>
									<ng-container *ngIf="userService.getCurrentUser().image">
										<img src="{{userService.getCurrentUser().resizable_url}}{{userService.getCurrentUser().image}}{{env.RATIO_26_X_26}}" alt="{{userService.getCurrentUser().full_name}}" (load)="profileImageLoaded = true" [hidden]="profileImageLoaded != true">
										<img src="{{env.SITE_USER_NO_IMAGE_URL}}{{env.RATIO_26_X_26}}" alt="profile-image" [hidden]="profileImageLoaded == true">
									</ng-container>
									<img *ngIf="!userService.getCurrentUser().image" src="{{env.SITE_USER_NO_IMAGE_URL}}{{env.RATIO_26_X_26}}" alt="profile-image">
								</figure>
								{{userService.getCurrentUser().full_name}}
							</button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <a class="dropdown-item arabiclang" routerLink="/user/profile">
                                    <img src="{{env.SITE_IMAGE_URL}}dashboard-blue.svg" alt="dashboard">
                                    <img class="showhover" src="{{env.SITE_IMAGE_URL}}dashboard.svg" alt="dashboard"> {{ 'DASHBOARD' | translate }}
                                </a>
                                <a class="dropdown-item englishlang" href="javascript:void(0);" appLogout>
                                    <img src="{{env.SITE_IMAGE_URL}}logout-blue.svg" alt="logout">
                                    <img class="showhover" src="{{env.SITE_IMAGE_URL}}logout.svg" alt="logout"> {{ 'GLOBAL.LOGOUT' | translate }}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="header-mid">
        <div class="container">
            <div class="main_header">
                <div class="logo">
                    <div class="nav-toggle" (click)="openNavbar();">
                        <i class="fa fa-bars"></i>
                    </div>
                    <a routerLink="/"><img src="{{env.SITE_IMAGE_URL}}logo.png" alt="{{env.SITE_TITLE}}"></a>
                </div>
                <div class="header_menu" [ngClass]="[openNav  ? 'show' : '']">
                    <div class="menu" [ngClass]="[openNav  ? 'show' : '']">
                        <div class="mobile-header">
                            <a routerLink="/" class="mobile-logo">
                                <img src="{{env.SITE_IMAGE_URL}}logo.png" alt="{{env.SITE_TITLE}}">
                            </a>
                            <a href="javascript:void(0);" class="close-btn" (click)="closeNavbar();" [ngClass]="[openNav  ? 'navbar-close show' : 'navbar-close']">
                                <i class="fa fa-times"></i>
                            </a>
                        </div>
                        <ul>
                            <ng-container *ngIf="categoryData.length>0">
                                <li *ngFor="let topcat of categoryData; index as i;" [ngClass]="[(topcat.sub_category.length >0) ? 'submenu header_li' : 'header_li']" class="{{topcat.header_class}}">
                                    <a *ngIf="topcat.slug !='knowledge'" routerLink="/product" [queryParams]="{category:topcat._id}" [ngClass]="[(categoryId == topcat._id) ? 'active header_box' : 'header_box']" (click)="openDropdown($event,topcat)">
										{{topcat.name | titlecase}}
									</a>                                 
                                    <div class="menu-dropdown" [ngClass]="[(selectedItem == topcat) ? 'active ' : '']" [ngStyle]="{'display'  : (selectedItem == topcat) ? 'block' : 'none'}">
                                        <ng-container *ngIf="topcat.sub_category.length >0">
                                            <div class="dropdown-row">
                                                <div *ngFor="let topsubcat of topcat.sub_category" class="dropdown-column acordianlist">
                                                    <h4>
                                                        <a *ngIf="topcat.slug !='knowledge'" routerLink="/product" [queryParams]="{category:topcat._id, subcategory : topsubcat._id }">
                                                            <span class="header_sub_menu_span">	{{topsubcat.name | titlecase}} </span>
                                                        </a>
                                                    </h4>
                                                    <ng-container *ngIf="topsubcat.child_sub_category.length >0">
                                                        <div class="menu_list">
                                                            <ul>
                                                                <li *ngFor="let childcat of topsubcat.child_sub_category" class="submenu-list">
                                                                    <a routerLink="/product" [queryParams]="{category:topcat._id, subcategory : topsubcat._id,sub_category1 : childcat._id }">
																		{{childcat.name | translate }} 
																	<span *ngIf="childcat.information_text" style="color: #6c7591;" class="cstm-tooltip" [tooltip]="childcat.information_text | translate " [options]="env.TOOLTIP_OPTIONS" placement="top"><i class="fa fa-info-circle" aria-hidden="true"></i></span>
																	</a>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </ng-container>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </li>
                            </ng-container>
                            <li *ngIf="userService.getCurrentUser().slug != '' && userService.getCurrentUser().slug != 'undefined' && userService.getCurrentUser().slug != null">
                                <a routerLink="/knowledge" href="javascript:void(0)" [ngClass]="router.url == '/knowledge' ? 'active knowledge_tab' : 'knowledge_tab' ">Knowledge</a>
                            </li>
                            <li>
                                <a routerLink="/nutritionist-landing" href="javascript:void(0)" [ngClass]="router.url == '/nutritionist-landing' ? 'active nutritionist_tab' : 'nutritionist_tab' ">{{ 'GLOBAL.ASK_ME_EXPERTS' | translate }}</a>
                            </li>
                            <li class="membership_btn">
                                <a routerLink="/subscription-plans" href="javascript:void(0)"><span>{{ 'GLOBAL.SAVE_MORE' | translate }}</span> <b>{{ 'GLOBAL.TELE' | translate }}<img
								src="{{env.SITE_IMAGE_URL}}plus-small.png" alt="img"> </b> {{ 'GLOBAL.MEMBERSHIP' | translate }}</a>
                            </li>
                            <li class="customer_support_mbl">
                                <div class="customer_support">
                                    <a href="javascript:void(0);" class="customer_support_link" routerLink="/pages/{{env.CUSTOMER_SUPPORT_PAGE_SLUG}}">
									{{ 'GLOBAL.CUSTOMER_SUPPORT' | translate }}
									</a>
                                </div>
                            </li>
                            <li class="multi_language">
                                <div class="select_contry">
                                    <div class="dropdown">
                                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											<ng-container *ngIf="translate.currentLang == 'ar'">عربى</ng-container>
											<ng-container *ngIf="translate.currentLang == 'en'">English</ng-container>   
										</button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <a *ngFor="let lang of env.LANGUAGES_ARRAY" class="dropdown-item" (click)="changeLanguage(lang)" href="javascript:void(0)">{{ (lang == 'ar') ? 'عربى' : 'English' }}</a>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="usd_mobile">
                                <div class="select_contry">
                                    <div class="dropdown">
                                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
										{{selectedCurrency}}
										</button>
                                        <div class="dropdown-menu currency_dropdown" aria-labelledby="dropdownMenuButton2">
                                            <div class="currency_search"><input type="text" (keyup)="filterCurrency($event)" class="search_currency" placeholder="{{ 'GLOBAL.SEARCH' | translate }}" /></div>
                                            <div class="currency_lists currency_lists_mobile">
                                                <a (click)="changeCurrencyMobile(currency.key)" *ngFor="let currency of currenciesList" class="dropdown-item" href="javascript:void(0)">{{currency.value}}</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>

                        </ul>
                    </div>
                    <div class="menu-overlay" (click)="closeNavbar();" [ngClass]="[openNav  ? 'show' : '']"></div>
                </div>
                <div class="main_header_right">
                    <div class="mobile_main_header_right">
                        <div class="search-mobile" (click)="toggleSearch()">
                            <i class="icon-search"></i>
                        </div>
                        <div class="cart-mobile cart-panel-open" *ngIf="router.url.indexOf('/payment') == -1">
                            <a href="javascript:void(0)" (click)="toggleCartComponent()" *ngIf="router.url.indexOf('/cart') == -1 && router.url.indexOf('/payment') == -1 && router.url.indexOf('/checkout') == -1 &&userService.getCurrentUser().slug != null && userService.getCurrentUser().slug != 'undefined'">
                                <i class="icon-add-to-cart"></i><span class="cart-item" *ngIf="cartCount>0 && cartCount < 100">{{cartCount}}</span>
                                <span class="cart-item" *ngIf="cartCount >= 100">99+</span>
                            </a>
                            <a href="javascript:void(0)" (click)="openSignupPopup('0')" *ngIf="userService.getCurrentUser().slug == null || userService.getCurrentUser().slug == 'undefined'">
                                <i class="icon-add-to-cart"></i>
                            </a>
                        </div>
                        <div class="notification-mobile" *ngIf="userService.getCurrentUser().slug != null && userService.getCurrentUser().slug != 'undefined'">
                            <a href="javascript:void(0)" routerLink="/notifications">
                                <i class="icon-bell"><img src="{{env.SITE_IMAGE_URL}}bell-icon.svg" alt="notifications"></i>
                            </a>
                        </div>
                        <div class="user-mobile" *ngIf="userService.getCurrentUser().slug == null || userService.getCurrentUser().slug == 'undefined'">
                            <a href="javascript:void(0);" (click)="openSignupPopup('0');" class="login-btn" data-toggle="modal" data-target="#exampleModal">
                                <img src="{{env.SITE_IMAGE_URL}}user-icon.svg" alt="user">
                            </a>
                        </div>
                        <div class="user-mobile" *ngIf="userService.getCurrentUser().slug != null && userService.getCurrentUser().slug != 'undefined'">
                            <div class="dropdown mobile-dropdown">
                                <button class="dropdown-toggle" type="button" id="loginMenuButton12" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
									<img src="{{env.SITE_IMAGE_URL}}user-icon.svg" alt="user">
								</button>
                                <div class="dropdown-menu" aria-labelledby="loginMenuButton12">
                                    <a class="dropdown-item arabiclang" routerLink="/user/profile">
                                        <img src="{{env.SITE_IMAGE_URL}}dashboard-blue.svg" alt="dashboard">
                                        <img class="showhover" src="{{env.SITE_IMAGE_URL}}dashboard.svg" alt="dashboard"> {{ 'DASHBOARD' | translate }}
                                    </a>
                                    <a class="dropdown-item englishlang" routerLink="/user/wishlists">
                                        <img src="{{env.SITE_IMAGE_URL}}wishlist-dashboard-mobile.svg" alt="wishlist">
                                        <img class="showhover" src="{{env.SITE_IMAGE_URL}}wishlist-dashboard-white.svg" alt="wishlist"> {{ 'GLOBAL.MY_WISHLIST' | translate }}
                                    </a>
                                    <a class="dropdown-item englishlang" href="javascript:void(0);" appLogout>
                                        <img src="{{env.SITE_IMAGE_URL}}logout-blue.svg" alt="logout">
                                        <img class="showhover" src="{{env.SITE_IMAGE_URL}}logout.svg" alt="logout"> {{ 'GLOBAL.LOGOUT' | translate }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="customer_support">

                        <a href="javascript:void(0);" class="customer_support_link" onclick="window.fcWidget.open();window.fcWidget.show();">
                            <img src="{{env.SITE_IMAGE_URL}}chatIcon.png" style="width: 32px;">
                        </a>
                    </div>
                    <div class="shopping_cart">
                        <a class="search_scroll" href="javascript:void(0);" (click)="toggleSearch()">
                            <figure><img src="{{env.SITE_IMAGE_URL}}search-blue.svg" alt="search"></figure>
                        </a>
                        <a class="notification-icon" routerLink="/notifications" href="javascript:void(0)" *ngIf="userService.getCurrentUser().slug != null && userService.getCurrentUser().slug != 'undefined'">
                            <figure>
                                <img src="{{env.SITE_IMAGE_URL}}bell_icon.svg" alt="notification">
                                <span class="cart-item" *ngIf="notificationCount>0 && notificationCount < 100">{{notificationCount}}</span>
                                <span class="cart-item" *ngIf="notificationCount >= 100">99+</span>
                            </figure>
                        </a>
                        <a routerLink="/user/wishlists" *ngIf="userService.getCurrentUser().slug != null && userService.getCurrentUser().slug != 'undefined'">
                            <figure>
                                <img src="{{env.SITE_IMAGE_URL}}wishlist-blue.svg" alt="wishlist">
                                <span class="cart-item" *ngIf="wishlistCount>0 && wishlistCount < 100">{{wishlistCount}}</span>
                                <span class="cart-item" *ngIf="wishlistCount >= 100">99+</span>
                            </figure>
                        </a>
                        <a routerLink="/user/wishlists" *ngIf="userService.getCurrentUser().slug == null || userService.getCurrentUser().slug == 'undefined'">
                            <figure><img src="{{env.SITE_IMAGE_URL}}wishlist-blue.svg" alt="wishlist"></figure>
                        </a>
                        <a href="javascript:void(0);" class=" cart-panel-open" routerLink="/cart" *ngIf="router.url.indexOf('/cart') == -1 && router.url.indexOf('/payment') == -1 && router.url.indexOf('/checkout') == -1 && userService.getCurrentUser().slug != null && userService.getCurrentUser().slug != 'undefined'">

                            <figure>
                                <img src="{{env.SITE_IMAGE_URL}}cart1.png" alt="Shopping Cart">
                                <span class="cart-item" *ngIf="cartCount > 0 && cartCount < 100">{{cartCount}}</span>
                                <span class="cart-item" *ngIf="cartCount >= 100">99+</span>
                            </figure>
                        </a>
                        <a href="javascript:void(0);" class=" cart-panel-open" (click)="openSignupPopup('0')" *ngIf="userService.getCurrentUser().slug == null || userService.getCurrentUser().slug == 'undefined'">
                            <figure><img src="{{env.SITE_IMAGE_URL}}cart1.png" alt="Shopping Cart"></figure>
                        </a>
                    </div>
                    <div class="mobile-search-overlay"></div>
                </div>
            </div>
        </div>
    </div>
</header>
<!-- Header End -->
<div class="how_can_help" [ngClass]="{'scroll_fixed':searchOpen, 'how_can_inner':(router.url != '/'  && router.url != '/shop-by-condition' && router.url != '/health-concerns')}">
    <div class="container">
        <div class="how_can_help_details">

            <div class="how_can_help_right">
                <div class="search_feild">
                    <button type="button" class="search-back">
					<i class="fa fa-arrow-left"></i>
					</button>
                    <div class="ng-autocomplete">
                        <ng-autocomplete [data]="data" placeholder="{{ 'GLOBAL.SEARCH_PLACEHOLDER' | translate }}" [searchKeyword]="keyword" [customFilter]="customFilter" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate"
                            (keyup)="onEnterKeydown($event)">
                        </ng-autocomplete>
                        <ng-template #itemTemplate let-item>
                            <a [innerHTML]="item.name"></a>
                        </ng-template>
                        <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                        </ng-template>
                    </div>
                    <button type="button" class="search-btn">
					<img src="{{env.SITE_IMAGE_URL}}search.svg" alt="Search">
					</button>
                </div>

            </div>
        </div>
    </div>
</div>

<app-cart [showCart]="isCartOpened" (closeCart)="toggleCartComponent()"></app-cart>