<!-- nutritionist List section  -->
<div class="nutritionist_product_box nutritionist_box_height">
    <div class="consult-online-box">
        <span class="nutritionist_online" *ngIf="nutritionist.is_doctor_online == '1'" [tooltip]="'online' | translate" [options]="env.TOOLTIP_OPTIONS" placement="top"></span>
        <figure>
            <img src="{{RESIZEABLE_URL}}{{nutritionist.image}}{{env.RATIO_202_X_202}}" alt="{{nutritionist.full_name}}" (load)="nutritionist.loaded = true" [hidden]="!nutritionist.loaded">
            <img src="{{env.SITE_USER_NO_IMAGE_URL}}{{env.RATIO_202_X_202}}" alt="{{nutritionist.full_name}}" [hidden]="nutritionist.loaded">
        </figure>
        <div class="consult-online-text">
            <h4>{{nutritionist.full_name}}</h4>
            <p>{{nutritionist.profession_name}}</p>
            <label *ngIf="nutritionist.spoken_language_name"><img src=" {{env.SITE_IMAGE_URL}}language.svg" alt="img"> {{nutritionist.spoken_language_name}}</label>
            <label><img src=" {{env.SITE_IMAGE_URL}}location.png" alt="img">{{nutritionist.country_name}}</label>
            </div>
    </div>
    <div class="nutritionist_profile view_all_btn">
        <a href="javascript:void(0)" (click)="userCheckPlanByDietician(nutritionist._id,nutritionist.slug)" class="btn-success">{{ 'APPOINTMENT.ViewProfile' | translate }}</a>
        <a href="javascript:void(0)" (click)="userCheckPlanByDietician(nutritionist._id,nutritionist.slug)" class="btn-success">{{ 'APPOINTMENT.BOOK_APPOINTMENT' | translate }}</a>
    </div>
    <div class="rating"><span><img src=" {{env.SITE_IMAGE_URL}}rating.svg" alt="img">{{nutritionist.avg_rating}}</span></div>
</div>
<!-- nutritionist List section  -->

