<!-- breadcrumb start -->
<div class="breadcrub-section">
    <div class="container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb"  *ngIf="breadcrumb   !=  ''">
                <li class="breadcrumb-item"   *ngFor="let item of breadcrumb; let i = index;">
                    <ng-container *ngIf="item.url">
                        <ng-container	*ngIf="item.url != '/contact-us' ">
                            <a *ngIf="item.query_param" routerLink="{{item.url}}"[queryParams]="item.query_param">{{item.text    |   translate}}</a>
                            <a *ngIf="!item.query_param" routerLink="{{item.url}}">{{item.text    |   translate}}</a>
                        </ng-container>
                        <ng-container	*ngIf="item.url == '/contact-us' ">
                            <a style="color:black" routerLink="{{item.url}}">{{item.text    |   translate}}</a>
                        </ng-container>
                    </ng-container>
                    <ng-container *ngIf="!item.url">
                        <a href="javascript:void(0);">
                            {{item.text |   titlecase}}
                        </a>
                    </ng-container>
                </li>
            </ol>
        </nav>
    </div>
</div>
<!-- breadcrumb end -->
