/** Import Angular Built-In Packages and Custom Files and Services */
import { Component, OnInit,OnDestroy,ViewEncapsulation,Input, Output,EventEmitter} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Router} from '@angular/router';
import { CommonFunctionsService, CartFunctionsService, NgxToasterService, UserService } from '../../../../_services/index';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
/** Component **/
@Component({
	selector: 'app-cart',
	templateUrl: './cart.component.html',
	styleUrls: ['./cart.component.css'],
	encapsulation: ViewEncapsulation.None
})
export class CartComponent implements OnInit, OnDestroy {
	@Input() showCart: any;
	@Output() closeCart  = new EventEmitter<boolean>();
	private ngUnsubscribe: Subject<void> = new Subject<void>();
	/** Define Variables */
	env = environment;
	cartData: any = {};

	/** Define Constructor */
	constructor(
		private commonFunctionsService		: CommonFunctionsService,
        public userService: UserService,
		private cartFunctionsService		: CartFunctionsService,
		public router						: Router,
		public modalRef				        : BsModalRef,
        public translateService: TranslateService,
        public toasterNotification:NgxToasterService
	) {

	}

	/** this function is used for component initialization*/
	ngOnInit(): void {
        this.getCartItems();
        this.getCartNotification();
	}

    // This function is used to get cart items
    public getCartItems(): void {
        if (this.userService.getCurrentUser().slug != '' && this.userService.getCurrentUser().slug != 'undefined' && this.userService.getCurrentUser().slug != null){
            const formData:any = {};
            this.cartFunctionsService.getCartItems(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any)=>{
                if (response.data.status == this.env.SUCCESS_STATUS) {
                    this.cartData = response.data;
                }
            })
        }else{
            this.cartData = {};
        }
    }

    // This function is used to decrease cart item quantity
    public decreaseQuantity(cart_id: any){
        const formData: any = {cart_id: cart_id, update_status: 0}
        this.updateCartQty(formData);
    }

    // This function is used to increase cart item quantity
    public increaseQuantity(cart_id: any){
        const formData: any = {cart_id: cart_id, update_status: 1}
        this.updateCartQty(formData);
    }

    // This function is used to call api for update cart item quantity
    public updateCartQty(formData: any){
        this.commonFunctionsService.blockUI();
        this.cartFunctionsService.updateCartQty(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any)=> {
            if (response.data.status == this.env.SUCCESS_STATUS) {
                this.commonFunctionsService.unblockUI();
                this.getCartItems();
                this.cartFunctionsService.setCartNotificationEvent('1');
            }
        })
    }

    // This function is used to remove cart item
    public removeCartItems(product_id: any){
        this.commonFunctionsService.confirm(this.translateService.instant('CONFIRM_CART_ITEM_REMOVE')).then((response: any) => {
			if (response) {
                const formData: any = {product_id: product_id}
                this.cartFunctionsService.removeCartItems(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any)=> {
                    if (res.data.status == this.env.SUCCESS_STATUS) {
                        this.getCartItems();
                        this.cartFunctionsService.setCartNotificationEvent('1');
                    }
                })
            }
        });
    }    
    // This function is used to get notification counter Event
    public getCartNotification(){
        this.cartFunctionsService.getCartNotificationEvent().subscribe((data:any) =>{
			this.getCartItems()
		})
    }

    /** this function is used to close cart popup */
	public CloseCart(){
		this.showCart = false;
        this.closeCart.emit(true);
	}
    /** this function is used to show error notification */
	public showCheckOutError(){
		this.toasterNotification.showError(this.translateService.instant('CART_CHECKOUT_ERROR_MESSAGE'));
	}

    // This function is used to navigate to checkout page
    public checkout():void{
        this.CloseCart();
        this.router.navigate(["/cart"]);
    }

    // This function is used to navigate to product listing page
    public continueShopping():void{
        this.CloseCart();
        this.router.navigate(["/product"]);
    }



	/** this function is used to destroy Component */
	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
	}
}
