<!-- loading Section start -->
<div class="middle_listing_content">
<ul>
<li>
<div class="rooms_features">
  <div class="room_images">
    <figure></figure>
  </div>
  <div class="rooms_features_content">
    <h3></h3>
    <small></small>
    <p></p>
    <div class="room_garage">
      <div class="posted_time"><span> </span></div>
    </div>
  </div>
</div>
</li>
<li>
<div class="rooms_features">
  <div class="room_images">
    <figure></figure>
  </div>
  <div class="rooms_features_content">
    <h3></h3>
    <small></small>
    <p></p>
    <div class="room_garage">
      <div class="posted_time"><span> </span></div>
    </div>
  </div>
</div>
</li>
<li>
<div class="rooms_features">
  <div class="room_images">
    <figure></figure>
  </div>
  <div class="rooms_features_content">
    <h3></h3>
    <small></small>
    <p></p>
    <div class="room_garage">
      <div class="posted_time"><span> </span></div>
    </div>
  </div>
</div>
</li>
</ul>
</div>
<!-- loading Section end -->
