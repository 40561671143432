<!-- error page start      -->
<div class="thank-you-page">
  <div class="thank-you-content">
    <div class="thanks-logo">
      <a routerLink="/" href="javascript:void(0);">
        <img src="{{env.SITE_IMAGE_URL}}404.svg" alt="{{env.SITE_TITLE}}">
      </a>
    </div>
    <h2>{{ 'ERROR_404' | translate}}</h2>
    <p>{{ '404_ERROR_DESCRIPTION' | translate}}</p>
    <div class="btns">
      <a routerLink="/" class="btn  btn-success">{{ 'GO_TO_HOME_BTN' | translate}}</a>

    </div>
  </div>
</div>
<!-- error page end      -->