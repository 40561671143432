import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { share, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { EncrDecrService } from '../../../_services/encr-decr.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class SharedService {
    // Define variable
	env = environment;
	resp: any = {};
	data: any = {};

	// Define constructor
	constructor(
		private router: Router,
		private httpClient: HttpClient,
		@Inject(PLATFORM_ID) private platformId: object,
		public EncrDecr: EncrDecrService,
		public translate: TranslateService
	) { }

	// Define get request method
	public getRequest(requestUrl = ''): any {
		return this.httpClient.get(requestUrl).toPromise().then((res: any) => {
			return res;
		})
			.catch(this.handleError);
	}

	// Define post request method
	public getPost(requestUrl = '', data: any): any {
		let loginUserSlug: any = '';
		let currency = this.env.CURRENCY_CODE;
		let lang = this.env.DEFAULT_LANGUAGE;
		let method = data.method_name
		if (isPlatformBrowser(this.platformId)) {
			const currentUser = JSON.parse(localStorage.getItem('userData') || '{}');
			if (typeof currentUser !== 'undefined' && currentUser != null) {
				loginUserSlug = currentUser.slug;
			}

			const currencyCode = localStorage.getItem('currency');
			if (typeof currencyCode != 'undefined' && currencyCode != null) {
				currency = currencyCode;
			}else{
                currency = this.env.CURRENCY_CODE;
            }
			const languageCode = localStorage.getItem('lang');
			if (typeof languageCode != 'undefined' && languageCode != null) {
				lang = languageCode;
			} else {
				lang = this.env.DEFAULT_LANGUAGE;
			}
		}
		if (typeof (data.data) !== 'undefined') {
			data.data.slug = loginUserSlug;

		} else {
			data.data = {};
			data.data.slug = loginUserSlug;
		}
		data.api_type = 'web';
		data.lang = lang;
		data.currency = currency;
		data.view_country = 'IN';
		const request: any = {};
		const jsonData = JSON.stringify(data);
		const encoded = Buffer.from(jsonData, 'binary').toString('base64');
		if (this.env.ENCRIPT_API === 1) {
			const encrypted = this.EncrDecr.set(this.env.ENCRIPTION_KEY, encoded);
			request.req = encrypted;
		} else {
			request.req = encoded;
		}
		request.is_crypto = this.env.ENCRIPT_API;
		request.method = method;
		return this.httpClient.post(requestUrl, request)
			.pipe(map((res: any) => {
				let resp: any = {};
				if (this.env.ENCRIPT_API === 1) {
					const decrypted = this.EncrDecr.get(this.env.ENCRIPTION_KEY, res.response);
					resp = Buffer.from(decrypted, 'base64').toString('utf8');
				} else {
					resp = Buffer.from(res.response, 'base64').toString('utf8');
				}				
				return JSON.parse(resp);
			}));
	}

	// Define post request method
	public getPostImage(requestUrl = '', data: any): any {		
		let loginUserSlug: any = '';
        let currency = this.env.CURRENCY_CODE;
		let lang = this.env.DEFAULT_LANGUAGE;
		let method = data.method_name
		if (isPlatformBrowser(this.platformId)) {
			const currentUser = JSON.parse(localStorage.getItem('userData') || '{}');
			if (typeof currentUser !== 'undefined' && currentUser != null) {
				loginUserSlug = currentUser.slug;
			}

            const currencyCode = localStorage.getItem('currency');
			if (typeof currencyCode != 'undefined' && currencyCode != null) {
				currency = currencyCode;
			}else{
                currency = this.env.CURRENCY_CODE;
            }
			const languageCode = localStorage.getItem('lang');
			if (typeof languageCode != 'undefined' && languageCode != null) {
				lang = languageCode;
			} else {
				lang = this.env.DEFAULT_LANGUAGE;
			}
		}
		if (typeof (data.data) !== 'undefined') {
			data.data.slug = loginUserSlug;
		} else {
			data.data = {};
			data.data.slug = loginUserSlug;
		}
		data.api_type = 'web';
        data.lang = lang;
		data.currency = currency;
		data.view_country = 'IN';
		let image: any = {};
		const requestData: any = {};
		let documentImage: any = {};
		//console.log(data.data);
		requestData.data = data.data;
		requestData.method_name = data.method_name;
		//requestData.reports = data.reports;
		//console.log(requestData);
		let jsonData = JSON.stringify(requestData);
		jsonData = Buffer.from(jsonData, 'utf8').toString('binary');
		const encoded = Buffer.from(jsonData, 'binary').toString('base64');

		const formData = new FormData();
		if (this.env.ENCRIPT_API === 1) {
			const encrypted = this.EncrDecr.set(this.env.ENCRIPTION_KEY, encoded);
			formData.append('req', encrypted);
		} else {
			formData.append('req', encoded);
		}
		const is_crypt: any = this.env.ENCRIPT_API
		formData.append('is_crypto', is_crypt);
		formData.append('method', method);

		if (data.image !== undefined) {
			image = data.image;
			formData.append('image', image);
		}
		
		if(data.reports !== undefined){
			documentImage	=	data.reports;
			//console.log(documentImage);
			for(let i in documentImage){
				//console.log(documentImage[i]);
				formData.append('reports['+i+']',documentImage[i]);
			}
			delete data.reports;
		}
		//console.table(formData);
		return this.httpClient.post(requestUrl, formData)
			.pipe(map((res: any) => {
				let resp: any = {};
				if (this.env.ENCRIPT_API === 1) {
					const decrypted = this.EncrDecr.get(this.env.ENCRIPTION_KEY, res.response);
					resp = Buffer.from(decrypted, 'base64').toString('utf8');
				} else {
					resp = Buffer.from(res.response, 'base64').toString('utf8');
				}
				return JSON.parse(resp);
			}));
	}

	// This function are used for get the token on each request
	public getToken(): any {
		let token: any;
		let tokenExpireTime: any;
		if (isPlatformBrowser(this.platformId)) {
			const userToken = JSON.parse(localStorage.getItem('token') || '{}');
			if (typeof userToken !== 'undefined' && userToken != null) {
				token = userToken;
			}
			tokenExpireTime = JSON.parse(localStorage.getItem('userTokenTime') || '{}');
		}

		const curentTime = new Date().getTime();
		let isTokenExpired: boolean = false;
		if (tokenExpireTime < curentTime && token !== '' && token != null) {
			isTokenExpired = true;
		}

		if (!isTokenExpired) {
			return of(token);
		}
		return this.refreshToken();
	}

	// This function are used for refresh Token
	refreshToken(): Observable<string> {
		const url = environment.REFRESH_TOKEN_URL;
		// append refresh token if you have one
		let refreshToken: string = '';
		let expiredToken: string = '';
		if (isPlatformBrowser(this.platformId)) {
			const userToken = JSON.parse(localStorage.getItem('token') || '{}');
			if (typeof userToken !== 'undefined' && userToken != null) {
				expiredToken = userToken;
			}
			const userRefreshToken = JSON.parse(localStorage.getItem('refresh_token') || '{}');
			if (typeof userRefreshToken !== 'undefined' && userRefreshToken != null) {
				refreshToken = userRefreshToken;
			}
		}
		return this.httpClient.get(url, {
			headers: new HttpHeaders()
				.set('Authorization', `${refreshToken ? refreshToken : ''}`)
				.set('token', expiredToken)
				.set('authExempt', 'true'),
			observe: 'response'
		})
			.pipe(
				share(), // <========== YOU HAVE TO SHARE THIS OBSERVABLE TO AVOID MULTIPLE REQUEST BEING SENT SIMULTANEOUSLY
				map((res: any) => {
					const response = JSON.parse(Buffer.from(res.body.response, 'base64').toString('binary'));
					this.resp = response;
					if (this.resp.status === this.env.SUCCESS_STATUS) {
						const token = this.resp.token;
						const newRefreshToken = this.resp.refresh_token;
						const tokenExpireTime = this.resp.token_life;
						const currnow = new Date().getTime();
						const newTime = currnow + (parseInt(tokenExpireTime) * 1000);
						localStorage.setItem('userTokenTime', JSON.stringify(newTime));
						localStorage.setItem('token', JSON.stringify(token));
						localStorage.setItem('refresh_token', JSON.stringify(newRefreshToken));
						return token;
					} else {
						this.router.navigate(['/']);
					}
				})
			);
	}

	// Handler function added
	private handleError(error: Response | any): any {
		return Promise.reject(error.message || error);
	}
}
