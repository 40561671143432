<!-- user siderBar Section page start -->

<div class="dashboard_menubtn">
	<h3>{{'DASHBOARD' | translate }}</h3>
	<button class="dashboard_toggle" type="button" (click)="toggleSidebar()"><span></span> <span></span> <span></span> <span></span> </button>
</div>
<div [ngClass]="[openSidebar  ? 'account_left show' : 'account_left']">
	<button class="close_menu_btn" type="button" (click)="closeSidebar()"><img src="{{env.SITE_IMAGE_URL}}close_btn.svg" alt="img"></button>
	<div class="profile_box" *ngIf="userService.getCurrentUser().slug != null && userService.getCurrentUser().slug != 'undefined'">
        <div class="profile-upload_box">
            <figure class="profile_pic" >
                <ng-container *ngIf="userService.getCurrentUser().image">
                    <img  src="{{userService.getCurrentUser().resizable_url}}{{userService.getCurrentUser().image}}{{env.RATIO_172_X_172}}" alt="{{userService.getCurrentUser().full_name}}"  (load)="profileImageLoaded = true" [hidden]="profileImageLoaded != true">
					<img src="{{env.SITE_USER_NO_IMAGE_URL}}{{env.RATIO_172_X_172}}" alt="profile-image" [hidden]="profileImageLoaded == true">
				</ng-container>
                <img *ngIf="!userService.getCurrentUser().image" src="{{env.SITE_USER_NO_IMAGE_URL}}{{env.RATIO_172_X_172}}" alt="profile-image">
            </figure>
        </div>
        <h3>{{userService.getCurrentUser().full_name}}</h3>
    </div>
	<ul class="dashboard_menu" id="accordion">
		<li [ngClass]="[( activeTab && (activeTab == 'userprofile') )  ? 'active' : '']">
			<a routerLink="/user/profile">
				<figure><img src="{{env.SITE_IMAGE_URL}}dashboard.svg" alt="dashboard"></figure>
				{{'DASHBOARD' | translate }}
			</a>
		</li>
		<li [ngClass]="[( activeTab && (activeTab == 'addressBook') )  ? 'active' : '']">
			<a routerLink="/user/addresses">
				<figure><img src="{{env.SITE_IMAGE_URL}}address-detail2.png" alt="address"></figure>
				{{ 'ADDRESS_BOOK' | translate }}
			</a>
		</li>
		<li [ngClass]="[( activeTab && (activeTab == 'myOrder') )  ? 'active' : '']">
			<a routerLink="/user/orders">
				<figure><img src="{{env.SITE_IMAGE_URL}}shopping-bags.svg" alt="address"></figure>
				{{ 'MY_ORDERS_PAGE_TITLE' | translate }}
			</a>
		</li>
		<li [ngClass]="[( activeTab && (activeTab == 'myPlan') )  ? 'active' : '']">
			<a routerLink="/my-plan">
				<figure><img src="{{env.SITE_IMAGE_URL}}plan-icon.svg" alt="my-plan"></figure>
				{{ 'GLOBAL.MY_PLAN' | translate }}
			</a>
		</li>
		<li [ngClass]="[( activeTab && (activeTab == 'wishlist') )  ? 'active' : '']">
			<a routerLink="/user/wishlists">
				<figure><img src="{{env.SITE_IMAGE_URL}}wishlist-dashboard.svg" alt="my-plan"></figure>
				{{ 'GLOBAL.MY_WISHLIST' | translate }}
			</a>
		</li>
		<li [ngClass]="[( activeTab && (activeTab == 'notifymelist') )  ? 'active' : '']">
			<a routerLink="/user/notifyme-products">
				<figure><img src="{{env.SITE_IMAGE_URL}}notify_me.svg" alt="notify-me-list"></figure>
				{{ 'GLOBAL.NOTIFY_ME_LIST' | translate }}
			</a>
		</li>
		<li [ngClass]="[( activeTab && (activeTab == 'myBilling') )  ? 'active' : '']">
			<a routerLink="/my-billing">
				<figure><img src="{{env.SITE_IMAGE_URL}}billing.png" alt="my-billing"></figure>
				{{ 'GLOBAL.MY_BILLING' | translate }}
			</a>
		</li>
		<li [ngClass]="[( activeTab && (activeTab == 'todayAppointments') )  ? 'active' : '']">
			<a routerLink="/appointments/today">
				<figure><img src="{{env.SITE_IMAGE_URL}}appointment.png" alt="today-appointments"></figure>
				{{ 'GLOBAL.TODAY_APPOINTMENTS' | translate }}
			</a>
		</li>
		<li [ngClass]="[( activeTab && (activeTab == 'upcomingAppointments') )  ? 'active' : '']">
			<a routerLink="/appointments">
				<figure><img src="{{env.SITE_IMAGE_URL}}appointment.png" alt="upcoming-appointments"></figure>
				{{ 'GLOBAL.UPCOMING_APPOINTMENTS' | translate }}
			</a>
		</li>
		<li [ngClass]="[( activeTab && (activeTab == 'pastAppointments') )  ? 'active' : '']">
			<a routerLink="/appointments/past">
				<figure><img src="{{env.SITE_IMAGE_URL}}appointment.png" alt="past-appointments"></figure>
				{{ 'GLOBAL.PAST_APPOINTMENTS' | translate }}
			</a>
		</li>
		<li [ngClass]="[( activeTab && (activeTab == 'notifications') )  ? 'active' : '']"> <a routerLink="/notifications">
            <figure><img src="{{env.SITE_IMAGE_URL}}notification.svg" alt="img"></figure>{{ 'GLOBAL.NOTIFICATIONS' | translate }} <span class="badge" *ngIf="notificationCount > 0">{{notificationCount}}</span></a>
		</li>

		<li [ngClass]="[( activeTab && (activeTab == 'changePassword') )  ? 'active' : '']">
			<a routerLink="/user/change-password">
				<figure><img src="{{env.SITE_IMAGE_URL}}man-user.svg" alt="setting"></figure>
				{{ 'CHANGE_PASSWORD.CHANGE_PASSWORD' | translate }}
			</a>
		</li>

		<li>
			<a href="javascript:void(0);" appLogout >
				<figure><img src="{{env.SITE_IMAGE_URL}}logout.svg" alt="logout"></figure>
				{{ 'GLOBAL.LOGOUT' | translate }}
			</a>
		</li>
	</ul>
</div>

<!-- user siderBar Section page end -->
