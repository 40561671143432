import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AuthGuard } from './guards/index';
import { Error404Component } from './modules/error404/index';
import { SharedService } from './modules/shared/service/shared.service';
import { SharedModule } from './modules/shared/shared.module';
import { TokenInterceptorService } from './token-interceptor.service';
import { AuthenticationService } from './_services/authentication.service';
import { CommonFunctionsService, EncrDecrService, UserService, SeoService, CartFunctionsService, WishlistService, SocketService } from './_services/index';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { appInitializerFactory } from './translate_language';
import { Injector, APP_INITIALIZER } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";
import {VgCoreModule} from '@videogular/ngx-videogular/core';
import {VgControlsModule} from '@videogular/ngx-videogular/controls';
import {VgOverlayPlayModule} from '@videogular/ngx-videogular/overlay-play';
import {VgBufferingModule} from '@videogular/ngx-videogular/buffering';



// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient): any {
	return new TranslateHttpLoader(httpClient, environment.SITE_URL+"assets/i18n/", ".json?time=" + Date.now());
}

@NgModule({
	declarations: [
		AppComponent,
		Error404Component
	],
	imports: [
		BrowserModule.withServerTransition({ appId: 'serverApp' }),
		BrowserAnimationsModule,
		CommonModule,
		NgbModule,
		AppRoutingModule,
		HttpClientModule,
		SharedModule,
		FormsModule,
		NgSelectModule,
		VgCoreModule,
        VgControlsModule,
        VgOverlayPlayModule,
        VgBufferingModule,
		ToastrModule.forRoot({
			closeButton: true,
			enableHtml: true
		}),
		ReactiveFormsModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: HttpLoaderFactory,
				deps: [HttpClient]
			}
		}),
		AngularFireModule.initializeApp(environment.firebase),
		AngularFireAuthModule
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptorService,
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: appInitializerFactory,
			deps: [TranslateService, Injector],
			multi: true
		},
		{
			provide: 'SocialAuthServiceConfig',
			useValue: {
				autoLogin: false,
				providers: [
					{
						id: FacebookLoginProvider.PROVIDER_ID,
						provider: new FacebookLoginProvider(
							environment.FACEBOOK_ID
						)
					},
					{
						id: GoogleLoginProvider.PROVIDER_ID,
						provider: new GoogleLoginProvider(
							environment.GOOGLE_ID
						)
					}
				]
			} as SocialAuthServiceConfig,
		},
		SharedService,
		CommonFunctionsService,
		UserService,
		AuthGuard,
		SeoService,
		AuthenticationService,
		EncrDecrService,
		CartFunctionsService,
		WishlistService,
		SocketService
	],
	exports: [CommonModule],
	bootstrap: [AppComponent]
})
export class AppModule { }

