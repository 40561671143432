import { Component, OnInit,Inject,PLATFORM_ID,OnDestroy } from '@angular/core';
/*import these modules for getting data from routing url */
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import {UserService} from '../../../../_services/user.service';
import { CommonService } from '../../service/common.service';
import {  NgxToasterService} from '../../../../_services/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

/*
*Component
*/
@Component({
  selector: 'app-verification',
  template: '',
})

export class VerificationComponent implements OnInit,OnDestroy {
	private ngUnsubscribe: Subject<void> = new Subject<void>();
	/*Dclear the variable constant */
	verificationStatus			:	any		=	{};
	validateString				:	string 	=	''; 
    /*
	*constructor
	*/
	constructor(
		private route: ActivatedRoute,
		public router: Router,
		private CommonService : CommonService,
		public toasterNotification		: NgxToasterService,
		@Inject(PLATFORM_ID) private platformId: Object
	) { }

  	ngOnInit() {
		if(isPlatformBrowser(this.platformId)) { 
			/*********** This is used for validate the login form ************/	
			this.verifyEmail();
		}
  	}
	
	/**This function are used for destroy the data**/
    ngOnDestroy(){
	 this.ngUnsubscribe.next();
    }
	
	/**function for submit verify email*/
	verifyEmail(){
		
		this.validateString = this.route.snapshot.params['validatestring'];
		this.CommonService.verifyAccount(this.validateString).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
			if(response.data.status == 'success'){
				this.router.navigate(['/']);
				this.toasterNotification.showSuccess(response.data.message);
			}else{
				this.toasterNotification.showError(response.data.message);
			}
			
		});
	}
}
