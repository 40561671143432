// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// const API_BASE_URL	= 'http://telenutrition.dev2.gipl.inet/api/index';
// const FRONT_URL	    = 'http://telenutrition.dev2.gipl.inet:11088';
// const SOCKET_URL	    = 'http://telenutrition.dev2.gipl.inet:3332';
//const API_BASE_URL	= 'http://127.0.0.1:8000/api/index';
//const FRONT_URL	    = 'https://127.0.0.1:11088';
//const FRONT_URL	    = 'http://localhost:4200';
//const SOCKET_URL	= 'http://localhost:3332';
//const FRONT_URL	    = 'https://localhost:11088';
const API_BASE_URL	= 'https://admin-dev.telenutrition.com/api/index';
const FRONT_URL	    = 'https://dev.telenutrition.com';
const SOCKET_URL	= 'https://chat-dev.telenutrition.com';


export const environment = {
  production: true,
  ENCRIPT_API: 0,
  /*agora: {
        appId: '0baa35df32994e558c3a5c6049e6f7de'
    },*/
  //OTOK_API_KEY: "47410231",
  OTOK_API_KEY: "47458191",
  ENCRIPTION_KEY: "123456$#@$^@1ERF123456$#@$^@1ERF",
  ENCRIPTION_IV: "123456$#@$^@1ERF",
  BLOCK_SLUG: "footer-about",
  //FACEBOOK_ID: "563848341424539",
  FACEBOOK_ID: "290143893287775",		
  //GOOGLE_ID:"583511189559-e7dbvog1416am24bgdq6j1q0mriagbk0.apps.googleusercontent.com",
  //GOOGLE_ID:"292072592597-v72m958hrl77rpqcut3lnk2ma9puvfjg.apps.googleusercontent.com", 
  //GOOGLE_ID:"3111522148-bbr0dlv2pd35as0dm4vl74hs9gpq6e1f.apps.googleusercontent.com",
  GOOGLE_ID:"683019524195-ml4ntfeavj53p77aetd8kibjj3qnrn12.apps.googleusercontent.com",
  //RECAPTCHA_SITE_KEY: "6LdsHMQaAAAAAHs6pwBb_GVdsM4GPR3sLw30jId3",
  RECAPTCHA_SITE_KEY: "6LcYr-MgAAAAAKft2uUT8grbzm29R4tGaVmXkEuP",
  API_URL: API_BASE_URL,
  REFRESH_TOKEN_URL: API_BASE_URL + "regenerate_jwt",
  SITE_URL: FRONT_URL + "/",
  SITE_URL_WITHOUT_SLASH: FRONT_URL,
  NODE_URL: SOCKET_URL,
  TIME_ZONE: "UTC+3",
  TIME_ZONE1: "UTC+5.30",
  SITE_TITLE: "Telenutrition",
  SITE_DESCRIPTION: "Telenutrition",
  SITE_KEYWORDS: "Telenutrition",
  SUCCESS_STATUS: "success",
  ERROR_STATUS: "error",
  STATUS_ERROR_FORM_EMAIL_EXISTS: "email_exists_error",
  OTP_STATUS_ERROR_VALIDATION: "otp_validation_error",
  SITE_IMAGE_URL: "/assets/images/",
  SITE_USER_NO_IMAGE_URL:
    "https://admin-dev.telenutrition.com/image.php?image=https://admin-dev.telenutrition.com/images/user-no-image.jpg",
  SITE_NO_IMAGE_URL:
    "https://admin-dev.telenutrition.com/image.php?image=https://admin-dev.telenutrition.com/images/no-image.jpg",
  MAX_FILE_UPLOAD_LIMIT						: 5,
	MAX_IMAGE_SIZE_IN_MB						: 10,
	IMAGE_ALLOWED_EXTENSIONS    				: ['jpeg', 'jpg', 'png'],
	IMAGE_ALLOWED_EXTENSIONS_ARRAY				: ['jpeg', 'jpg', 'png'],
	FILE_ALLOWED_EXTENSIONS_ARRAY				: ['jpeg', 'jpg', 'png', 'pdf'],
	FILE_ALLOWED_EXTENSIONS						: 'jpeg, jpg, png, pdf',
	DISPLAY_DATE_FORMATE						: 'dd MMM, yyyy',
	DISPLAY_DATE_FORMATE1						: 'dd MMMM yyyy',
	DISPLAY_DATE_TIME_FORMATE					: 'EEEE LLL dd, yyyy',
	CURRENCY_CODE								: 'BHD',
	DEFAULT_LANGUAGE							: 'en',
	NO_RECORD_FOUND								: 'No Record Found.',
    LANGUAGES_ARRAY								: ['en','ar'],
	LANGUAGES_DIRECTIONS						: {en: 'ltr', ar: 'rtl'},
	PER_PAGE									: 10,
	TOTAL_PAGE_TO_SHOW              			: 5,
	DATEPICKER_DATE_FORMATE						: 'DD-MM-YYYY',
	DATE_FORMATE								: 'dd MMM',
	DATEPICKER_DISPLAY_DATE_FORMATE				: 'dd-MM-YYYY',
	DATEPICKER_SEND_DATE_FORMATE				: 'YYYY-MM-dd',
	DEFAULT_COUNTRY_DIAL_CODE					: '+973-BH',
	DEFAULT_DIAL_CODE							: '+973',
	TOOLTIP_OPTIONS								: {'placement': 'left','show-delay': 500,'zIndex': 1099 },
	PASWORD_MIN_LENGTH							: 8,
	PASWORD_MAX_LENGTH							: 100,
	MOBILE_MIN_LENGTH							: 7,
	MOBILE_MAX_LENGTH							: 15,
	PRODUCT_TEXT_LENGTH							: 50,
	RESEND_MAIL_TIME_IN_SECOND					: 30,
	COMMENT_MAX_LENGTH							: 300,
	LOGIN_BY_OPTIONS                			: ['mobile','email'],
	FORGOT_BY_OPTIONS               			: ['mobile','email'],
	TERMS_AND_CONDITION_PAGE_SLUG   			: 'terms-conditions',
	PRIVACY_POLICY_PAGE_SLUG    				: 'privacy-policy',
	ABOUT_US_PAGE_SLUG							: 'about-us',
	OUR_STORY_PAGE_SLUG							: 'our-story',
	CUSTOMER_SUPPORT_PAGE_SLUG					: 'customer-support',
    CONTACT_REASON_DROPDOWN_TYPE				: 'contact_reason',
    CONTACT_US_BLOCK_SLUG        				: 'contact-left',
    RATIO_172_X_172                				: '&cropratio=1:1&zc=2&height=172px&width=172px',
	RATIO_100_X_100                				: '&cropratio=1:1&zc=2&height=100px&width=100px',
	RATIO_90_X_90                				: '&cropratio=1:1&zc=2&height=90px&width=90px',
    RATIO_26_X_26                				: '&cropratio=1:1&zc=2&height=26px&width=26px',
    RATIO_191_X_294                				: '&cropratio=191:294&zc=2&height=294px&width=191px',
    RATIO_129_X_129                				: '&cropratio=129:129&zc=2&height=129px&width=129px',
    RATIO_875_X_288                				: '&cropratio=3:1&zc=2&height=288px&width=875px',
    RATIO_59_X_59                				: '&cropratio=1:1&zc=2&height=59px&width=59px',
    RATIO_228_X_228                				: '&cropratio=1:1&zc=2&height=228px&width=228px',
	RATIO_175_X_175                				: '&cropratio=1:1&zc=2&height=175px&width=175px',
	RATIO_570_X_570                				: '&cropratio=0:0&zc=0&height=500px&width=500x',
	RATIO_202_X_202                				: '&cropratio=1:1&zc=2&height=202px&width=202px',
	RATIO_75_X_75                				: '&cropratio=1:1&zc=2&height=75px&width=75px',
	RATIO_175_X_191                				: '&cropratio=1:1&zc=2&height=175px&width=191px',
	RATIO_197_X_194                				: '&cropratio=1:1&zc=2&height=197px&width=194px',
	RATIO_46_X_46                				: '&cropratio=1:1&zc=2&height=46px&width=46px',
	RATIO_296_X_181                				: '&cropratio=1:1&zc=2&height=181px&width=296px',
    ARCHIVE_LAST_YEAR               			: 2000,
    FACEBOOK_SHARE_BASE_URL						: 'https://www.facebook.com/sharer/sharer.php?u=',
	TWITTER_SHARE_BASE_URL						: 'https://twitter.com/intent/tweet?url=',
	LINKEDIN_SHARE_BASE_URL						: 'https://www.linkedin.com/shareArticle?mini=true&url=',
    GENDER_ARRAY                    			: {'1': 'male','2': 'female'},
	AD_SORT_BY_OPTIONS							: [{ key: 'most_popular', name: "Most Popular" }, { key: 'price_high_to_low', name: "Price High to Low" }, { key: 'price_low_to_high', name: "Price Low to High" } , { key: 'oldest_on_top', name: "Oldest on Top" },{ key: 'newest_on_top', name: "Newest on Top" }],
	PLAN_ARRAY									: {0: "PLANS.PLAN_TYPE_MONTHLY", 1: 'PLANS.PLAN_TYPE_YEARLY',2: 'PLANS.PLAN_TYPE_QUARTERLY'},
	PLAN_VALIDITY_ARRAY							: {0: "PLANS.PER_MONTH", 1: 'PLANS.PER_YEAR',2: 'PLANS.PER_QUARTERLY'},
	PLAN_DURATION_TYPE_ARRAY					: {0: "PLANS.MONTH",1: 'PLANS.YEAR' ,2: 'PLANS.QUARTERLY'},
	ORDER_STATUS								: {0:'order_created', 1:'payment_received', 2:'shipped', 3:'delivered', 4:'cancel_request', 5: 'cancelled'},
	APPOINTMENT_STATUS							: {1:'APPOINTMENT_STATUS_CONFIRM', 2:'APPOINTMENT_STATUS_TEMORARY', 3:'APPOINTMENT_STATUS_TREATED', 4:'APPOINTMENT_STATUS_CANCEL'},
    SHIPPED_ORDER								: 2,
	HOME_CONSULT_WITH_US_BLOCK_SLUG     		: 'home-counsult-with-us',
	PAYMENT_FOR_SUBSCRIPTION					: 0,
	PAYMENT_FOR_MEMBERSHIP						: 1,
	PAYMENT_FOR_ORDER							: 2,
	PAYMENT_STATUS_PENDING						: 0,
	PAYMENT_STATUS_SUCCESS						: 1,
	PAYMENT_STATUS_FAIL							: 2,
	DIET_PLAN_INACTIVE							: 0,
	DIET_PLAN_ACTIVE							: 1,
	NOTIFICATION_ORDER_PLACED								: 'order_placed',
	NOTIFICATION_ORDER_SHIPPED								: 'order_shipped',
	NOTIFICATION_ORDER_REFUND_INITIATED						: 'order_refund_initiated',
	NOTIFICATION_PLAN_EXPIRATION							: 'user_purchase_plan_expiration',
	NOTIFICATION_HOURLY_APPOINTMENT_REMINDER				: 'patient_hourly_appointment_reminder',
	NOTIFICATION_DAILY_APPOINTMENT_REMINDER					: 'patient_daily_appointment_reminder',
	NOTIFICATION_APPOINTMENT_CALL_START						: 'appointment_video_call_start_send_to_patient',
	NOTIFICATION_APPOINTMENT_BOOK_SEND_TO_PATIENT			: 'appointment_book_send_to_patient',
	NOTIFICATION_APPOINTMENT_FOLLOWUP_BOOKED_SEND_TO_PATIENT: 'appointment_followup_booked_send_to_patient',
	ACTIVE 						                : 1,
	DEACTIVE 						            : 0,
	APPOINTMENT_STATUS_CONFIRM					: 1,
	APPOINTMENT_STATUS_TEMORARY					: 2,
	APPOINTMENT_STATUS_TREATED					: 3,
	APPOINTMENT_STATUS_CANCEL					: 4,
	USER_ONLINE_STATUS                    		: {'2': 'offline','1': 'online'},
	CREATE_ORDER								: 0,
	PLAN_PURCHASE_STATUS_ARRAY					: {0: 'PLANS.PENDING', 1:'PLANS.ACTIVE',2:'PLANS.EXPIRED'},
	PET_NUTRITION_FILTER                    	: {'1': 'for_pet'},
	CC_AVENUE_ACCESS_CODE                       : 'AVJM03IJ90BY79MJYB',
	CC_AVENUE_ENCRYPTION_KEY                    : '712E7664FAF44198AC0D8878CC3A35FA',
	CC_AVENUE_URL                               : 'https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction',
	MINIMUM_AGE_RESTRICTION						: 18,
	HOME_PROTEIN_BANNER_BLOCK_SLUG     		    : 'home-protein-banner',
	DIETICIAN_TYPE_NORMAL 		                : 'normal',
	DIETICIAN_TYPE_SUPPLEMENTS                  : 'supplements',
	DURATION_30                                 : '30 Min',
	DURATION_15                                 : '15 Min',
	CART_TYPE_PRODUCT						    : 'product',
	CART_TYPE_PLAN							    : 'plan',
	ASF_PAYMENT_INTERACTION_NAME                : 'TEST100070995',
	ASF_PAYMENT_ADDRESS_LINE_1                  : '200 Sample St',
	ASF_PAYMENT_ADDRESS_LINE_2                  : '1234 Example Town',

	// Firebase
	firebase: {
		apiKey: "AIzaSyDc03KeVix-SxQsiStluT75p2y95khmmNU",
		authDomain: "telenutrition-web.firebaseapp.com",
		projectId: "telenutrition-web",
		storageBucket: "telenutrition-web.appspot.com",
		messagingSenderId: "683019524195",
		appId: "1:683019524195:web:aca9fd48db4dc1070cc056",
		measurementId: "G-WYT3Z9KE5S"
  	}
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
