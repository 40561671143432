import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
/**
* Component
*/
@Component({
	selector: 'app-norecordfound',
	templateUrl: './norecordfound.component.html',
	styleUrls : ['./norecordfound.component.css']
})
export class NoRecordFoundComponent implements OnInit {

	@Input() norecord: any = '';
	@Input() type: any = 'message';
	/**
	* constructor
	*/
	constructor(public router: Router) { }
	/**
	*This function is used to  On Init
	*/
	ngOnInit() {
	}
}
