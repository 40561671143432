import { Directive, ElementRef, HostListener, Input ,Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { CartFunctionsService } from '../_services/cartfunctions.service';
import { NgxToasterService } from '../_services/toastr.service';
import { CommonFunctionsService } from '../_services/commonFunctions.service';
import { environment } from '../../environments/environment';
import { takeUntil } from 'rxjs/operators';

@Directive({
    selector: '[appAddPlanToCart]'
})

export class AddPlanToCartDirective {
    env = environment;
    @Input('plan') plan: any;
    
   


    @HostListener('click') onClick() {
        this.addPlanToCart();
    }


    constructor(
        private el: ElementRef,
        public router: Router,
        public userService: UserService,
        public cartService: CartFunctionsService,
        public toasterNotification:NgxToasterService,
        public commonFunctionsService:CommonFunctionsService,
        private renderer: Renderer2,
    ) {

    }

    /**
	 * CartService:addToCart()
	 * @Description Function to add product in cart.

	 * */

    addPlanToCart(){
        let userData   =   this.userService.getCurrentUser();
        if(Object.keys(userData).length	==	0){
            this.router.navigate(['/login'], { queryParams: { returnUrl: "/subscription-plans" }});
        }else{
            this.commonFunctionsService.blockUI();
            let formData	: any = {plan_id: this.plan._id, item_type: this.env.CART_TYPE_PLAN};
            this.cartService.addPlanToCart(formData).subscribe((res: any) => {
                if(res.data.status == this.env.SUCCESS_STATUS){
                    this.commonFunctionsService.unblockUI();
                    this.toasterNotification.showSuccess(res.data.message);
                    this.plan.is_in_cart =   1;
                    this.cartService.setCartNotificationEvent('1');
                    //this.cartService.setAddToCartNotificationEvent('1');
                    /*if(this.quickBuy == 1){
                        this.router.navigate(['/checkout'],{ queryParams: {quick_buy:1}});
                    }*/
                    
                    //$("#add_to_cart").trigger('click');
                }else{
                    this.toasterNotification.showError(res.data.message);
                }
            });
        }
    }


    
    

}
