import { Component  } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';

/** Component */
@Component({
	selector: 'app-signupmodal',
	templateUrl: './signupmodal.component.html',
	styleUrls: ['./signupmodal.component.css']
})
export class SignupModalComponent {
	/** Define Variables */
	tabIndex             : any     = "";
	constructor(private modalService: BsModalService ) {}
    
	/** this function is used for close sign modal box */
	public closeModal(): void {
		this.modalService.hide();
		document.body.classList.remove('modal-open');
	}

	
}
