import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SharedService } from "../modules/shared/service/shared.service";
import { environment } from '../../environments/environment';
import { Observable, of, Observer, Subject, ReplaySubject } from 'rxjs';
@Injectable()
export class WishlistService {

    env     =   environment;
    data    :	any		=	{};
    private wishlistNotification = new Subject<any>();
    constructor (
        private SharedService : SharedService,
        private http: HttpClient,
    ){}


    /** Function to add document to wishlist
	 * @param inputData: array
	 */
	addProductToWishlist(inputData: any) {
        this.data['method_name']    = "addToWishList";
        this.data['data']           = inputData;
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }
  

	/** Function to get item count of wishlist
	 * */
	getWishlistItemCount() {
	    this.data['method_name']    = "getWishlistCount";
	    this.data['data']           = {}
	    return this.SharedService.getPost(this.env.API_URL , this.data);
	}


  	/** Function to get item count of wishlist
	 * @param formData: any for formData
	 */
	getWishlistItems(formData : any) {
        this.data['method_name']    = "listingWishList";
        this.data['data']           = formData
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }
  
   	/** Function to get item count of wishlist
	 * @param slug: any for slug
	 */
	removeWishlistItem(inputData: any) {
        this.data['method_name']    = "removeWishListProduct";
        this.data['data']           = inputData;
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }
   	/** Function to move products to cart
	 * @param formData: any
	 */
	moveToCart(formData: any) {
        this.data['method_name']    = "moveToCart";
        this.data['data']           = formData;
        return this.SharedService.getPost(this.env.API_URL , this.data);
    }
   	
      // This function is used to set cart Notification event
    setWishlistNotificationEvent(message: any) {
		this.wishlistNotification.next(message);
	}

    // This function is used to get cart Notification event
	getWishlistNotificationEvent(): Observable<any> {
		return this.wishlistNotification.asObservable();
	}

}
