import { Component, Renderer2 } from '@angular/core';
import { environment } from '../environments/environment';
import { CommonFunctionsService} from './_services/index';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
/**Define Component **/
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent {
	/**Define Varaible **/
	urlLang: any = '';
    env	   =	environment;
    /**Define constructor **/
    constructor(
        private route: ActivatedRoute,
        public router: Router,
		public commonFunctionsService  : CommonFunctionsService,
		public translateService: TranslateService,
        private renderer: Renderer2,

	) {
        this.translateService.addLangs(this.env.LANGUAGES_ARRAY);
        this.setLanguage();
        this.route.queryParams.subscribe(params => {
			const paramsSub: any = params;
			if (typeof (paramsSub.lang) !== 'undefined' && paramsSub.lang !== '' && this.env.LANGUAGES_ARRAY.indexOf(paramsSub.lang) != -1) {
				this.urlLang = paramsSub.lang;
                this.setLanguage();
			}

		});
    }

    /**This function are used for when activte the url then move window.*/
	onActivate(e: any, outlet: any){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser)	=>	{
			if (isBrowser){
                window.scrollTo(0, 0);
            }
		})
	}

    // This function is used to set language
	public setLanguage(): void{
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if (isBrowser){
				const lang: any | null | undefined	=	this.commonFunctionsService.getItem('lang');

                const languageDirections: any	=	this.env.LANGUAGES_DIRECTIONS
				if (this.urlLang !== ''){
					const lang 		=	this.urlLang;
					this.translateService.setDefaultLang(lang);
					this.translateService.use(lang);
					if (languageDirections[lang] === 'ltr'){
						this.renderer.setAttribute(document.body, "dir", "ltr")
						this.renderer.removeClass(document.body, 'rtl');
					} else {
						this.renderer.setAttribute(document.body, "dir", "rtl")
						this.renderer.addClass(document.body, 'rtl');
					}
				} else {
					if (lang != undefined){
						this.translateService.setDefaultLang(lang);
						this.translateService.use(lang);
						if (languageDirections[lang] === 'ltr'){
							this.renderer.setAttribute(document.body, "dir", "ltr")
							this.renderer.removeClass(document.body, 'rtl');
						} else {
							this.renderer.setAttribute(document.body, "dir", "rtl")
							this.renderer.addClass(document.body, 'rtl');
						}
					} else {
                        this.commonFunctionsService.setItem('lang',this.env.DEFAULT_LANGUAGE);
						this.translateService.setDefaultLang(this.env.DEFAULT_LANGUAGE);
						this.translateService.use(this.env.DEFAULT_LANGUAGE);
						if (languageDirections[this.env.DEFAULT_LANGUAGE] == 'ltr'){
							this.renderer.setAttribute(document.body, "dir", "ltr")
							this.renderer.removeClass(document.body, 'rtl');
						} else {
							this.renderer.setAttribute(document.body, "dir", "rtl")
							this.renderer.addClass(document.body, 'rtl');
						}
					}
				}
            }
		});
	}
}
