import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
    HeaderComponent,
    FooterComponent,
    CartComponent,
    SignupComponent,
    SignupModalComponent,
    LoginComponent,
    BreadcrumbComponent,
    UserleftComponent,
    LoadingComponent,
    NoRecordFoundComponent, 
    ProductViewComponent, 
    NutritionistViewComponent,
    PaginationComponent,
    TestmonialComponent,
    NewsletterComponent,
    VerificationComponent
} from './layout/index';
import { ControlMessagesComponent } from '../../control-message.component';
import { CommonService } from './service/common.service';
import { TranslateModule } from '@ngx-translate/core';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { DisplayDatePipe, DisplayPricePipe, KeysPipe,TimeAgoPipe } from '../../_pipes/index';
import { AddToCartDirective } from '../../directive/addtocart.directive';
import { AddPlanToCartDirective } from '../../directive/addplantocart.directive';
import { MatchHeightDirective } from '../../directive/match-height.directive';
import { LoginDirective } from '../../directive/login.directive';
import { ShowHidePasswordDirective } from '../../directive/showhide-password.directive';
import { LogoutDirective } from '../../directive/logout.directive';
import { RouterModule } from '@angular/router';
import { ModalModule, BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { LaddaModule } from 'angular7-ladda';
import { NgxCaptchaModule } from 'ngx-captcha';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { TooltipModule } from 'ng2-tooltip-directive';
import { AddToWishlistDirective } from '../../directive/addtowishlist.directive';
import { AddToNotifyMeDirective } from '../../directive/addtonotifyme.directive';
import { RemoveFromNotifyMeDirective } from '../../directive/removefromnotifyme.directive';
import { RemoveFromWishlistDirective } from '../../directive/removefromwishlist.directive';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MalihuScrollbarModule } from 'ngx-malihu-scrollbar';
import { RatingModule } from 'ng-starrating';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';

/***********layout routing ************/
 const sharedRouting = RouterModule.forChild([
   {
    path: 'account-verification/:validatestring',
    component: VerificationComponent
   }
 ]);


@NgModule({
	declarations: [
        BreadcrumbComponent,
        HeaderComponent,
        FooterComponent,
        SignupComponent,
        SignupModalComponent,
        CartComponent,
        LoginComponent,
        ControlMessagesComponent,
        MatchHeightDirective,
        LogoutDirective,
        ShowHidePasswordDirective,
        AddToCartDirective,
        AddPlanToCartDirective,
        UserleftComponent,
        LoadingComponent,
        NoRecordFoundComponent,
        DisplayDatePipe,
		DisplayPricePipe,
        KeysPipe,
		ProductViewComponent,
		PaginationComponent,
        LoginDirective,
        NutritionistViewComponent,
        AddToWishlistDirective,
        AddToNotifyMeDirective,
        RemoveFromNotifyMeDirective,
        RemoveFromWishlistDirective,
        TimeAgoPipe,
        TestmonialComponent,
        NewsletterComponent,
        VerificationComponent
    ],
	imports: [
		CommonModule,
		TranslateModule,
		ModalModule.forRoot(),
		LaddaModule.forRoot({
			style	:	"expand-right",
			spinnerColor	:	"OrangeRed"
		}),
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
        NgxCaptchaModule,
        RatingModule,
		SlickCarouselModule,
		NgxPaginationModule,
        BsDatepickerModule.forRoot(),
        NgSelectModule,
        TooltipModule,
        CarouselModule,
        sharedRouting,
        AutocompleteLibModule
	],
	providers: [CommonService, BsModalRef, BsModalService],
	exports: [
        CommonModule,
		HeaderComponent,
		FooterComponent,
		ControlMessagesComponent,
		TranslateModule,
		ModalModule,
        FormsModule,
		ReactiveFormsModule,
        LaddaModule,
        SignupComponent,
        NgxCaptchaModule,
        RatingModule,
		LogoutDirective,
		ShowHidePasswordDirective,
        AddToCartDirective,
        AddPlanToCartDirective,
		MatchHeightDirective,
        LoginDirective,
		BreadcrumbComponent,
        NgSelectModule,
        TooltipModule,
        UserleftComponent,
        LoadingComponent,
        NoRecordFoundComponent,
        DisplayDatePipe,
		DisplayPricePipe,
        KeysPipe,
		ProductViewComponent,
		PaginationComponent,
        NutritionistViewComponent,
        AddToWishlistDirective,
        AddToNotifyMeDirective,
        RemoveFromNotifyMeDirective,
        RemoveFromWishlistDirective,
        TimeAgoPipe,
        CarouselModule,
        TestmonialComponent,
        NewsletterComponent,
        AutocompleteLibModule
	]
})
export class SharedModule { }
