import { Component, OnInit } from '@angular/core';
import {TranslateService } from '@ngx-translate/core';
/**
 * Component
*/
@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls : ['./loading.component.css'],
})
export class LoadingComponent implements OnInit {
  constructor(public translate: TranslateService) {}
/*
 This function is used to On Init
*/
  ngOnInit() {
    
  }
}