<!-- signup form section start -->
<div class="login-signup-content">
	<ul class="nav nav-tabs" id="myTab9" role="tablist">
		<li class="nav-item">
			<a class="nav-link" [ngClass]="[tabIndex ==='0'  ? 'active' : '']" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">{{ 'GLOBAL.LOGIN'| translate }}</a>
		</li>
		<li class="nav-item">
			<a class="nav-link" [ngClass]="[tabIndex ==='1'  ? 'active' : '']" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">{{ 'GLOBAL.SIGNUP'| translate }}</a>
		</li>
	</ul>
	<div class="tab-content">
		<div class="tab-pane" [ngClass]="[tabIndex ==='0'  ? 'active' : '']" id="home" role="tabpanel" aria-labelledby="home-tab">
			<app-login></app-login>
		</div>
		<div class="tab-pane" [ngClass]="[tabIndex ==='1'  ? 'active' : '']" id="profile" role="tabpanel" aria-labelledby="profile-tab">
			<div class="signup-wrap">
				<h2>{{'SIGNUP.GET_STARTED' | translate }}</h2>
				<p class="subtext">{{'SIGNUP.CREATE_ACCOUNT_NOW' | translate }}</p>
				<div class="with-scocial">
					<div>
						<a href="javascript:void(0);" class="facebook-btn" (click)="signupWithFacebook()">
						<img src="{{env.SITE_IMAGE_URL}}facebook.svg" alt="{{ 'SIGNUP.SIGNUP_WITH_FB'| translate }}">
						<span>{{ 'SIGNUP.SIGNUP_WITH_FB'| translate }}</span>
						</a>
					</div>
					<div>
						<a href="javascript:void(0);" class="google-btn"  (click)="signupWithGoogle()">
						<img src="{{env.SITE_IMAGE_URL}}google.svg" alt="Login with google">
						<span>{{ 'SIGNUP.SIGNUP_WITH_GOOGLE'| translate }}</span>
						</a>
					</div>
				</div><br/>
				<form [formGroup]="signupForm">
					<div class="row">
						<div class="col">
							<div class="form-group">
								<div class="form-input">
									<input type="text" placeholder="{{'FORM.FIRST_NAME' | translate | titlecase }}" id="first_name" name="first_name" class="form-control"  formControlName="first_name">
									<label for="first_name">{{'FORM.FIRST_NAME' | translate | titlecase }} <span>*</span></label>
								</div>
								<control-messages *ngIf="!signupFormError.first_name"  [name]="'FORM.FIRST_NAME'  | translate" [control]="signupForm.controls.first_name"></control-messages>
								<div  *ngIf="signupFormError.first_name != ''"  class="help-block">{{signupFormError.first_name}}</div>
							</div>
						</div>
						<div class="col">
							<div class="form-group">
								<div class="form-input">
									<input type="text" placeholder="{{ 'FORM.LAST_NAME'| translate | titlecase }}" id="last_name" name="last_name" class="form-control" formControlName="last_name">
									<label for="last_name">{{ 'FORM.LAST_NAME'| translate | titlecase }} <span>*</span></label>
								</div>
								<control-messages *ngIf="!signupFormError.last_name"  [name]="'FORM.LAST_NAME'  | translate" [control]="signupForm.controls.last_name"></control-messages>
								<div  *ngIf="signupFormError.last_name != ''"  class="help-block">{{signupFormError.last_name}}</div>
							</div>
						</div>
					</div>
					
					<div class="form-group">
						<div class="form-input">
							<input type="email" placeholder="{{ 'FORM.EMAIL'| translate | titlecase }}" id="Email" name="Email" class="form-control"  formControlName="email">
							<label for="email">{{ 'FORM.EMAIL'| translate | titlecase }} <span>*</span></label>
						</div>
						<control-messages *ngIf="!signupFormError.email"  [name]="'FORM.EMAIL'  | translate" [control]="signupForm.controls.email"></control-messages>
						<div  *ngIf="signupFormError.email != ''"  class="help-block">{{signupFormError.email}}</div>
					</div>
					<div class="form-group">
						<div class="phone-with-code">
							<ng-select labelForId="signup_dial_code"  appearance="outline"  formControlName="dial_code" appendTo="body" [clearable]="false" (keyup.esc)="$event.preventDefault()" [virtualScroll]="true">
								<ng-option  value="">{{ 'FORM.DIAL_CODE'| translate | titlecase }}</ng-option>
								<ng-option [value]="item.country_code+'-'+item.country_iso_code"  [disabled]="false" *ngFor="let item of contryDialCodeList">
									{{item.country_code+'-'+item.country_iso_code}}
								</ng-option>
							</ng-select>
							<div class="form-input">
								<input type="text" placeholder="{{ 'FORM.PHONE_NUMBER' | translate | titlecase }}" id="Phone_number2" name="Phone_number2" class="form-control"  formControlName="phone">
								<label for="Phone_number2">{{ 'FORM.PHONE_NUMBER' | translate | titlecase }} <span>*</span> <span class="cstm-tooltip"><img src="{{env.SITE_IMAGE_URL}}tooltip-icon.png" alt="image" [tooltip]="'GLOBAL.MOBILE_TOOLTIP_TEXT' | translate: {min: env.MOBILE_MIN_LENGTH, max:env.MOBILE_MAX_LENGTH}" [options]="env.TOOLTIP_OPTIONS" placement="top"></span></label>
							</div>
						</div>
						<control-messages *ngIf="!signupFormError.dial_code"  [name]="'FORM.DIAL_CODE'  | translate" [control]="signupForm.controls.dial_code"></control-messages>
						<control-messages *ngIf="!signupFormError.phone"  [name]="'FORM.PHONE_NUMBER'  | translate" [control]="signupForm.controls.phone"></control-messages>
						<div  *ngIf="signupFormError.dial_code != ''"  class="help-block">{{signupFormError.dial_code}}</div>
						<div  *ngIf="signupFormError.phone != ''"  class="help-block">{{signupFormError.phone}}</div>
					</div>
					<div class="form-group">
						<div class="form-input">
							<input type="text" placeholder="{{ 'FORM.DATE_OF_BIRTH'| translate | titlecase }}" id="date_of_birth" name="date_of_birth" class="form-control"  formControlName="date_of_birth" bsDatepicker  [bsConfig]="datePickerConfig">
							<label for="date_of_birth">{{ 'FORM.DATE_OF_BIRTH'| translate | titlecase }} <span>*</span></label>
						</div>
						<control-messages *ngIf="!signupFormError.date_of_birth"  [name]="'FORM.DATE_OF_BIRTH'  | translate" [control]="signupForm.controls.date_of_birth"></control-messages>
						<div  *ngIf="signupFormError.date_of_birth != ''"  class="help-block">{{signupFormError.date_of_birth}}</div>
					</div>
					<div class="row">
						<div class="col">
							<div class="form-group passwordInput">
								<div class="form-input">
									<input data-toggle="tooltip"  type="password" placeholder="{{ 'FORM.PASSWORD'| translate | titlecase }}" id="password_two" name="password_two" class="form-control"  formControlName="password" (keyup)="changeSignupPassword()">
									<label for="password_two">{{ 'FORM.PASSWORD'| translate | titlecase }} <span>*</span> <span class="cstm-tooltip"><img src="{{env.SITE_IMAGE_URL}}tooltip-icon.png" alt="image" [tooltip]="'GLOBAL.PASSWORD_TOOLTIP_TEXT' | translate: {min: env.PASWORD_MIN_LENGTH}" [options]="env.TOOLTIP_OPTIONS" placement="top"></span></label>
								</div>
								<a class ="password" ShowHidePasswordDirective href="javascript:void(0);"><img src="{{env.SITE_IMAGE_URL}}eye.svg" alt="Password"></a>
								<control-messages *ngIf="!signupFormError.password"  [name]="'FORM.PASSWORD'  | translate" [control]="signupForm.controls.password"></control-messages>
								<div  *ngIf="signupFormError.password != ''"  class="help-block">{{signupFormError.password}}</div>
							</div>
						</div>
						<div class="col">
							<div class="form-group passwordInput">
								<div class="form-input">
									<input type="password" placeholder="{{ 'FORM.CONFIRM_PASSWORD'| translate | titlecase }}" id="confirmpassword" name="confirmpassword" class="form-control"  formControlName="confirm_password" (keyup)="changecPassword()">
									<label for="confirmpassword">{{ 'FORM.CONFIRM_PASSWORD'| translate | titlecase }} <span>*</span></label>
								</div>
								<a class="password" ShowHidePasswordDirective href="javascript:void(0);"><img src="{{env.SITE_IMAGE_URL}}eye.svg" alt="Password"></a>
								<control-messages *ngIf="!signupFormError.confirm_password"  [name]="'FORM.CONFIRM_PASSWORD'  | translate" [control]="signupForm.controls.confirm_password"></control-messages>
								<div  *ngIf="signupFormError.confirm_password != ''"  class="help-block">{{signupFormError.confirm_password}}</div>
							</div>
						</div>
					</div>	
					<div class="checkwrap">
						<label>{{ 'SIGNUP.BY_SIGNUP_I_AGREE'| translate }} <a routerLink="/pages/{{env.TERMS_AND_CONDITION_PAGE_SLUG}}" target="_blank">{{ 'GLOBAL.TERMS_AND_CONDITIONS'| translate }}</a>.</label>
					</div>
					<!-- <div class="captcha" *ngIf="isBrowser">
						<ngx-recaptcha2 #captchaElem
						[siteKey]="env.RECAPTCHA_SITE_KEY"
						(expire)="handleExpire()"
						[useGlobalDomain]="false"
						formControlName="recaptcha">
						</ngx-recaptcha2>
						<control-messages *ngIf="!signupFormError.recaptcha" [name]="'FORM.RECAPTCHA'| translate"  name="Recaptcha" [control]="signupForm.controls.recaptcha"></control-messages>
						<div *ngIf="signupFormError.recaptcha!=''" class="error-block-form">{{ signupFormError.recaptcha }}</div>
					</div> -->
					<button type="submit" [ladda]="progress" class="btn-success submitbtn" (click)="signup();">{{ 'SIGNUP.CREATE_ACCOUNT'| translate }}</button>
					<!-- <div class="or-divider">
						<span>{{ 'SIGNUP.OR'| translate }}</span>
					</div>					
					<div class="bt-text">
						{{ 'SIGNUP.ALREADY_HAVE_ACCOUNT'| translate }} <a href="javascript:void(0);" (click)="toggle();">{{ 'GLOBAL.LOGIN'| translate }}</a>
					</div> -->
				</form>
			</div>
		</div>
	</div>
</div>
<!-- signup form section end -->
