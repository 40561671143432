import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import {TranslateService} from '@ngx-translate/core';

/**
* Component
**/  
@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls : ['./pagination.component.css'],

})
export class PaginationComponent implements OnInit {
  env = environment;
  @Input() page: any;
  @Input() count: any;
  @Input() perPage: any;
  @Input() pagesToShow: any;

  @Output() goPrev = new EventEmitter<boolean>();
  @Output() goNext = new EventEmitter<boolean>();
  @Output() goPage = new EventEmitter<number>();
  @Output() goFirstPage = new EventEmitter<boolean>();
  @Output() goLastPage = new EventEmitter<boolean>();

  /**
  * constructor
  **/
  constructor(public translate: TranslateService) { }
  
  /**
  * On Init 
  **/
  ngOnInit() {

 }
 /**This function is used to get minimum number of page */
  getMin(): number {
    return ((this.perPage * this.page) - this.perPage) + 1;
  }

  /**This function is used to get maximum number of page */
  getMax(): number {
    let max = this.perPage * this.page;
    if (max > this.count) {
      max = this.count;
    }
    return max;
  }

  /**This function is used to get perticular clicked page */
  onPage(n: number): void {
    this.goPage.emit(n);
  }
  /**This function is used to get previous page */
  onPrev(): void {
    this.goPrev.emit(true);
  }
  /**This function is used to get next page */
  onNext(next: boolean): void {
    this.goNext.emit(next);
  }
  /**This function is used to get total page*/
  totalPages(): number {
    return Math.ceil(this.count / this.perPage) || 0;
  }

  /**This function is used to get last page*/
  lastPage(): boolean {
    return this.perPage * this.page >= this.count;
  }

  /**This function is used to get total pages count */
  getPages(): number[] {
		const c = Math.ceil(this.count / this.perPage);
		const p = this.page || 1;
		const pagesToShow = this.pagesToShow || 9;
		const pages: number[] = [];
		pages.push(p);
		const times = pagesToShow - 1;
		for (let i = 0; i < times; i++) {
		  if (pages.length < pagesToShow) {
			if (Math.min.apply(null, pages) > 1) {
			  pages.push(Math.min.apply(null, pages) - 1);
			}
		  }
		  if (pages.length < pagesToShow) {
			if (Math.max.apply(null, pages) < c) {
			  pages.push(Math.max.apply(null, pages) + 1);
			}
		  }
		}
		pages.sort((a, b) => a - b);
		return pages;
	}

  /**This function is used to go to First Page */
  gotoFirstPage(): void {
    this.goPage.emit(1);
  }
  /**This function is used to go to last page */
  gotoLastPage(): void {
    this.goPage.emit(this.totalPages());
  }
}
