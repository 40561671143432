<!-- testmonial slider section start -->
<section class="testimonials" *ngIf="testmonialSliderData && testmonialSliderData.result">
    <div class="container" *ngIf="testmonialSliderData.result.length > 0">
        <div class="headingtext" *ngIf="page == 'nutritionlist'">
            <h2 class="title_head">{{"NUTRITIONLIST.HAPPY_CUSTOMERS" | translate}}</h2>
        </div>
        <h2 class="wow animate__animated animate__fadeInUp" *ngIf="page == 'home'">{{"HOME.TESTIMONIALS" | translate}}</h2>
        <owl-carousel-o class="testimonials-slider"  [options]="testimonialSliderOptions" myMatchHeight="card-height">
            <ng-container *ngFor="let slide of testmonialSliderData.result">
                <ng-template class="slide" carouselSlide>
                    <div class="testimonials-slide wow animate__animated animate__fadeInUp">
                        <div class="testimonials-box card-height">
                            <div class="testi-profile">
                                <figure>
                                    <img src="{{testmonialSliderData.resizable_url}}{{slide.image}}&width=90px&height=90px&cropratio=90:90" alt="" (load)="slide.loaded = true" [hidden]="!slide.loaded">
                                    <img src="{{env.SITE_USER_NO_IMAGE_URL}}&width=90px&height=90px&cropratio=90:90" alt="" [hidden]="slide.loaded">
                                </figure>
                                <div class="testi-profile-text">
                                    <h5 *ngIf="slide.client_name !=''">{{slide.client_name}}</h5>
                                    <p *ngIf="slide.designation_name !=''">{{slide.designation_name}}</p>
                                </div>
                            </div>
                            <div class="testi-msg" *ngIf="slide.comment !=''">{{slide.comment}}</div>
                        </div>
                    </div>
                </ng-template>
            </ng-container>
        </owl-carousel-o>
    </div>
</section>
<!-- testmonial slider section end -->
