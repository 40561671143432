import { Injectable , Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../../environments/environment';
import { SharedService } from '../modules/shared/service/shared.service';
import { isPlatformBrowser } from '@angular/common';
import { ReplaySubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EncrDecrService } from './encr-decr.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router,ActivatedRoute  } from '@angular/router';
import Swal from 'sweetalert2';

@Injectable()

export class CommonFunctionsService {
	// Define valiables
	private isBrowserSubject = new ReplaySubject<boolean>(0);
	public isBrowser	= this.isBrowserSubject.asObservable();
	env				=	environment;
	SITE_API_URL:	any	=	'';
	data:	any	=	{};
    getSettingUrl: any = environment.SITE_URL+'settings.json'

	// Define constructor
	constructor(
        public sharedService: SharedService,
        public EncrDecr: EncrDecrService,
        private httpClient: HttpClient,
        public translateService: TranslateService,
		private route: ActivatedRoute,
		public router: Router,
        @Inject(PLATFORM_ID) private platformId: object
    ){
		if (isPlatformBrowser(this.platformId)){
			this.isBrowserSubject.next(true);
		}
		this.SITE_API_URL	=	this.env.API_URL;
	}

    // This function is used for geting data from localstorage
	public getItem = (key:any): any=>{
		if(this.isBrowser){
			let data = localStorage.getItem(key);
			return data;
		}
	}

    // This function is used for setting data from localstorage
	public setItem(key:any,item:any): void{
		if(this.isBrowser){
			localStorage.setItem(key,item);
		}
	}

	// This function is used for setting data from localstorage
	public removeItem(key:any): void{
		if(this.isBrowser){
			localStorage.removeItem(key);
		}
	}

    /*This function are used for fetch the settings */
	public getSettings = (): any =>{
		let timestamp			:	any		=	Date.now()
		return this.httpClient.get(this.getSettingUrl+"?temp="+timestamp).toPromise()
			.then((response:any) => {
				let decripted_data =this.EncrDecr.get(this.env.ENCRIPTION_KEY, response.setting);
				return JSON.parse(decripted_data)
			});

	}

	// Function used for get user data/
	updateCurrentUserData = (): any => {
		this.data.method_name		=	'getUserProfileData';
		this.data.data				=	{};
		return this.sharedService.getPost(this.SITE_API_URL, this.data);
	}
	// Function used for get user data/
	getOrderAndPlanDetailForPayment = (data: any): any => {
		this.data.method_name		=	'getOrderAndPlanDetailForPayment';
		this.data.data				=	data;
		return this.sharedService.getPost(this.SITE_API_URL, this.data);
	}
	// Function used for get user data/
	checkoutSuccess = (data: any): any => {
		this.data.method_name		=	'saveOrderAndPlanPaymentDetail';
		this.data.data				=	data;
		return this.sharedService.getPost(this.SITE_API_URL, this.data);
	}

	/** Routing is used to getCountryDialCode **/

	getCountryDialCode = (): any => {
		this.data.method_name	=	'getCountryDialCode';
		this.data.data			=	{};
		return this.sharedService.getPost(this.SITE_API_URL, this.data);
	}

    showDropDown = (data: any): any => {
		this.data.method_name	=	'showDropDown';
		this.data.data			=	data;
		return this.sharedService.getPost(this.SITE_API_URL, this.data);
	}
    getBlockDetail = (data: any): any => {
		this.data.method_name	=	'getBlockDetail';
		this.data.data			=	data;
		return this.sharedService.getPost(this.SITE_API_URL, this.data);
	}

	/** Function to add product in notify me */
	addProductToNotifyMe = (data: any): any => {
		this.data.method_name    = "saveNotifyUserProductInStock";
		this.data.data           = data;
		return this.sharedService.getPost(this.SITE_API_URL,this.data);
	}

	/** Function to remove product from notify me */
	removeProductFromNotifyMe = (data: any): any => {
		this.data.method_name    = "removeProductFromNotifyMe";
		this.data.data           = data;
		return this.sharedService.getPost(this.SITE_API_URL,this.data);
	}

	// Get notifyme product list
	getNotifymelistItems = (data: any): any => {
		this.data.method_name	=	'notifyMeListing';
		this.data.data			=	data;
		return this.sharedService.getPost(this.SITE_API_URL, this.data);
	}

    // Function to submit profile image form
	updateProfileImage(data:any){
		return this.sharedService.getPostImage(this.SITE_API_URL,data);
	}

    // This function is used to scroll on top
	public scrollTop(): void {
		if(isPlatformBrowser(this.platformId)){
			window.scrollTo(0, 0);
		}
	}

    // This function is used to open confirm box
	public confirm(modal_text:any):any{
		return Swal.fire({
			title: this.translateService.instant('ARE_YOU_SURE'),
			text: modal_text,
			icon: 'warning',
			showCancelButton: true,
			cancelButtonText: this.translateService.instant('GLOBAL.CANCEL'),
			confirmButtonText: this.translateService.instant('OK')
		}).then((result:any) => {
			if (result.value) {
				return result.isConfirmed;
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				return result.isConfirmed;
			}
		})
	}

	// This function is used to open confirm box
	public callConfirm(appointmentData:any, imageUrl: any):any{
		let message = this.translateService.instant('APPOINTMENT_START_NOTIFICATION',{appointment_id:appointmentData.appointment_id})
		
		return Swal.fire({
			title: this.translateService.instant('GLOBAL.INCOMING_CALL'),
			text: message,
			html:
				'</div class="popup-info">'+message+'</div>'+
				'<div class="appointment_box popup_info">'+
					'<div class="appointment_left">'+
						'<figure>'+
							'<img src="'+imageUrl+appointmentData.dietician_detail.image+'" alt="'+appointmentData.dietician_detail.full_name+'">'+
						'</figure>'+
						'<h5>'+ appointmentData.dietician_detail.full_name+'</h5>'+
						'<strong>'+appointmentData.dietician_detail.profession_name+'</strong>'+
					'</div>'+
				'</div>',
			allowOutsideClick: false,
			showCancelButton: true,
			cancelButtonText: this.translateService.instant('GLOBAL.JOIN_WITH_AUDIO'),
			confirmButtonText: this.translateService.instant('GLOBAL.JOIN_WITH_VIDEO')
		}).then((result:any) => {
			if (result.value) {
				return result.isConfirmed;
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				return result.isConfirmed;
			}
		})
	}



	// This function is used to open pplan confirm box
	public purchasePlanConfirm(planData:any):any{
		
		return Swal.fire({
			title: 'Please Select Dietician',
			text: 'Please Select Dietician',
			allowOutsideClick : false,
			showCancelButton  : true,
			cancelButtonText  : this.translateService.instant('GLOBAL.CANCEL'),
			confirmButtonText : this.translateService.instant('OK')
		}).then((result:any) => {
			if (result.value) {
				return result.isConfirmed;
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				return result.isConfirmed;
			}
		})
	}

    blockUI(): void{
		if(this.isBrowser){
			$('.loading-cntant').fadeIn(100);
		}
	}
	unblockUI(): void{
		if(this.isBrowser){
			$('.loading-cntant').fadeOut(1000);
		}
	}



	// This function to change query parameter in URL on form change
	updateQueryParams(queryParamsAttr: any):void{
		// triggering a navigation event,
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: queryParamsAttr,
			queryParamsHandling: 'merge',
			// preserve the existing query params in the route
			skipLocationChange: false,
			// do not trigger navigation
		});

	}



	
    /**This function are used for free shippping price data . */
    getRecentViewProductData(){
        this.data['method_name']                =   'getRecentViewProducts';
        this.data['data']                       =   {};
        return this.sharedService.getPost(this.SITE_API_URL,this.data);
    }


}
