import { Directive, ElementRef, HostListener,Input } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../_services/user.service';
import { WishlistService } from '../_services/wishlist.service';
import { TextSetting } from '../textsetting';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { CommonFunctionsService, NgxToasterService } from '../_services/index';
import { environment } from '../../environments/environment';

declare var bootbox:any;
@Directive({
    selector: '[appAddToWishlist]'
})

export class AddToWishlistDirective {
    env = environment;

    @Input('product') product: any;

    @HostListener('click') onClick() {
        this.addToWishlist();
    }

    constructor(
        private el: ElementRef,
        public router: Router,
        public userService: UserService,
        public wishlistService: WishlistService,
        public toasterNotification: NgxToasterService,
        public translateService: TranslateService
    ) {

    }

    /**
	 * addToWishlist()
	 * @Description Function to add product in wishlist.

	 * */

    addToWishlist() : void{
        let userData   =   this.userService.getCurrentUser();
        if(Object.keys(userData).length	==	0){
            //this.toasterNotification.showError(this.translateService.instant('WISHLIST_UNAUTHORIZED_MSG'));
            this.router.navigate(['/login'], { queryParams: { returnUrl: this.router.url }});
        }else{
            let formData	: any = {product_id: this.product._id};
            this.wishlistService.addProductToWishlist(formData).subscribe((res: any) => {
                if(res.data.status == this.env.SUCCESS_STATUS){
                    this.wishlistService.setWishlistNotificationEvent('1');
                    this.toasterNotification.showSuccess(this.translateService.instant(res.data.message));
                    this.product.is_favourite =   1;
                }else{
                    this.toasterNotification.showError(res.data.message);
                }
            });
        }
    }
}
