import { Injectable } from "@angular/core";
import { SharedService } from "../../shared/service/shared.service";
import { environment } from "../../../../environments/environment";
import { Observable } from "rxjs";

@Injectable()
export class CommonService {
    // Define valiables
    SITE_API_URL: any = "";
    data: any = {};

    // Define constructor
    constructor(public sharedService: SharedService) {
        this.SITE_API_URL = environment.API_URL;
    }

    // Function to submit signup form
    public submitSignupForm = (formData: any): any => {
        this.data.method_name = "signupUser";
        this.data.data = formData;
        return this.sharedService.getPost(this.SITE_API_URL, this.data);
    };

    // Function to submit signup form
    public getSocialUrl = (): any => {
        this.data.method_name = "getSocicalUrl";
        this.data.data = {};
        return this.sharedService.getPost(this.SITE_API_URL, this.data);
    };
    
    // Function to submit signup form
    public submitNewsletterForm = (formData: any): any => {
        this.data.method_name = "";
        this.data.data = formData;
        return this.sharedService.getPost(this.SITE_API_URL, this.data);
    };

    // Function to submit signup form
    public login = (formData: any): any => {
        this.data.method_name = "login";
        this.data.data = formData;
        return this.sharedService.getPost(this.SITE_API_URL, this.data);
    };

    /** this function is used for user registration (Signup) */
    public checkSocialUser = (formData: any): any => {
        this.data.method_name = "verifySocialUser";
        this.data["data"] = formData;
        return this.sharedService.getPost(this.SITE_API_URL, this.data);
    };

    // Social user signup
    public saveSocialUser = (formData: any): Observable<any> => {
        this.data.method_name = "saveSocialUser";
        this.data["data"] = formData;
        return this.sharedService.getPost(this.SITE_API_URL, this.data);
    };

    // This function is used for get Header Category Data
    getHeaderCategoryData() {
        this.data.method_name = "getHeaderCategoryData";
        return this.sharedService.getPost(this.SITE_API_URL, this.data);
    }

    // This function is used for get Appointment Notification
    getAppointmentNotification() {
        this.data.method_name = "startAppointmentSendToUser";
        this.data.data = {}
        return this.sharedService.getPost(this.SITE_API_URL, this.data);
    }

    // This function is used for get Popular Tags
    getPopularTags() {
        this.data.method_name = "getPopularTags";
        return this.sharedService.getPost(this.SITE_API_URL, this.data);
    }
    // This function is used for get Block Data
    public getBlockData = (formData: any): any => {
        this.data.method_name = "getBlockDetail";
        this.data.data = formData;
        return this.sharedService.getPost(this.SITE_API_URL, this.data);
    };

    // This function is used for Currencies
    public getCurrencies = (): any => {
        this.data.method_name = "getCurrencyList";
        return this.sharedService.getPost(this.SITE_API_URL, this.data);
    };

    // this function is use to call  newsletter subscribe api
	public newsletterSubscribe = (formData: any): any => {
		this.data["method_name"]=  'newsletterSubscribe';
		this.data["data"]		=	formData;
		return this.sharedService.getPost(this.SITE_API_URL,this.data);
	};

    /** This function is used for Unread Notification Count */
	getUnreadNotificationCount = (): any => {
        this.data.method_name   = "getUnreadNotificationCounter";
        this.data["data"] = {};
        return this.sharedService.getPost(this.SITE_API_URL, this.data);
    }

    /** Function to get Testimonial data */
	getTestimonialData() {
		this.data["method_name"]=  'getTestimonials';
		this.data["data"]		=	{};
		return this.sharedService.getPost(this.SITE_API_URL,this.data);
    }

    /** Function to global product search */
	globalProductSearch = (formData: any): any => {
		this.data["method_name"]=  'globalProductSearch';
		this.data["data"]		=	formData;
		return this.sharedService.getPost(this.SITE_API_URL,this.data);
    }



    /**This function are used for verify Account data. */
    verifyAccount(validate_string: string){
        this.data['method_name']                =   'verifyUserEmail';
        this.data['data']                       =   {};
        this.data['data']['validate_string']    =   validate_string;
        return this.sharedService.getPost(this.SITE_API_URL,this.data);
    }


    /**This function are used for free shippping price data . */
    getFreeShippingPrice(){
        this.data['method_name']                =   'getfreeShippingPriceData';
        this.data['data']                       =   {};
        return this.sharedService.getPost(this.SITE_API_URL,this.data);
    }


    /** this function is used for get user plan data   */
	getMyPlanByDietician = (data:any): any => {
		this.data.method_name	=	'getMyPlanByDietician';
		this.data['data'] 		= 	data;
		return this.sharedService.getPost(this.SITE_API_URL, this.data);
	}



    

}
