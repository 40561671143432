/** Import Angular Built-In Packages and Custom Files and Services */
import { Component, OnInit,OnDestroy,ViewEncapsulation, ViewChild, Input} from '@angular/core';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { Router} from '@angular/router';
import { CommonFunctionsService, NgxToasterService, UserService, ValidationService, CartFunctionsService, SocketService } from '../../../../_services/index';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../service/common.service';
import { ReCaptcha2Component } from 'ngx-captcha';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthenticationService } from 'src/app/_services/authentication.service';

/** Component */
@Component({
	selector: 'app-signup',
	styleUrls: ['./signup.component.css'],
	templateUrl: './signup.component.html',
	providers: [SocialAuthService],
	encapsulation: ViewEncapsulation.None
})
export class SignupComponent implements OnInit, OnDestroy {
	private ngUnsubscribe: Subject<void> = new Subject<void>();
    datePickerConfig: Partial<BsDatepickerConfig>;

	/** Define Variables */
	env            				= environment;
	isBrowser                   : boolean = false;
	contryDialCodeList			: any		=	[];
	//tabIndex                  : any     	= "";
	@Input() tabIndex			: any;
	signupForm					: FormGroup;
	signupFormError				: any		=	{};
	progress					: boolean	|	number	=	false;
    socialUser					: any 		= null;
    cpasswordValid				: boolean 	= true;

	@ViewChild ('captchaElem') captchaElem : ReCaptcha2Component | any;

	/** Define Constructor */
	constructor(
		private commonFunctionsService	: CommonFunctionsService,
		private modalService			: BsModalService,
        private userService				: UserService,
		private socialAuthService		: SocialAuthService,
        public router					: Router,
		public translateService			: TranslateService,
		public formBuilder				: FormBuilder,
		public commonService			: CommonService,
		public toasterNotification		: NgxToasterService,
		public modalRef					: BsModalRef,
		private cartFunctionsService	: CartFunctionsService,
		private socketService	: SocketService,
		private afService: AngularFireAuth,
		private authService: AuthenticationService

	) {
		let ageLimit = new Date();
		ageLimit = new Date(ageLimit.setFullYear(ageLimit.getFullYear()- this.env.MINIMUM_AGE_RESTRICTION));
        this.datePickerConfig = Object.assign({},
			{
			containerClass    	: 'theme-green',
			maxDate           	: ageLimit, //new Date(),
			showWeekNumbers     : false,
			dateInputFormat		: this.env.DATEPICKER_DATE_FORMATE,
		});
		this.signupForm	=	this.formBuilder.group({});
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if (isBrowser) {
				this.isBrowser = true;
			}
		});
	}

	/** this function is used for component initialization*/
	ngOnInit(): void {
		this.createSignupForm();
        this.getCountryDialCode();
        //this.initializeSocialService();
	}

    // This function is used to login with google
	initializeSocialService(): void {
		// this.socialAuthService.authState.subscribe((user: any) => {
		// 	this.socialUser = user;
		// 	if(this.socialUser !=  null){
		// 		/*Check User have an valid or not for login or sigup */
		// 		if(Object.keys(this.userService.getCurrentUser()).length == 0){
		// 			this.checkSocialUser();
		// 		}
		// 		/*Check User have an valid or not for login or sigup */
		// 	}
		// });
		// this.afService.authState.subscribe(async (user: any) => {
		// 	 this.setGoogleData(user);
		// 	// this.socialUser = user;
		// 	// console.log(user);
		// 	// if(this.socialUser !=  null){
				
		// 	// 	const obj = {
		// 	// 		id: user.providerData[0].uid,
		// 	// 		name: user.displayName,
		// 	// 		email: user.email,
		// 	// 		photoUrl:user.photoURL,
		// 	// 		firstName:user.displayName.split(' ')[0],
		// 	// 		lastName:user.displayName.split(' ')[1],
		// 	// 		authToken:user.Aa,
		// 	// 		idToken:user.uid
		// 	// 	}
		// 	// 	this.socialUser = obj;
		// 	// 	this.socialUser['provider'] = 'GOOGLE';
		// 	// 	console.log('pujan ', this.socialUser)
		// 	// 	/*Check User have an valid or not for login or sigup */
		// 	// 	if(Object.keys(this.userService.getCurrentUser()).length == 0){
		// 	// 		//this.checkSocialUser();
		// 	// 	}
		// 	// 	/*Check User have an valid or not for login or sigup */
		// 	// }
		// })

	}
	setGoogleData(user : any):void{
			this.socialUser = user;
			//console.log(user);
			if(this.socialUser !=  null){
				
				const obj = {
					id: user.providerData[0].uid,
					name: user.displayName,
					email: user.email,
					photoUrl:user.photoURL,
					firstName:user.displayName.split(' ')[0],
					lastName:user.displayName.split(' ')[1],
					authToken:user.Aa,
					idToken:user.uid
				}
				this.socialUser = obj;
				this.socialUser['provider'] = 'GOOGLE';
				//console.log('pujan ', this.socialUser)
				/*Check User have an valid or not for login or sigup */
				if(Object.keys(this.userService.getCurrentUser()).length == 0){
					this.checkSocialUser();
				}
				/*Check User have an valid or not for login or sigup */
			}
	}
	setFacebookData(user : any):void{
		this.socialUser = user;
		//console.log(user);
		if(this.socialUser !=  null){
			
			const obj = {
				id: user.providerData[0].uid,
				name: user.displayName,
				email: user.email,
				photoUrl:user.photoURL,
				firstName:user.displayName.split(' ')[0],
				lastName:user.displayName.split(' ')[1],
				authToken:user.Aa,
				idToken:user.uid
			}
			this.socialUser = obj;
			this.socialUser['provider'] = 'FACEBOOK';
			//console.log('pujan ', this.socialUser)
			/*Check User have an valid or not for login or sigup */
			if(Object.keys(this.userService.getCurrentUser()).length == 0){
				this.checkSocialUser();
			}
			/*Check User have an valid or not for login or sigup */
		}
	}
	
	// This function is used to login with google
	signupWithGoogle(): void {
		// console.log("Inside Login");
		// console.log(GoogleLoginProvider.PROVIDER_ID);
		// this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then(
		// 	res => console.log(res)
		// ).catch(err=>console.log(err));
		this.authService.GoogleAuth()
			.then(res => {
				//console.log(res.user);
				this.setGoogleData(res.user);
			})
			.catch(error => {
				console.log('Something went wrong: ', error);
			});
	}

	// This function is used to login with facebook
	signupWithFacebook(): void {
		// console.log("Inside Login");
		// console.log(FacebookLoginProvider.PROVIDER_ID);
		// this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).catch(err=>console.log(err));
		this.authService.FacebookAuth()
			.then(res => {
				//console.log(res);
				this.setFacebookData(res.user);
			})
			.catch(error => {
				console.log('Something went wrong: ', error);
			});
	}

	// This function is used to signout from social service
	signOut(): void {
		this.socialAuthService.signOut();
	}

	// This function are used for check social the user valid or not
	public checkSocialUser(){
		this.commonService.checkSocialUser(this.socialUser).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
			this.signOut();
			if(response.data.status === this.env.SUCCESS_STATUS){
				let loginuserData 			= response.data.userData;
                let user_name 				= loginuserData.first_name;
                user_name 					= user_name[0].toUpperCase() + user_name.slice(1);
                let loginUserToken 			= loginuserData.token;
                let loginUserRefreshToken 	= loginuserData.token;
                this.userService.setAuth(loginuserData);
                this.userService.setToken(loginUserToken);
                this.userService.setRefreshToken(loginUserRefreshToken);
                this.commonFunctionsService.removeItem('validate_string');
                this.commonFunctionsService.removeItem('mobile_number');
                this.toasterNotification.showSuccess(this.translateService.instant('WELCOME_LOGIN') + ' ' + user_name + ', ' + this.translateService.instant('SUCCESS_LOGIN'));
                this.modalService.hide();
                document.body.classList.remove('modal-open');
				this.cartFunctionsService.setCartNotificationEvent('1');
				this.socketService.Login();
    		}else if(response.data.status == 'new_user') {
				this.createSocialUser();

            }else if(response.data.status == this.env.OTP_STATUS_ERROR_VALIDATION) {
				this.modalService.hide();
                document.body.classList.remove('modal-open');
                this.commonFunctionsService.setItem('validate_string',response.data.validate_string);
                this.commonFunctionsService.setItem('mobile_number',response.data.mobile_number);
                this.router.navigate(['/signup/thank-you']);
				this.toasterNotification.showError(response.data.message);
			}else{
				this.toasterNotification.showError(response.data.message);
			}
		})
	}

	createSocialUser() {
		const user = this.socialUser;
		const obj = {
			social_id: user.id,
			name: user.name,
			email: user.email,
			firstName:user.firstName,
			lastName:user.lastName,
			social_type: user.provider,
			mobile_number:''
		}
		this.commonService.saveSocialUser(obj).subscribe(
			response => {
				if(response.data.status	==	this.env.SUCCESS_STATUS) {
					let loginuserData 			= response.data.userData;
                    let user_name 				= loginuserData.first_name;
                    user_name 					= user_name[0].toUpperCase() + user_name.slice(1);
                    let loginUserToken 			= loginuserData.token;
                    let loginUserRefreshToken 	= loginuserData.token;
                    this.userService.setAuth(loginuserData);
                    this.userService.setToken(loginUserToken);
                    this.userService.setRefreshToken(loginUserRefreshToken);
                    this.commonFunctionsService.removeItem('validate_string');
                    this.commonFunctionsService.removeItem('mobile_number');
                    this.toasterNotification.showSuccess(this.translateService.instant('WELCOME_LOGIN') + ' ' + user_name + ', ' + this.translateService.instant('SUCCESS_LOGIN'));
				  	this.cartFunctionsService.setCartNotificationEvent('1');
					this.socketService.Login();

			    	this.progress	=	false;
                    this.modalService.hide();
		            document.body.classList.remove('modal-open');
                    this.commonFunctionsService.setItem('validate_string',response.data.validate_string);
                    //this.commonFunctionsService.setItem('mobile_number',response.data.mobile_number);
				    //this.router.navigate(['/signup-thankyou']);
		
				    //this.toasterNotification.showSuccess(response.data.message);
					
			    } else {
			    	if(response.data.errors	!==	undefined	&&	response.data.errors	!=	''	&&	response.data.errors 	!==	null)	{
				        this.signupFormError	=	response.data.errors;
    				} else {
    				    this.toasterNotification.showError(response.data.message);
    				}
				    this.progress	=	false;
			    }
			}, err => console.log(err)
		)
	}

    // This function is used for get country dial code
	public getCountryDialCode(){
		this.commonFunctionsService.getCountryDialCode().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
			this.contryDialCodeList = response.data.result;
        });
	}

    // This function is used for create Signup Form
	public createSignupForm(): void {
        this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if (isBrowser) {
				if(this.socialUser ==  null){
					let socialData		=	this.commonFunctionsService.getItem('socialData');
					if(socialData != null){
						this.socialUser		=	JSON.parse(socialData);
						this.commonFunctionsService.removeItem('socialData');
					}
				}
			}
		})
		let	first_name	    =	'';
		let	last_name		=	'';
		let	email		    =	'';
		let	socialId		=	'';
		let	provider		=	'';
		if(this.socialUser	 != null){
			first_name	    =	this.socialUser.firstName;
			last_name		=	this.socialUser.lastName;
			email		    =	this.socialUser.email;
			socialId		=	this.socialUser.id;
			provider		=	this.socialUser.provider;
		}

		this.signupForm	=	this.formBuilder.group({
			first_name			:	[first_name,	Validators.required],
			last_name			:	[last_name,	Validators.required],
			//user_name			:	['', Validators.required],
			email				:	[email, [Validators.required, ValidationService.emailValidator]],
			dial_code			:	[this.env.DEFAULT_COUNTRY_DIAL_CODE, Validators.required],
			phone				:	['',	[Validators.required, ValidationService.numberValidator]],
			password			:	['',	[Validators.required, ValidationService.passwordValidator]],
			confirm_password	:	['',	[Validators.required, ValidationService.MatchPassword]],
			//recaptcha			:	['',	Validators.required],
			country_code		:	['BH'],
			date_of_birth		:	['',	Validators.required],
			social_id			: 	[socialId],
			social_type			: 	[provider],
		});
	}

	// This function is used to close modal
	public closeModal(): void {
		this.modalService.hide();
		document.body.classList.remove('modal-open');
	}

	// This function is used to submit signup form
	public signup(): void	{
		this.progress	=	0;
		///console.log(this.signupForm);
		if (this.signupForm.valid && this.cpasswordValid){
			this.commonService.submitSignupForm(this.signupForm.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
			    if(response.data.status	==	this.env.SUCCESS_STATUS) {
			    	this.progress	=	false;
                    this.modalService.hide();
		            document.body.classList.remove('modal-open');
                    this.commonFunctionsService.setItem('validate_string',response.data.validate_string);
                    this.commonFunctionsService.setItem('mobile_number',response.data.mobile_number);
				    this.router.navigate(['/signup-thankyou']);
				    this.toasterNotification.showSuccess(response.data.message);
			    } else {
			    	if(response.data.errors	!==	undefined	&&	response.data.errors	!=	''	&&	response.data.errors 	!==	null)	{
				        this.signupFormError	=	response.data.errors;
    				} else {
    				    this.toasterNotification.showError(response.data.message);
    				}
				    this.progress	=	false;
			    }
			})
		} else {
			this.progress = false;
			ValidationService.validateAllFormFields(this.signupForm);
		}
	}

    // This function is used to handle toggle login and signup form
	public toggle(): void {
		const element = document.getElementById('home');
		const prevElement = document.getElementById('profile');
		const tabElement = document.getElementById('home-tab');
		const prevTabElement = document.getElementById('profile-tab');

		if(element && tabElement)	{
			element.classList.toggle('active');
			tabElement.classList.toggle('active');
		}
		if(prevElement && prevTabElement)	{
			prevElement.classList.toggle('active');
			prevTabElement.classList.toggle('active');
		}
	}

    // This function is used to handle captcha expiry
	handleExpire(): void {
		this.captchaElem.resetCaptcha();
	}


	/** this function is used to destroy Component */
	public ngOnDestroy(): void {
		this.ngUnsubscribe.next();
	}

    /** this function is used to check confirm password & password value */
	public changeSignupPassword(){
		const password	=	this.signupForm.value.password;
		const cpassword	=	this.signupForm.value.confirm_password;
		if(password	!== '' && cpassword	!== '' && password !== cpassword){
			this.cpasswordValid	=	false;
		}else{
			this.cpasswordValid	=	true;
		}

	}

	/* Function calling on change of change confirm password*/
	public changecPassword(){
		const password	=	this.signupForm.value.password;
		const cpassword	=	this.signupForm.value.confirm_password;
		if(password	!== '' && cpassword !== '' && password !== cpassword){
			this.cpasswordValid	=	false;
		}else{
			this.cpasswordValid	=	true;
		}
	}
}
