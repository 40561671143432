/** Import Angular Built-In Packages and Custom Files and Services */
import { Component, OnInit, OnDestroy,ElementRef,ViewEncapsulation } from '@angular/core';
import { CommonFunctionsService, NgxToasterService, UserService, ValidationService, CartFunctionsService, SocketService } from '../../../../../_services/index';
import { environment } from '../../../../../../environments/environment';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../service/common.service';
import { SocialAuthService, FacebookLoginProvider, GoogleLoginProvider } from 'angularx-social-login';
import { AuthenticationService } from 'src/app/_services/authentication.service';
import { AngularFireAuth } from '@angular/fire/auth';

/** Component */
@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls	:	['./login.component.css'],
	providers: [CommonFunctionsService]
})


export class LoginComponent implements OnInit, OnDestroy  {
	private ngUnsubscribe: Subject<void> = new Subject<void>();


	/**Define Variables */
	env                  	= environment;
	isBrowser            	: boolean 	= false;
	loginForm				: FormGroup;
	loginFormError			: any		= {};
	contryDialCodeList		: any		= [];
	progress				: boolean	| number	=	false;
	tabIndex                : any     	= "";
	loginBy                	: string 	= 'mobile';
	rememberMeValue			: boolean 	= false;
	remeberMeData		 	: any		= {};
	rememberlocalStorage 	: any  		= {};
	userNameMobileNoValue	: any		= "";
	returnUrl				: string	= '';
	dialCodeValue			: any		= "";
	loginByValue			: any		= "";
	socialUser				: any 		= null;
	/*Declare Constructor*/
	constructor(

		private commonFunctionsService: CommonFunctionsService,
		private userService: UserService,
		private router: Router,
		private route: ActivatedRoute,
		private formBuilder: FormBuilder,
		public	translateService: TranslateService,
		private commonService: CommonService,
		private toasterNotification: NgxToasterService,
		public modalRef: BsModalRef,
		private modalService: BsModalService,
		private socialAuthService: SocialAuthService,
		private cartFunctionsService: CartFunctionsService,
		private socketService: SocketService,
		private authService: AuthenticationService,
		private afService: AngularFireAuth
	) {
		this.loginForm	=	this.formBuilder.group({});
	}


	/** Declare Component initialization*/
	ngOnInit(): void {
        this.createLoginForm();
        this.getCountryDialCode();
        this.remeberMe();
        //this.initializeSocialService();
	}

    // This function is used to login with google
	initializeSocialService(): void {
		// this.socialAuthService.authState.subscribe((user) => {
		// 	this.socialUser = user;
		// 	if(this.socialUser !=  null){
		// 		/*Check User have an valid or not for login or sigup */
		// 		if(Object.keys(this.userService.getCurrentUser()).length == 0){
		// 			this.checkSocialUser();
		// 		}
		// 		/*Check User have an valid or not for login or sigup */
		// 	}
		// });
		// this.afService.authState.subscribe((user: any) => {
		// 	console.log(user);
		// 	this.socialUser = user.providerData[0];
		// 	if(this.socialUser !=  null){
		// 		this.socialUser['id'] = this.socialUser.uid;
		// 		this.socialUser['provider'] = this.socialUser.providerId;
		// 		/*Check User have an valid or not for login or sigup */
		// 		if(Object.keys(this.userService.getCurrentUser()).length == 0){
		// 			this.checkSocialUser();
		// 		}
		// 		/*Check User have an valid or not for login or sigup */
		// 	}
		// })
	}
	setGoogleData(user : any):void{
		this.socialUser = user;
		//console.log(user);
		if(this.socialUser !=  null){
			
			const obj = {
				id: user.providerData[0].uid,
				name: user.displayName,
				email: user.email,
				photoUrl:user.photoURL,
				firstName:user.displayName.split(' ')[0],
				lastName:user.displayName.split(' ')[1],
				authToken:user.Aa,
				idToken:user.uid
			}
			this.socialUser = obj;
			this.socialUser['provider'] = 'GOOGLE';
			//console.log('pujan ', this.socialUser)
			/*Check User have an valid or not for login or sigup */
			if(Object.keys(this.userService.getCurrentUser()).length == 0){
				this.checkSocialUser();
			}
			/*Check User have an valid or not for login or sigup */
		}
	}
	setFacebookData(user : any):void{
		this.socialUser = user;
		//console.log(user);
		if(this.socialUser !=  null){
			
			const obj = {
				id: user.providerData[0].uid,
				name: user.displayName,
				email: user.email,
				photoUrl:user.photoURL,
				firstName:user.displayName.split(' ')[0],
				lastName:user.displayName.split(' ')[1],
				authToken:user.Aa,
				idToken:user.uid
			}
			this.socialUser = obj;
			this.socialUser['provider'] = 'FACEBOOK';
			//console.log('pujan ', this.socialUser)
			/*Check User have an valid or not for login or sigup */
			if(Object.keys(this.userService.getCurrentUser()).length == 0){
				this.checkSocialUser();
			}
			/*Check User have an valid or not for login or sigup */
		}
	}
	// This function is used to login with google
	loginWithGoogle(): void {		
		this.authService.GoogleAuth()
			.then(res => {				
				this.setGoogleData(res.user);
			})
			.catch(error => {
				console.log('Something went wrong: ', error);
			});
	}

	// This function is used to login with facebook
	loginWithFacebook(): void {
		this.authService.FacebookAuth()
			.then(res => {
				this.setFacebookData(res.user);
				///console.log('Sucess', res)
			})
			.catch(error => {
				console.log('Something went wrong: ', error);
			});
	}

	// This function is used to signout from social service
	signOut(): void {
		this.socialAuthService.signOut();
	}

	// This function are used for check social the user valid or not
	public checkSocialUser(){
		this.commonService.checkSocialUser(this.socialUser).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
			this.signOut();
			if(response.data.status === this.env.SUCCESS_STATUS){
				let loginuserData 			= response.data.userData;
                let user_name 				= loginuserData.first_name;
                user_name 					= user_name[0].toUpperCase() + user_name.slice(1);
                let loginUserToken 			= loginuserData.token;
                let loginUserRefreshToken 	= loginuserData.token;
                this.userService.setAuth(loginuserData);
                this.userService.setToken(loginUserToken);
                this.userService.setRefreshToken(loginUserRefreshToken);
                this.commonFunctionsService.removeItem('validate_string');
                this.commonFunctionsService.removeItem('mobile_number');
                this.toasterNotification.showSuccess(this.translateService.instant('WELCOME_LOGIN') + ' ' + user_name + ', ' + this.translateService.instant('SUCCESS_LOGIN'));
                this.modalService.hide();
                document.body.classList.remove('modal-open');
			    this.cartFunctionsService.setCartNotificationEvent('1');
			    this.socketService.Login();
                this.loginRedirect()
			}else if(response.data.status == 'new_user') {
				// this.commonFunctionsService.setItem('socialData',JSON.stringify(this.socialUser));
				// this.goToSignup();
				this.createSocialUser();
			}else if(response.data.status == this.env.OTP_STATUS_ERROR_VALIDATION) {
				this.commonFunctionsService.setItem('validate_string',response.data.validate_string);
				this.commonFunctionsService.setItem('mobile_number',response.data.mobile_number);
				this.modalService.hide();
				document.body.classList.remove('modal-open');
				this.router.navigate(['/signup/thank-you']);
				this.toasterNotification.showError(response.data.message);
			}else{
				this.toasterNotification.showError(response.data.message);
			}
		})
	}
	createSocialUser() {
		const user = this.socialUser;
		const obj = {
			social_id: user.id,
			name: user.name,
			email: user.email,
			firstName:user.firstName,
			lastName:user.lastName,
			social_type: user.provider,
			mobile_number:''
		}
		this.commonService.saveSocialUser(obj).subscribe(
			response => {
				if(response.data.status	==	this.env.SUCCESS_STATUS) {
					let loginuserData 			= response.data.userData;
                    let user_name 				= loginuserData.first_name;
                    user_name 					= user_name[0].toUpperCase() + user_name.slice(1);
                    let loginUserToken 			= loginuserData.token;
                    let loginUserRefreshToken 	= loginuserData.token;
                    this.userService.setAuth(loginuserData);
                    this.userService.setToken(loginUserToken);
                    this.userService.setRefreshToken(loginUserRefreshToken);
                    this.commonFunctionsService.removeItem('validate_string');
                    this.commonFunctionsService.removeItem('mobile_number');
                    this.toasterNotification.showSuccess(this.translateService.instant('WELCOME_LOGIN') + ' ' + user_name + ', ' + this.translateService.instant('SUCCESS_LOGIN'));
				  	this.cartFunctionsService.setCartNotificationEvent('1');
					this.socketService.Login();

			    	this.progress	=	false;
                    this.modalService.hide();
		            document.body.classList.remove('modal-open');
                    this.commonFunctionsService.setItem('validate_string',response.data.validate_string);
                    //this.commonFunctionsService.setItem('mobile_number',response.data.mobile_number);
				    //this.router.navigate(['/signup-thankyou']);
		
				    //this.toasterNotification.showSuccess(response.data.message);
					
			    } else {
			    	if(response.data.errors	!==	undefined	&&	response.data.errors	!=	''	&&	response.data.errors 	!==	null)	{
				        this.loginFormError	=	response.data.errors;
    				} else {
    				    this.toasterNotification.showError(response.data.message);
    				}
				    this.progress	=	false;
			    }
			}, err => console.log(err)
		)
	}
	// This function are used for go To Signup
    public goToSignup() {
		const element	=	document.getElementById('profile');
		const prevElement	=	document.getElementById('home');
		const tabElement	=	document.getElementById('profile-tab');
		const prevTabElement	=	document.getElementById('home-tab');

		if(element	&&	tabElement)	{
			element.classList.add('active');
			tabElement.classList.add('active');
		}
		if(prevElement	&&	prevTabElement)	{
			prevElement.classList.remove('active');
			prevTabElement.classList.remove('active');
		}
	}
	// This function are used for remeber Me 
    public remeberMe(){
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if (isBrowser) {
				/** to get remember me data through local storage and set values */
				const RememberLocalData = JSON.parse(this.commonFunctionsService.getItem('remeberMeData') || '{}')
				if (RememberLocalData) {
					this.userNameMobileNoValue		=	RememberLocalData.user_name_mobile_no;
					this.dialCodeValue				=	(RememberLocalData.dial_code)?RememberLocalData.dial_code:this.env.DEFAULT_COUNTRY_DIAL_CODE;
					this.loginByValue				=	RememberLocalData.login_by;
					this.rememberMeValue			=	RememberLocalData.remeber_me;
					this.createLoginForm();
				}
			}
		});
	}

    // This function is used to create login form
	public createLoginForm()	{
		if(this.loginByValue != null &&  this.loginByValue!= '' &&  this.loginByValue != 'undefined'){
			this.loginBy    =    this.loginByValue;
		}
		this.loginForm	=	this.formBuilder.group({
			login_by    		: [this.loginBy, Validators.required],
			dial_code			: [this.dialCodeValue],
			user_name_mobile_no	: [this.userNameMobileNoValue, Validators.required],
			password    		: ['', Validators.required],
			remember_me			: [this.rememberMeValue]
		})
	}

    // This function is used for get country dial code
	public getCountryDialCode(){
		this.commonFunctionsService.getCountryDialCode().pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
			this.contryDialCodeList = response.data.result;
        });
	}

    // This function is used to submit login form
	public submitLogin()	{
		if(this.loginForm.valid)	{
			this.progress	=	true;
			this.commonService.login(this.loginForm.value).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response	:	any)	=>	{
				if (response.data.status == this.env.SUCCESS_STATUS) {
                    this.progress = false;
                    if (this.loginForm.value.remember_me) {
                        this.remeberMeData['user_name_mobile_no'] 		= this.loginForm.value.user_name_mobile_no;
                        this.remeberMeData['dial_code'] 		= this.loginForm.value.dial_code;
                        this.remeberMeData['login_by'] 		= this.loginForm.value.login_by;
                        this.remeberMeData['remeber_me'] 	= true;
                        this.commonFunctionsService.setItem('remeberMeData', JSON.stringify(this.remeberMeData));
                    } else {
                        this.commonFunctionsService.removeItem('remeberMeData');
                    }
                    let loginuserData 			= response.data.userData;
                    let user_name 				= loginuserData.first_name;
                    user_name 					= user_name[0].toUpperCase() + user_name.slice(1);
                    let loginUserToken 			= loginuserData.token;
                    let loginUserRefreshToken 	= loginuserData.token;
                    this.userService.setAuth(loginuserData);
                    this.userService.setToken(loginUserToken);
                    this.userService.setRefreshToken(loginUserRefreshToken);
                    this.commonFunctionsService.removeItem('validate_string');
                    this.commonFunctionsService.removeItem('mobile_number');
                    this.toasterNotification.showSuccess(this.translateService.instant('WELCOME_LOGIN') + ' ' + user_name + ', ' + this.translateService.instant('SUCCESS_LOGIN'));
				  	this.cartFunctionsService.setCartNotificationEvent('1');
					this.socketService.Login();
				    this.modalService.hide();
    		        document.body.classList.remove('modal-open');
                    this.loginRedirect()
                } else if (response.data.status == this.env.OTP_STATUS_ERROR_VALIDATION) {
                    this.progress = false;
                    this.commonFunctionsService.setItem('validate_string',response.data.validate_string);
    				this.commonFunctionsService.setItem('mobile_number',response.data.mobile_number);
    				this.modalService.hide();
    		        document.body.classList.remove('modal-open');
                    this.router.navigate(['/signup-thankyou']);
    				this.toasterNotification.showError(response.data.message);
                } else {
                    if (response.data.errors != undefined && response.data.errors != '' && response.data.errors != null) {
                        this.loginFormError = response.data.errors;
                    } else {
                       this.toasterNotification.showError(response.data.message);
                    }
                    this.progress = false;
                }
			}, (err: any) => console.log('submitLogin err', err));
		} else {
			this.progress	=	false;
			ValidationService.validateAllFormFields(this.loginForm);
		}
	}

    // This function is used to redirect after login
    public loginRedirect(): void{
		this.route.queryParams.subscribe(params => {
			if(typeof(params.returnUrl) != 'undefined'){
				this.returnUrl	=	params.returnUrl;
			}
		});

        if(this.router.url.split('?')[0] == '/login'){
            if(this.returnUrl != ''){
    			this.router.navigate([this.returnUrl]);
    		}else{
    			this.router.navigate(['/user/profile']);
    		}
		}
		else if( this.router.url.split('?')[0] == '/forget-password' || this.router.url.split('?')[0] == '/reset-password' ){
			this.router.navigate(['/user/profile']);
		}
    }

    // This function is used to redirect on forgot password page
    public forgotPassword(): void {
        this.modalService.hide();
        document.body.classList.remove('modal-open');
        this.router.navigate(['/forget-password']);
    }

	// This function are used for toggle
	public toggle() {
		const element	=	document.getElementById('profile');
		const prevElement	=	document.getElementById('home');
		const tabElement	=	document.getElementById('profile-tab');
		const prevTabElement	=	document.getElementById('home-tab');

		if(element	&&	tabElement)	{
			element.classList.toggle('active');
			tabElement.classList.toggle('active');
		}
		if(prevElement	&&	prevTabElement)	{
			prevElement.classList.toggle('active');
			prevTabElement.classList.toggle('active');
		}
	}

	// This function is used to handle change of login by option
	public changeLoginBy(login_by: any): void {
		this.loginForm.patchValue({login_by:login_by});
        this.loginForm.patchValue({user_name_mobile_no:''});
		this.loginBy = login_by;
		if(this.loginBy != 'mobile'){
			this.loginForm.controls["dial_code"].clearValidators();
            this.loginForm.controls["dial_code"].updateValueAndValidity();
		}
	}

	/** For destroying component */
	ngOnDestroy(): void {
		this.ngUnsubscribe.next();
	}
}
