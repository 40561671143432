<!-- login page section start -->
<div class="login-wrap">
	<h2>{{'LOGIN.WELCOME_BACK' | translate }}</h2>
	<p class="subtext">{{'LOGIN.PLEASE_LOGIN_TO_YOUR_ACCOUNT' | translate }}</p>
    <div class="with-scocial">
        <div>
            <a href="javascript:void(0);" class="facebook-btn" (click)="loginWithFacebook()">
            <img src="{{env.SITE_IMAGE_URL}}facebook.svg" alt="{{ 'SIGNUP.SIGNUP_WITH_FB'| translate }}">
            <span>{{ 'SIGNUP.SIGNUP_WITH_FB'| translate }}</span>
            </a>
        </div>
        <div>
            <a href="javascript:void(0);" class="google-btn" (click)="loginWithGoogle()">
            <img src="{{env.SITE_IMAGE_URL}}google.svg" alt="{{ 'SIGNUP.SIGNUP_WITH_GOOGLE'| translate }}">
            <span>{{ 'SIGNUP.SIGNUP_WITH_GOOGLE'| translate }}</span>
            </a>
        </div>
    </div>
	<form [formGroup]="loginForm">
		<div class="form-group radio_group">
            <label for="option1">{{'LOGIN.LOGIN_BY' | translate }}</label>
            <div class="form-input">

                <div class="styled-selectors">
                    <span *ngFor="let loginOption of env.LOGIN_BY_OPTIONS; let i=index;">
			            <input id="option{{i}}" class="radio-button" value="{{loginOption}}" name="login_by" formControlName="login_by" type="radio" [checked]="(loginBy == loginOption)" (change)="changeLoginBy(loginOption)">
			            <label for="option{{i}}">{{ loginOption | translate }}</label>
		            </span>
                </div>
            </div>

            <control-messages *ngIf="!loginFormError.login_by"  [name]="'FORM.LOGIN_BY'  | translate" [control]="loginForm.controls.login_by"></control-messages>
            <div *ngIf="loginFormError.login_by != ''"  class="help-block">{{loginFormError.login_by}}</div>
        </div>
		<div class="form-group" *ngIf="loginBy == 'mobile'">
            <div class="phone-with-code">
                <ng-select labelForId="login_dial_code"  appearance="outline"  formControlName="dial_code"  appendTo="body" [clearable]="false" (keyup.esc)="$event.preventDefault()" [virtualScroll]="true" required>
                    <ng-option  value="">{{ 'FORM.DIAL_CODE'| translate | titlecase }}</ng-option>
                    <ng-option [value]="item.country_code"  [disabled]="false" *ngFor="let item of contryDialCodeList">
                        {{item.country_code+'-'+item.country_iso_code}}
                    </ng-option>
                </ng-select>
                <div class="form-input">
                    <input type="text" placeholder="{{ 'FORM.PHONE_NUMBER' | translate | titlecase }}" id="login_phone_number" name="user_name_mobile_no" class="form-control"  formControlName="user_name_mobile_no">
                    <label for="login_phone_number">{{ 'FORM.PHONE_NUMBER' | translate | titlecase }} <span>*</span></label>
                </div>
            </div>
            <control-messages *ngIf="!loginFormError.dial_code"  [name]="'FORM.DIAL_CODE'  | translate" [control]="loginForm.controls.dial_code"></control-messages>
            <control-messages *ngIf="!loginFormError.user_name_mobile_no"  [name]="'FORM.PHONE_NUMBER'  | translate" [control]="loginForm.controls.user_name_mobile_no"></control-messages>
            <div *ngIf="loginFormError.dial_code != ''"  class="help-block">{{loginFormError.dial_code}}</div>
            <div *ngIf="loginFormError.user_name_mobile_no != ''"  class="help-block">{{loginFormError.user_name_mobile_no}}</div>
        </div>
        <div class="form-group" *ngIf="loginBy != 'mobile'">
            <div class="form-input">
                <input type="text" placeholder="{{ 'FORM.EMAIL'| translate | titlecase }}" id="login_user_name" class="form-control"  formControlName="user_name_mobile_no">
                <label for="login_user_name">{{ 'FORM.EMAIL'| translate | titlecase }} <span>*</span></label>
            </div>
            <control-messages *ngIf="!loginFormError.user_name_mobile_no"  [name]="'FORM.EMAIL'  | translate" [control]="loginForm.controls.user_name_mobile_no"></control-messages>
            <div *ngIf="loginFormError.user_name_mobile_no != ''"  class="help-block">{{loginFormError.user_name_mobile_no}}</div>
        </div>
        <div class="form-group passwordInput">
            <div class="form-input">
                <input type="password" placeholder="{{ 'FORM.PASSWORD'| translate | titlecase }}" id="login_password" name="login_password" class="form-control"  formControlName="password">
                <label for="login_password">{{ 'FORM.PASSWORD'| translate | titlecase }} <span>*</span></label>
            </div>
            <a class ="password" ShowHidePasswordDirective href="javascript:void(0);"><img src="{{env.SITE_IMAGE_URL}}eye.svg" alt="Password"></a>
            <control-messages *ngIf="!loginFormError.password"  [name]="'FORM.PASSWORD'  | translate" [control]="loginForm.controls.password"></control-messages>
            <div  *ngIf="loginFormError.password != ''" class="help-block">{{loginFormError.password}}</div>
        </div>
		<div class="checkwrap">
			<div class="custom-control custom-checkbox">
				<input type="checkbox" class="custom-control-input" id="customCheck" formControlName="remember_me">
				<label class="custom-control-label" for="customCheck">{{'LOGIN.REMEMBER_ME' | translate }}</label>
			</div>
			<a href="javascript:void(0);" (click)="forgotPassword();">{{'LOGIN.FORGOT_PASSWORD' | translate }} </a>
		</div>
		<button type="submit" class="btn-success submitbtn" [ladda]="progress" (click)="submitLogin();">{{'GLOBAL.LOGIN' | translate }}</button>
		<!-- <div class="or-divider">
			<span>{{ 'SIGNUP.OR'| translate }}</span>
		</div>		
		<div class="bt-text">
			{{'GLOBAL.DONT_HAVE_ACCOUNT' | translate }} <a class="signup-btn" href="javascript:void(0);" (click)="toggle()">{{'GLOBAL.SIGNUP' | translate }}</a>
		</div> -->
	</form>
</div>
<!-- login page section end -->
