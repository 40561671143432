import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
/**
 * component
**/ 
@Component({
	selector: 'app-breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls : ['./breadcrumb.component.css']
})
export class BreadcrumbComponent {
	
	@Input() breadcrumb: any;
	constructor(public router: Router) { }
	
	
	
}
