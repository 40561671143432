<!-- Product List view section start-->
<ng-container *ngIf="product != ''">
<div class="product-box">
	<a routerLink="/product/product-detail/{{product.slug}}" class="product-img">
		<figure>
			<img src="{{RESIZEABLE_URL}}{{product.image}}{{env.RATIO_175_X_175}}" alt="{{product.name}}" (load)="product.loaded = true" [hidden]="!product.loaded">
            <img src="{{env.SITE_NO_IMAGE_URL}}{{env.RATIO_175_X_175}}" alt="{{product.name | titlecase}}" [hidden]="product.loaded">
		</figure>
	</a>
	<div class="product-body-text">
		<a routerLink="/product/product-detail/{{product.slug}}" href="javascript:void(0);">
			<h5 class="product-name product-height">{{product.name}}</h5>
		</a>
		<div class="plan-price">
			<b>{{'GLOBAL.TELE' | translate}} <img src="{{env.SITE_IMAGE_URL}}plus-small.png" alt="img"></b> <strong> {{product.membership_selling_price | displayPrice}} </strong>
		</div>
		
	</div>
	<div class="product-price price2"> {{product.price | displayPrice :'1.0-2' }} </div>
	<div class="product-addtocart">
		<a href="javascript:void(0)" class="btn-success out_of_stock" *ngIf="product.stock_quantity <= 0">
		    {{'GLOBAL.OUT_OF_STOCK' | translate}}
		</a>
		<a href="javascript:void(0)" class="btn-success quickBuy" *ngIf="product.stock_quantity > 0" appAddToCart [product]="product" [quickBuy]="1">
		    {{'GLOBAL.QUICK_BUY' | translate}}
		</a>
		<a href="javascript:void(0)" class="btn-success wishlistBtn" *ngIf="product.stock_quantity <= 0 && product.is_notify_me_product == 0" appAddToNotifyMe [product]="product">
		    <img src="{{env.SITE_IMAGE_URL}}notify.svg" alt="Notify Me">
			<img class="showhover" src="{{env.SITE_IMAGE_URL}}notify-hover.svg" alt="Notify Me" [tooltip]="'GLOBAL.NOTIFY_ME' | translate" [options]="env.TOOLTIP_OPTIONS" placement="top">
		</a>
		<a href="javascript:void(0)" class="btn-success wishlistBtn active" *ngIf="product.stock_quantity <= 0 && product.is_notify_me_product == 1" appRemoveFromNotifyMe [product]="product">
			<img src="{{env.SITE_IMAGE_URL}}notify.svg" alt="Notify Me">
			<img class="showhover" src="{{env.SITE_IMAGE_URL}}notify-hover.svg" alt="Notify Me" [tooltip]="'GLOBAL.NOTIFY_ME' | translate" [options]="env.TOOLTIP_OPTIONS" placement="top">
		</a>

		<a *ngIf="userService.getCurrentUser().slug == null || userService.getCurrentUser().slug == 'undefined' else loggedInWishlist" appLogin href="javascript:void(0)" class="btn-success wishlistBtn">
			<img src="{{env.SITE_IMAGE_URL}}wishlist-small.png" alt="wishlist">
			<img class="showhover" src="{{env.SITE_IMAGE_URL}}wishlist-small-hover.png" alt="wishlist" [tooltip]="'GLOBAL.ADD_TO_WISHLIST' | translate" [options]="env.TOOLTIP_OPTIONS" placement="top">
		</a>


        <ng-template #loggedInWishlist>
            <a *ngIf="product.is_favourite == 0" href="javascript:void(0)" class="btn-success wishlistBtn" appAddToWishlist [product]="product">
                <img src="{{env.SITE_IMAGE_URL}}wishlist-small.png" alt="wishlist">
                <img class="showhover" src="{{env.SITE_IMAGE_URL}}wishlist-small-hover.png" alt="wishlist" [tooltip]="'GLOBAL.ADD_TO_WISHLIST' | translate" [options]="env.TOOLTIP_OPTIONS" placement="top">
            </a>
            <a *ngIf="product.is_favourite == 1" href="javascript:void(0)" class="btn-success wishlistBtn active" appRemoveFromWishlist [product]="product">
                <img src="{{env.SITE_IMAGE_URL}}wishlist-small.png" alt="wishlist">
                <img class="showhover" src="{{env.SITE_IMAGE_URL}}wishlist-small-hover.png" alt="wishlist" [tooltip]="'GLOBAL.REMOVE_FROM_WISHLIST' | translate" [options]="env.TOOLTIP_OPTIONS" placement="top">
            </a>
        </ng-template>
		<a href="javascript:void(0)" *ngIf="product.stock_quantity > 0" [ngClass]="[(product.is_in_cart == 1)?'btn-success cartBtn active':'btn-success cartBtn']" appAddToCart [product]="product">
			<img src="{{env.SITE_IMAGE_URL}}cart-small.png" alt="Shopping Cart">
			<img class="showhover" src="{{env.SITE_IMAGE_URL}}cart-hover.png" alt="Shopping Cart" [tooltip]="'GLOBAL.ADD_TO_CART' | translate" [options]="env.TOOLTIP_OPTIONS" placement="top">
		</a>
	</div>
	<div *ngIf="product.for_pet && product.pet_image && product.pet_image!= ''" class="ted_pro"><a href="javascript:void(0);"><img src="{{product.category_image_url}}{{product.pet_image}}" alt="pet-icon"></a></div>
</div>
</ng-container>
<!-- Product List view section end-->