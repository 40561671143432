import { Component, OnInit, Input} from '@angular/core';
import { OwlOptions} from 'ngx-owl-carousel-o';
/*import these modules for getting data from routing url */
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import { CommonService } from '../../service/common.service';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';

/***
 * Component
*/
@Component({
  selector: 'app-testmonial',
  templateUrl: './testmonial.component.html'
})
export class TestmonialComponent implements OnInit {
	/***
	 * Define variable
	*/
	private ngUnsubscribe: Subject<void> = new Subject<void>();
	env						= environment;
	@Input() page			: any	= '';
	testmonialSliderData	: any	= [];

	/**Initialize testimonial Slider Options */
	testimonialSliderOptions: OwlOptions = {
        loop	: true,
		dots	: false,
		items	: 5,
		nav		: true,
		responsive:{
			0:{
				items:1,
			},
			576:{
				items:2,
			},
			767:{
				items:3,
			},
			1024:{
				items:4,
			},
			1199:{
				items:5,
			}
		}
	}

	/***
	 * Define constructor
	*/
	constructor(
		public router: Router,
		public commonService: CommonService,
		private translateService: TranslateService
  	) {

  	}

	/***
	* ng On Init
	*/
  	ngOnInit() {
		this.getTestimonials();

		/** Function Calling On Change of language Dropdown */
		this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
			this.getTestimonials();
		});
 	}

	 // This function is used for get testimonials list
	public getTestimonials() : void{
		this.commonService.getTestimonialData().pipe(takeUntil(this.ngUnsubscribe)).subscribe((res: any) => {
			if (res.data.status == this.env.SUCCESS_STATUS) {
				this.testmonialSliderData =	res.data;
			}
		});
	}
}
