<!-- signUp modal box section start -->
<div class="modal-body">
	<button type="button" class="close" (click)="closeModal();">
	    <span aria-hidden="true">&times;</span>
	</button>
	<div class="login-signup-popup">
        <app-signup [tabIndex]="tabIndex"></app-signup>
    </div>
</div>
<!-- signUp modal box section end -->
