import { Component, OnInit,Input} from '@angular/core';
/*import these modules for getting data from routing url */
import { Router } from '@angular/router';
import { environment } from '../../../../../environments/environment';
import { TextSetting } from '../../../../textsetting';
import { UserService,CommonFunctionsService } from '../../../../_services/index';
import { CommonService } from '../../service/common.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
/***
 * Component
*/
@Component({
  selector: 'app-nutritionist-view',
  templateUrl: './nutritionistview.component.html'
})
export class NutritionistViewComponent implements OnInit {
	/***
	 * Define Variable 
	*/
	private ngUnsubscribe: Subject<void> = new Subject<void>();
	env					       =	environment
	TextSetting			       =	TextSetting
	@Input() nutritionist	   :	any	=	{};
	@Input() IMAGE_URL	       :	any	=	'';
	@Input() RESIZEABLE_URL	   :	any	=	'';
	membershipPlanData         :    any	=	'';

	/***
	 * Define constructor
	*/
	constructor(
		public router: Router,
		public userService: UserService,
		public commonService: CommonService,
		public commonFunctionsService       : CommonFunctionsService,
  	) {

  	}
	/***
	 * This function is used to On Init
	*/
  	ngOnInit() {
 
 	}

	/**This function is used to user Check Plan By Dietician */
	public userCheckPlanByDietician(nutritionistId:any, nutritionistSlug:any){
		this.commonFunctionsService.isBrowser.subscribe(isBrowser => {
			if (isBrowser) {
			    	let userData = this.userService.getCurrentUser();
   
			    	if (Object.keys(userData).length > 0) {
					let formData        : any = {};
					formData            = {nutritionist_id: nutritionistId};
					this.commonService.getMyPlanByDietician(formData).pipe(takeUntil(this.ngUnsubscribe)).subscribe((response: any) => {
						if(response.data.status === this.env.SUCCESS_STATUS){
							this.membershipPlanData 	= response.data.result.data;
							if(this.membershipPlanData.is_dietician == this.env.ACTIVE){
								this.commonFunctionsService.removeItem('planData');
								this.router.navigate(['/nutritionists/'+nutritionistSlug]);
							}else{
								this.router.navigate(['/nutritionists/'+nutritionistSlug]);
							}
						}
					})
				}else{
					this.commonFunctionsService.removeItem('planData');
					this.router.navigate(['/nutritionists/'+nutritionistSlug]);
				}
			}
		});
	}

}
