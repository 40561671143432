import { Injectable } from '@angular/core';
//import * as io from 'socket.io-client';
import { io } from 'socket.io-client';
import { Observable, of, Observer, Subject, ReplaySubject } from 'rxjs';

import { UserService } from './user.service';
import { CommonFunctionsService } from './commonFunctions.service';
import { environment } from '../../environments/environment';

@Injectable({
	providedIn: 'root'
})
export class SocketService {
	private url = environment.NODE_URL;
	private socket: any;
	private subjectNotification = new Subject<any>();
	private subjectChat = new Subject<any>();
	private subjectLocation = new Subject<any>();

	/**define variables */
	abuseWords: any = [];
	/*For video call ringtone */
	audioElement: any = {};
	AUDIO_URL: string = "";

	constructor(private userService: UserService, public commonFunctionsService: CommonFunctionsService) {
		this.commonFunctionsService.isBrowser.subscribe((isBrowser) => {
			if(isBrowser){
				this.socket = io(this.url);
			}
		});
	}

	public Login(){

		if (this.userService.getCurrentUser().slug) {
			let uid = this.userService.getCurrentUser()._id;
			let uslug = this.userService.getCurrentUser().slug;
			let full_name = this.userService.getCurrentUser().full_name;

			this.socket.emit('login', { user: full_name, slug: uslug, id: uid, chat_status: '1' });
		}
	}

	public chatLogin(data: any): void {
		/* Login user into chat room :: START */
		this.socket.emit('chatlogin', {
			id: data.chatroomid,
			user: this.userService.getCurrentUser().full_name,
			u_id: this.userService.getCurrentUser()._id
		});
		/* Login user into chat room :: END */
	}


	public sendMessage(data: any): void {
		this.socket.emit('msg', data);
	}
	public muteVideo(data: any): void {
		this.socket.emit('mute_video', data);
	}

	public getMuteVideoEvent = () => {
		return new Observable((observer: Observer<any>) => {
			this.socket.on('receive_video_mute', (message: any) => {
				observer.next(message);
			});
		});
	}

	public unmuteVideo(data: any): void {
		this.socket.emit('unmute_video', data);
	}
	public getUnMuteVideoEvent = () => {
		return new Observable((observer: Observer<any>) => {
			this.socket.on('receive_video_unmute', (message: any) => {
				observer.next(message);
			});
		});
	}
	public muteAudio(data: any): void {
		this.socket.emit('mute_audio', data);
	}
	public getMuteAudioEvent = () => {
		return new Observable((observer: Observer<any>) => {
			this.socket.on('receive_audio_mute', (message: any) => {
				observer.next(message);
			});
		});
	}
	public unmuteAudio(data: any): void {
		this.socket.emit('unmute_audio', data);
	}
	public getUnMuteAudioEvent = () => {
		return new Observable((observer: Observer<any>) => {
			this.socket.on('receive_audio_unmute', (message: any) => {
				observer.next(message);
			});
		});
	}

	public updateChatCounter(): void {
		this.socket.emit('chat_counter', {
			id: this.userService.getCurrentUser()._id
		});
		this.sendChatEvent({id: this.userService.getCurrentUser()._id});
	}

	public markMessageAsRead(data: any): void {
		this.socket.emit('mark_message_as_read', data);
		this.sendChatEvent(data);
	}
	public markNotificationAsRead(): void {
		this.socket.emit('mark_notification_as_read', {id:this.userService.getCurrentUser()._id});
		this.sendNotificationEvent({id:this.userService.getCurrentUser()._id});
	}


	public getMessages = () => {
		return new Observable((observer: Observer<any>) => {
			this.socket.on('receive', (message: any) => {
				observer.next(message);
			});
		});
	}
	
	public receiveVideoCallEvent = () => {
		return new Observable((observer: Observer<any>) => {
			this.socket.on('receive_video_call_event', (data: any) => {
				observer.next(data);
			});
		});
	}
	public receiveUpdateDetailEvent = () => {
		return new Observable((observer: Observer<any>) => {
			this.socket.on('receive_update_details_event', (data: any) => {
				observer.next(data);
			});
		});
	}

	public getUpdateChatCounterEvent = () => {
		return new Observable((observer: Observer<any>) => {
			this.socket.on('update_chat_counter', (data: any) => {
				observer.next(data);
				this.sendChatEvent(data);
			});
		});
	}
	public getUpdateNotificationCounterEvent = () => {
		return new Observable((observer: Observer<any>) => {
			this.socket.on('notification_received', (data: any) => {
				observer.next(data);
			});
		});
	}
	public showOnline = () => {
		return Observable.create((observer: any) => {
			this.socket.on('showonline', (data: any) => {
				observer.next(data);
			});
		});
	}
	public onBlockUnblock = () => {
		return Observable.create((observer: any) => {
			this.socket.on('block_unblock_user', (data: any) => {
				observer.next(data);
			});
		});
	}




	public playSound() {
		this.audioElement = document.createElement('audio');
		this.audioElement.setAttribute('src', this.AUDIO_URL);
		this.audioElement.play();
	}



	sendNotificationEvent(message: any) {
		this.subjectNotification.next(message);
	}

	getNotificationEvent(): Observable<any> {
		return this.subjectNotification.asObservable();
	}

	sendChatEvent(message: any) {
		this.subjectChat.next(message);
	}

	getChatEvent(): Observable<any> {
		return this.subjectChat.asObservable();
	}

	sendLocationEvent(message: any) {
		this.subjectLocation.next(message);
	}

	getLocationEvent(): Observable<any> {
		return this.subjectLocation.asObservable();
	}
}
